import {Autocomplete, Button, Grid, Typography} from "@mui/material";
import React, {useState} from "react";
import FormSection from "./FormSection";
import Heading from "./Heading";
import ValueInputCard from "./ValueInputCard";
import {FormValueCard, FormValueTextField} from "./FormStyles";
import ISite from "../../shared/types/Site";

type Props = {
	show?: boolean;
	header: string;
	submit: (siteId: number, name: string) => void;
	sites: ISite[];
}

const SiteInfrastructure = ({submit, show, header, sites}: Props) => {
	const [site, setSite] = useState<ISite | null>(null);
	const [name, setName] = useState<string>("");
	const [nameValid, setNameValid] = useState<boolean>(false);

	return (
		<FormSection show={show}>
			<Grid container spacing={3} justifyContent="center" alignContent="center">
				<Grid item xs={12}>
					<Heading subheading={header}/>
				</Grid>
				<Grid item xs={12} sm={6} md={4} lg={3} sx={{display: "flex"}}>
					<ValueInputCard
						heading="Name"
						valid={nameValid}
						setValueStr={setName}
						setValid={setNameValid}
						helperText="Use Id & first letter of site"
					/>
				</Grid>
				<Grid item xs={12} sm={6} md={4} lg={3} sx={{display: "flex"}}>
					<FormValueCard variant="outlined" style={{flexGrow: 1}}>
						<Typography component="p" variant="h6" align="center">
							Site
						</Typography>
						<Autocomplete
							value={site}
							options={sites}
							onChange={(event, newValue) => {
								setSite(newValue);
							}}
							getOptionLabel={option => option.location}
							renderInput={(params) => (
								<FormValueTextField
									{...params}
									variant="standard"
								/>
							)}
						/>
					</FormValueCard>
				</Grid>
				<Grid item xs={12}>
					<Button
						size="large"
						color="primary"
						variant="contained"
						disabled={site === null || !nameValid}
						onClick={() => {
							if (site && site.id) {
								submit(site.id, name)
							}
						}}
					>
						{header}
					</Button>
				</Grid>
			</Grid>
		</FormSection>

	)
}

export default SiteInfrastructure;