import {ListItemText} from "@mui/material";
import {Link, useLocation} from "react-router-dom";
import React, {useEffect, useState} from "react";
import {NavListItemButton, NavListItemIcon} from "./NavigationStyles";

type Props = {
	route: string;
	isNested?: boolean;
	children: React.ReactNode;
	text: string;
	disabled?: boolean;
}

const NavListItem = ({route, isNested = false, children, text, disabled}: Props) => {
	const location = useLocation()
	const [selected, setSelected] = useState(location.pathname === route)

	useEffect(() => {
		setSelected(location.pathname === route);
	}, [location, route])

	if (disabled) {
		return (
			<NavListItemButton disabled={disabled} selected={selected} nested={isNested}>
				<NavListItemIcon selected={selected}>
					{children}
				</NavListItemIcon>
				<ListItemText primary={text}/>
			</NavListItemButton>
		)
	}
	return (
		<Link to={route}>
			<NavListItemButton disabled={disabled} selected={selected} nested={isNested}>
				<NavListItemIcon selected={selected}>
					{children}
				</NavListItemIcon>
				<ListItemText primary={text}/>
			</NavListItemButton>
		</Link>
	)
}

export default NavListItem
