import React, {useContext, useEffect, useState} from "react";
import {NavContext} from "../../shared/providers/NavProvider";
import Form from "../../components/forms/Form";
import {useHistory} from "react-router-dom";
import {useSnackbar} from "notistack";
import BatchApi from "../../shared/api/BatchApi";
import {GridColDef, GridRowParams} from "@mui/x-data-grid";
import FormSection from "../../components/forms/FormSection";
import {Box, Hidden, Typography} from "@mui/material";
import {format} from "date-fns";
import {DataGridStyled} from "../../components/layout/DataGridStyles";
import {CheckCircle, Error} from "@mui/icons-material";
import FormOutputs from "../../components/forms/FormOutputs";
import ValueCard from "../../components/forms/ValueCard";
import axios from "axios";


const columns: GridColDef[] = [
	{
		field: "id",
		headerName: "Batch ID",
		flex: 1,
		renderCell: (cellValues) => {
			return (
				<Typography>
					{cellValues.value && `Batch ${cellValues.value.toString().padStart(4, "0")}`}
				</Typography>
			)
		}
	},
	{
		field: "date",
		headerName: "Start Date",
		flex: 2,
		renderCell: (cellValues) => {
			return (
				<Typography>
					{cellValues.value && cellValues.value instanceof Date && format(cellValues.value, "dd/MM/yyyy")}
				</Typography>
			)
		}
	},
	{
		field: "status",
		headerName: "Status",
		flex: 2,
		renderCell: (cellValues) => {
			return (
				<React.Fragment>
					<Box pr={1}>
						{
							cellValues.value === "Completed"
								?
								<CheckCircle color="success"/>

								:
								cellValues.value === "Overdue"
									?
									<Error color="error"/>
									:
									<Error color="primary"/>
						}
					</Box>
					<Typography>
						{cellValues.value}
					</Typography>
				</React.Fragment>
			)
		}
	}
];

const mobileColumns: GridColDef[] = [
	{
		field: "id",
		headerName: "ID",
		flex: 1
	},
	{
		field: "date",
		headerName: "Start Date",
		flex: 1
	},
];

type DisplayBatch = {
	id: number;
	date: Date;
	status: string;
	serverId: number;
}

const BatchList = () => {
	const [navState] = useContext(NavContext);
	const [batches, setBatches] = useState<DisplayBatch[]>([]);
	const [completed, setCompleted] = useState(0);
	const [overdue, setOverdue] = useState(0);
	const [progress, setProgress] = useState(0);
	const [isLoading, setIsLoading] = useState(true);
	const {enqueueSnackbar} = useSnackbar();

	let history = useHistory();

	useEffect(() => {
		(async () => {
			setIsLoading(true);
			try {
				if (navState.sites.length === 0) {
					// Still waiting for sites to be populated
					return;
				}
				let response = await BatchApi.list(navState.sites[navState.siteIndex].id, true);
				let completedLocal = 0;
				let overdueLocal = 0;
				let progressLocal = 0;
				const batchesIn = response.data.map((batch): DisplayBatch | undefined => {
					if (batch.tasks) {
						const localStatus = batch.tasks.filter(task => task.status === "Overdue").length > 0 ? "Overdue" :
							batch.tasks.filter(task => task.status === "Todo").length > 0 ? "In Progress" : "Completed";
						switch (localStatus) {
							case "Completed":
								completedLocal++;
								break;
							case "Overdue":
								overdueLocal++;
								break;
							case "In Progress":
								progressLocal++;
								break;
						}
						return {
							id: batch.localId,
							date: new Date(batch.time),
							status: localStatus,
							serverId: batch.id
						}
					}
					return undefined;
				})
				const newBatchesIn: DisplayBatch[] = [];
				batchesIn.forEach(batch => {
					if (batch) {
						newBatchesIn.push(batch);
					}
				})
				setProgress(progressLocal);
				setOverdue(overdueLocal);
				setCompleted(completedLocal);
				setBatches(newBatchesIn);
			} catch (error) {
				if (axios.isCancel(error)) {
					return;
				}
				// Something happened in setting up the request that triggered an Error
				enqueueSnackbar(`Failed to find batches at your site`, {variant: "error"})
				console.log('Error', error.message);
				setIsLoading(false);
			}
		})()

		return () => {
			BatchApi.abort();
		}
	}, [navState.sites, navState.siteIndex, enqueueSnackbar, history])

	useEffect(() => {
		if (batches && batches.length > 0) {
			setIsLoading(false);
		}
	}, [batches])

	const onRowClick = (params: GridRowParams) => {
		history.push(`/batches/${params.row.serverId}`)
	}

	return (
		<Form heading="Batches" loading={false}>
			<FormOutputs>
				<ValueCard heading="Overdue Batches" value={overdue} color="error" variant="elevation"/>
				<ValueCard heading="Completed Batches" value={completed} color="success" variant="elevation"/>
				<ValueCard heading="Batches In Progress" value={progress} color="primary" variant="elevation"/>
			</FormOutputs>
			<FormSection>
				<Box height={600}>
					<Hidden smDown>
						<DataGridStyled
							columns={columns}
							rows={batches}
							loading={isLoading}
							onRowClick={onRowClick}
						/>
					</Hidden>
					<Hidden smUp>
						<DataGridStyled
							columns={mobileColumns}
							rows={batches}
							loading={isLoading}
							onRowClick={onRowClick}
						/>
					</Hidden>
				</Box>
			</FormSection>
		</Form>
	)
}

export default BatchList;