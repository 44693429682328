import {Typography} from "@mui/material";
import React from "react";

type Props = {
	heading?: string,
	subheading?: string
	smallSubHeading?: string
}

const Heading = ({heading, subheading, smallSubHeading}: Props) => {
	return (
		<React.Fragment>
			{heading &&
				<Typography component="h1" variant="h1" gutterBottom>
					{heading}
				</Typography>
			}
			{subheading &&
				<Typography component="h2" variant="h2" gutterBottom>
					{subheading}
				</Typography>
			}
			{smallSubHeading &&
				<Typography component="h3" variant="h3" gutterBottom>
					{smallSubHeading}
				</Typography>
			}
		</React.Fragment>
	)
}

export default Heading;