import {styled} from "@mui/material";
import {DataGrid, DataGridProps} from "@mui/x-data-grid";

interface DataGridStyledProps extends DataGridProps {
}

export const DataGridStyled = styled(DataGrid)<DataGridStyledProps>(({theme}) => ({
	"& .MuiDataGrid-columnHeaderTitle": {
		fontWeight: theme.typography.h6.fontWeight,
		color: theme.palette.primary.main,
		fontSize: theme.typography.h6.fontSize
	},
	"& .MuiDataGrid-cell": {
		fontSize: "1rem",
	}

}))