import AxiosRequest from "./AxiosRequest"
import ITransfer from "../types/Transfer";
import AbstractMagOpsApi from "./AbstractMagOpsApi";

class TransferApi extends AbstractMagOpsApi {

	create(data: ITransfer) {
		return AxiosRequest.post<ITransfer>("/transfer", data)
	}

	get(batchId: number) {
		return AxiosRequest.get<ITransfer>("/transfer", {
			params: {
				batchId: batchId
			},
			signal: this.getRequestSignal()
		})
	}
}

export default new TransferApi()