import {List} from "@mui/material";
import {ListAlt} from "@mui/icons-material";
import React from "react";
import NavListItem from "./NavListItem";

const SecondarySideNav = () => {
	return (
		<List disablePadding>
			<NavListItem isNested route="/reports/aviary" text="Aviary">
				<ListAlt/>
			</NavListItem>
			<NavListItem isNested route="/reports/incubator" text="Incubator">
				<ListAlt/>
			</NavListItem>
			<NavListItem isNested route="/reports/sifting" text="Sifting">
				<ListAlt/>
			</NavListItem>
			<NavListItem isNested route="/reports/dark_room" text="Dark Rooms">
				<ListAlt/>
			</NavListItem>
		</List>
	)
}

export default SecondarySideNav;