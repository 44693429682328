import {appBarClasses, AppBarInput, AppBarTitle} from "./LayoutStyles";
import {
	AppBar,
	Badge,
	Box,
	IconButton,
	ListItemIcon,
	Menu,
	MenuItem,
	Select,
	Theme,
	Toolbar,
	useMediaQuery
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import {ReactComponent as Logo} from "../../assets/goterra_logo.svg";
import {ReactComponent as LogoWhite} from "../../assets/goterra_logo_white.svg";
import Loading from "./Loading";
import {AddBusiness, Business, Logout, Notifications, PersonAdd, Settings} from "@mui/icons-material";
import React, {useContext, useEffect, useState} from "react";
import {NavContext} from "../../shared/providers/NavProvider";
import {TodoContext} from "../../shared/providers/TodoProvider";
import {MagOpsColors} from "../../shared/styles/MagOpsStyle";
import {Link, useHistory} from "react-router-dom";
import AuthApi from "../../shared/api/AuthApi";
import {loadRefreshToken} from "../../shared/helpers/LocalStorageHelper";
import {checkGroups} from "../../shared/helpers";
import {AuthContext} from "../../shared/providers/AuthProvider";

const HeaderBar = () => {
	const [navState, navDispatch] = useContext(NavContext);
	const [authState,] = useContext(AuthContext);
	const [todoState,] = useContext(TodoContext);
	const [iconColor, setIconColor] = useState(navState.darkMode ? "#FFF" : MagOpsColors.primary.goterraSky);
	const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
	const [admin, setAdmin] = useState<boolean>();
	const smUp = useMediaQuery((theme: Theme) => theme.breakpoints.up("sm"));
	const open = Boolean(anchorEl);
	let history = useHistory();

	const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
		setAnchorEl(event.currentTarget);
	}

	const handleClose = () => {
		setAnchorEl(null);
	};

	useEffect(() => {
		if (authState.idToken) {
			setAdmin(checkGroups(authState.idToken, ["Admin"]));
		} else if (process.env.NODE_ENV === "development") {
			setAdmin(true);
		}
	}, [authState.idToken])

	useEffect(() => {
		setIconColor(navState.darkMode ? "#FFF" : MagOpsColors.primary.goterraSky)
	}, [navState.darkMode])

	const handleSiteChange = (indexIn: string) => {
		const index = parseInt(indexIn);
		navDispatch({
			type: "SET_SITE",
			data: index
		});
	}

	const signOut = () => {
		handleClose();
		(async () => {
			const token = loadRefreshToken();
			if (token) {
				await AuthApi.logout(token);
			}
		})();
		history.push("/login");
	}

	return (
		<AppBar
			position="fixed"
			sx={{
				backgroundColor: navState.darkMode ? MagOpsColors.primary.goterraSky : MagOpsColors.primary.duskBlue,
				[`& .${appBarClasses.logo}`]: {
					maxHeight: 64,
				}
			}}
			elevation={0}
		>
			<Toolbar>
				<IconButton
					edge="start"
					sx={{
						color: iconColor
					}}
					aria-label="open drawer"
					onClick={() => {
						smUp
							?
							navDispatch({type: navState.navOpen ? "CLOSE_NAV" : "OPEN_NAV"})
							:
							navDispatch({type: "OPEN_NAV_MOBILE"})
					}}
					size="large">
					<MenuIcon/>
				</IconButton>
				<AppBarTitle>

					<Link to="/">
						{navState.darkMode
							?
							<LogoWhite className={appBarClasses.logo}/>
							:
							<Logo className={appBarClasses.logo}/>
						}
					</Link>
				</AppBarTitle>
				<Box mr={navState.sites.length === 0 ? 1 : 0}>
					<Loading loading={navState.sites.length === 0} small lame>
						<Select
							value={navState.siteIndex}
							variant="standard"
							onChange={event => handleSiteChange(event.target.value as string)}
							input={<AppBarInput dark={navState.darkMode}/>}
						>
							{
								navState.sites.map((site, index) => {
									return <MenuItem key={site.id} value={index}>
										{site.location}
									</MenuItem>
								})
							}
						</Select>
					</Loading>
				</Box>
				<IconButton
					sx={{color: iconColor}}
					onClick={() => {
						smUp
							?
							navDispatch({type: navState.todoOpen ? "CLOSE_TODO" : "OPEN_TODO"})
							:
							navDispatch({type: "OPEN_TODO_MOBILE"})
					}}
					size="large"
				>
					<Badge badgeContent={todoState.todoLength} color="primary">
						<Notifications/>
					</Badge>
				</IconButton>
				<IconButton
					sx={{color: iconColor}}
					size="large"
					onClick={handleClick}
				>
					<Settings/>
				</IconButton>

				<Menu open={open} anchorEl={anchorEl} onClose={handleClose}>
					{admin &&
						<React.Fragment>
							<Link to="/settings/config">
								<MenuItem onClick={handleClose}>
									<ListItemIcon>
										<Settings/>
									</ListItemIcon>
									Settings
								</MenuItem>
							</Link>
							<Link to="/settings/infrastructure">
								<MenuItem onClick={handleClose}>
									<ListItemIcon>
										<Business/>
									</ListItemIcon>
									Infrastructure
								</MenuItem>
							</Link>
							<Link to="/users/invite">
								<MenuItem onClick={handleClose}>
									<ListItemIcon>
										<PersonAdd/>
									</ListItemIcon>
									Invite Users
								</MenuItem>
							</Link>
							<Link to="/settings/customer">
								<MenuItem onClick={handleClose}>
									<ListItemIcon>
										<AddBusiness/>
									</ListItemIcon>
									Customers
								</MenuItem>
							</Link>
						</React.Fragment>
					}
					<MenuItem onClick={signOut}>
						<ListItemIcon>
							<Logout/>
						</ListItemIcon>
						Sign Out
					</MenuItem>
				</Menu>
			</Toolbar>
		</AppBar>
	)
}

export default HeaderBar;