import AxiosRequest from "./AxiosRequest"
import IBatchTrays from "../types/BatchTrays";
import IMibService from "../types/MibService";
import AbstractMagOpsApi from "./AbstractMagOpsApi";

class MibServiceApi extends AbstractMagOpsApi {
	getAll(mibId: number) {
		return AxiosRequest.get<IMibService[]>("/mib_services", {
			params: {
				mib_id: mibId
			},
			signal: this.getRequestSignal()
		})
	}

	get(id: number) {
		return AxiosRequest.get<IMibService>(`/mib_service/${id}`, {
			signal: this.getRequestSignal()
		})
	}

	create(data: { mib_id: number, date: Date, larvae_g: number, trays: IBatchTrays[] }) {
		return AxiosRequest.post<IMibService>("/mib_service", data)
	}
}

export default new MibServiceApi()