import AxiosRequest from "./AxiosRequest"
import IIncubator from "../types/Incubator";
import AbstractMagOpsApi from "./AbstractMagOpsApi";

class IncubatorApi extends AbstractMagOpsApi {
	getAll(siteId: number) {
		return AxiosRequest.get<IIncubator[]>("/incubators", {
			params: {
				"siteId": siteId
			},
			signal: this.getRequestSignal()
		})
	}

	get(id: number) {
		return AxiosRequest.get<IIncubator>(`/incubator/${id}`, {
			signal: this.getRequestSignal()
		})
	}

	create(data: { siteId: number, name: string }) {
		return AxiosRequest.post<IIncubator>("/incubator", data)
	}

	delete(id: number) {
		return AxiosRequest.delete<{}>(`/incubator/${id}`)
	}
}

export default new IncubatorApi()