import AxiosRequest from "./AxiosRequest"
import AbstractMagOpsApi from "./AbstractMagOpsApi";
import IPupaeIntroPrep from "../types/PupaeIntroPrep";

class PupaeIntroPrepApi extends AbstractMagOpsApi {

	create(data: IPupaeIntroPrep) {
		return AxiosRequest.post<IPupaeIntroPrep>("/pupae_intro_prep", data)
	}
}

export default new PupaeIntroPrepApi()