import AxiosRequest from "./AxiosRequest"
import ISifting from "../types/Sifting";
import AbstractMagOpsApi from "./AbstractMagOpsApi";

class SiftingApi extends AbstractMagOpsApi {

	create(data: ISifting) {
		return AxiosRequest.post<ISifting>("/sifting", data)
	}
}

export default new SiftingApi()