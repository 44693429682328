import {Cell, Legend, Pie, PieChart, ResponsiveContainer, Tooltip} from "recharts";
import FormOutputs from "../forms/FormOutputs";
import React from "react";
import {DashboardColors} from "./DashboardStyles";
import {Box, Skeleton, Theme, Typography, useMediaQuery} from "@mui/material";
import {MagOpsColors} from "../../shared/styles/MagOpsStyle";
import {formatFloats} from "../../shared/helpers";

const RADIAN = Math.PI / 180;

interface KeyValue {
	name: string;
	value: number;
}

type Props = {
	data: KeyValue[];
	title: string;
	subtitle?: string;
	align?: "right" | "left";
	loading?: boolean;
	children?: React.ReactNode;
}

const renderText = (value: string) => (
	<Typography
		component="span"
		fontSize={"1.1rem"}
		fontWeight="bold"
		pl={1}
		color={MagOpsColors.primary.goterraSky}
	>
		{value}
	</Typography>
)

const renderCustomizedLabel = ({cx, cy, midAngle, innerRadius, outerRadius, percent, index, value, children}: any) => {
	if (percent < 0.05) {
		const radius = outerRadius * 1.15;
		const x = cx + radius * Math.cos(-midAngle * RADIAN);
		const y = cy + radius * Math.sin(-midAngle * RADIAN);
		return (
			<text
				x={x}
				y={y}
				fill={DashboardColors[index % (DashboardColors.length)]}
				textAnchor={"middle"}
				dominantBaseline="central"
			>
				{`${(formatFloats(value))}`}
			</text>
		);
	} else {
		const radius = innerRadius + (outerRadius - (innerRadius)) * 0.5;
		const x = cx + radius * Math.cos(-midAngle * RADIAN);
		const y = cy + radius * Math.sin(-midAngle * RADIAN);
		return (
			<text x={x} y={y} fill="white" textAnchor={"middle"} dominantBaseline="central">
				{`${(formatFloats(value))}`}
			</text>
		);
	}
};

const AviaryChart = ({data, title, align = "left", loading, subtitle, children}: Props) => {
	const smUp = useMediaQuery((theme: Theme) => theme.breakpoints.up("sm"));

	return (
		<FormOutputs header={title} subheader={subtitle} half>
			{loading
				?
				<Skeleton width="100%" height="100%"/>
				:
				(data.length === 0
					?
					<Box width="100%">
						<Typography variant="h3" textAlign="center">
							No Results Found
						</Typography>
					</Box>
					:
					<ResponsiveContainer width={"100%"} minHeight={smUp ? 350 : 450} key={Math.random()}>
						<PieChart>
							<Tooltip/>
							<Pie
								data={data}
								nameKey="name"
								dataKey="value"
								label={renderCustomizedLabel}
								outerRadius="80%"
								innerRadius="30%"
								labelLine={false}
							>
								{data.map((entry, index) => (
									<Cell key={index} fill={DashboardColors[index % (DashboardColors.length)]}/>
								))}
							</Pie>
							<Legend
								layout={"horizontal"}
								align={smUp ? align === "right" ? "left" : "right" : "center"}
								verticalAlign={"top"}
								iconType="circle"
								height={50}
								formatter={renderText}
							/>
						</PieChart>
					</ResponsiveContainer>)
			}
			{children}
		</FormOutputs>
	);
}

export default AviaryChart;
