import AxiosRequest from "./AxiosRequest"
import AbstractMagOpsApi from "./AbstractMagOpsApi";
import IBillableCustomer from "../types/BillableCustomer";

class CustomerApi extends AbstractMagOpsApi {

	get(id: number) {
		return AxiosRequest.get<IBillableCustomer>(`/customer/${id}`, {
				signal: this.getRequestSignal()
			}
		)
	}

	list() {
		return AxiosRequest.get<IBillableCustomer[]>("/customer", {
				signal: this.getRequestSignal()
			}
		)
	}

	create(data: IBillableCustomer) {
		return AxiosRequest.post<IBillableCustomer>("/customer", data)
	}
}

export default new CustomerApi()