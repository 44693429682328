import AxiosRequest from "./AxiosRequest"
import AbstractMagOpsApi from "./AbstractMagOpsApi";
import ICustomerSite from "../types/CustomerSite";
import BatchCreateCustomerSites from "../types/BatchCreateCustomerSites";

class CustomerSiteApi extends AbstractMagOpsApi {

	get(id: number) {
		return AxiosRequest.get<ICustomerSite>(`/customer-site/${id}`, {
				signal: this.getRequestSignal()
			}
		)
	}

	list(billableCustomerId: number) {
		return AxiosRequest.get<ICustomerSite[]>("/customer-site", {
				params: {billableCustomerId},
				signal: this.getRequestSignal()
			}
		)
	}

	create(data: BatchCreateCustomerSites) {
		return AxiosRequest.post<ICustomerSite>("/customer-site", data)
	}
}

export default new CustomerSiteApi()