import React, {useContext, useEffect, useState} from "react";
import {useHistory} from "react-router-dom";
import AuthApi from "../../shared/api/AuthApi";
import {useSnackbar} from "notistack";
import {Box, Button, Hidden, LinearProgress, Paper, Stack, TextField} from "@mui/material";
import Heading from "../../components/forms/Heading";
import {NavContext} from "../../shared/providers/NavProvider";
import {ReactComponent as LogoWhite} from "../../assets/goterra_logo_white.svg";
import {validatePassword} from "../../shared/helpers";
import {saveTokens} from "../../shared/helpers/LocalStorageHelper";
import {AuthContext} from "../../shared/providers/AuthProvider";

const Login = () => {
	const [email, setEmail] = useState<string>();
	const [emailValid, setEmailValid] = useState(true);
	const [password, setPassword] = useState<string>();
	const [passwordValid, setPasswordValid] = useState(true);
	const [newPassword, setNewPassword] = useState<string>();
	const [newPasswordValid, setNewPasswordValid] = useState(true);
	const [session, setSession] = useState<string>();
	const [, navDispatch] = useContext(NavContext);
	const [, authDispatch] = useContext(AuthContext);
	const [isLoading, setIsLoading] = useState(false);
	let history = useHistory();
	const {enqueueSnackbar} = useSnackbar();

	const handleSubmit = async (e: any) => {
		e.preventDefault();
		if (email && password && emailValid && passwordValid) {
			try {
				setIsLoading(true);
				if (session && newPassword && newPasswordValid) {
					const response = await AuthApi.forcePasswordReset(email, newPassword, session);
					saveTokens(response.data.AuthenticationResult, authDispatch);
				} else {
					const response = await AuthApi.login(email, password);
					saveTokens(response.data.AuthenticationResult, authDispatch);
				}
				history.push("/");
			} catch (error) {
				setIsLoading(false);
				if (error.response && error.response.status === 302) {
					// We need to open and update the temp password
					setSession(error.response.data.Session);
					enqueueSnackbar("Welcome! Please enter your new password.", {variant: "info"})
				} else {
					// Something happened in setting up the request that triggered an Error
					enqueueSnackbar("Could not Login, check your password and try again.", {variant: "error"})
					console.log('Error', error.message);
				}
			}
		}
	}

	useEffect(() => {
		navDispatch({type: "SET_PAGE_DARK"})

		return () => {
			navDispatch({type: "SET_PAGE_LIGHT"})
		}
	}, [navDispatch])

	return (
		<Box display="flex" flexGrow={1} height="100vh" justifyContent="center" p={3}>
			<Stack>
				<Hidden smDown>
					<LogoWhite style={{height: 192}}/>
				</Hidden>
				<Hidden smUp>
					<LogoWhite style={{height: 128}}/>
				</Hidden>
				<Paper>
					<Box p={6}>
						<form onSubmit={handleSubmit}>
							<Heading heading={"Welcome to MagOps"} subheading="Please Sign In"/>
							<Box mt={6}>
								<TextField
									error={!emailValid}
									label="Email"
									fullWidth
									variant="outlined"
									type="email"
									onChange={event => {
										if (event.target.value !== "" && event.target.value.indexOf("@") > 0) {
											setEmailValid(true);
											setEmail(event.target.value);
										} else {
											setEmailValid(false);
										}
									}}
								/>
							</Box>
							<Box my={6}>
								<TextField
									error={!passwordValid}
									label="Password"
									fullWidth
									type="password"
									variant="outlined"
									onChange={event => {
										if (event.target.value !== "") {
											setPasswordValid(true);
											setPassword(event.target.value);
										} else {
											setPasswordValid(false);
										}
									}}
								/>
							</Box>
							{session &&
								<Box my={6}>
									<TextField
										error={!newPasswordValid}
										label="New Password"
										fullWidth
										type="password"
										variant="outlined"
										onChange={event => {
											if (validatePassword(event.target.value)) {
												setNewPasswordValid(true);
												setNewPassword(event.target.value);
											} else {
												setNewPasswordValid(false);
											}
										}}
										helperText="Requires numbers, uppercase, lowercase and at least 8 characters."
									/>
								</Box>
							}
							{isLoading
								?
								<LinearProgress/>
								:
								<Button
									type="submit"
									fullWidth
									color="primary"
									size="large"
									variant="contained"
									disabled={!emailValid || !passwordValid || !email || !password}
								>
									Sign In
								</Button>
							}
						</form>
					</Box>
				</Paper>
			</Stack>
		</Box>
	)
}

export default Login;
