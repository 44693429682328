import AxiosRequest from "./AxiosRequest"
import {formatDate} from "../helpers";
import IAviaryReport from "../types/AviaryReport";
import IIncubatorReport from "../types/IncubatorReport";
import IGrowRoomReport from "../types/GrowRoomReport";
import IDarkRoomReport from "../types/DarkRoomReport";
import IDayReport from "../types/DayReport";
import AbstractMagOpsApi from "./AbstractMagOpsApi";

class ReportsApi extends AbstractMagOpsApi {

	getAviary(siteId: number, startDate: Date, endDate?: Date) {
		return AxiosRequest.get<IAviaryReport>("/reports/aviary", {
				params: {
					siteId: siteId,
					startDate: formatDate(startDate),
					...(endDate && {
						endDate: formatDate(endDate)
					})
				},
				signal: this.getRequestSignal()
			}
		)
	}

	getIncubator(siteId: number, startDate: Date, endDate?: Date) {
		return AxiosRequest.get<IIncubatorReport>("/reports/incubator", {
				params: {
					siteId: siteId,
					startDate: formatDate(startDate),
					...(endDate && {
						endDate: formatDate(endDate)
					})
				},
				signal: this.getRequestSignal()
			}
		)
	}

	getGrowRoom(siteId: number, startDate: Date, endDate?: Date) {
		return AxiosRequest.get<IGrowRoomReport>("/reports/grow_room", {
				params: {
					siteId: siteId,
					startDate: formatDate(startDate),
					...(endDate && {
						endDate: formatDate(endDate)
					})
				},
				signal: this.getRequestSignal()
			}
		)
	}

	getDarkRoom(siteId: number, startDate: Date, endDate?: Date) {
		return AxiosRequest.get<IDarkRoomReport>("/reports/dark_room", {
				params: {
					siteId: siteId,
					startDate: formatDate(startDate),
					...(endDate && {
						endDate: formatDate(endDate)
					})
				},
				signal: this.getRequestSignal()
			}
		)
	}

	getDay(siteId: number, date: Date) {
		return AxiosRequest.get<IDayReport>("/reports/day", {
				params: {
					siteId: siteId,
					time: formatDate(date),
				},
				signal: this.getRequestSignal()
			}
		)
	}
}

export default new ReportsApi()