import Form from "../../../components/forms/Form";
import React, {useContext, useEffect, useState} from "react";
import {useHistory} from "react-router-dom";
import {NavContext} from "../../../shared/providers/NavProvider";
import ReportsApi from "../../../shared/api/ReportsApi";
import {format, subDays} from "date-fns";
import {useSnackbar} from "notistack";
import {DateRange} from "@mui/lab/DateRangePicker/RangeTypes";
import SingleDateAreaChart from "../../../components/dashboard/SingleDateAreaChart";
import AlertsBanner from "../../../components/dashboard/AlertsBanner";
import IAlert from "../../../shared/types/Alert";
import AlertsApi from "../../../shared/api/AlertsApi";
import IDarkRoomReport from "../../../shared/types/DarkRoomReport";
import {formatFloats} from "../../../shared/helpers";
import axios from "axios";
import {CSVLink} from "react-csv";
import {Button} from "@mui/material";

const DarkRoomDashboard = () => {
	const [navState] = useContext(NavContext);
	const [report, setReport] = useState<IDarkRoomReport>();
	const [isLoading, setIsLoading] = useState(true);
	const [alerts, setAlerts] = useState<Array<IAlert>>();
	const [dateRange, setDateRange] = React.useState<DateRange<Date>>(
		[subDays(new Date(), 7), new Date()]
	);
	const {enqueueSnackbar} = useSnackbar();

	let history = useHistory();

	useEffect(() => {
		(async () => {
			try {
				setIsLoading(true);
				if (navState.sites.length === 0 || !dateRange[0] || !dateRange[1]) {
					// Still waiting for sites to be populated
					return;
				}
				let response = await ReportsApi.getDarkRoom(navState.sites[navState.siteIndex].id, dateRange[0], dateRange[1]);
				setReport(response.data);
			} catch (error) {
				if (axios.isCancel(error)) {
					return;
				}
				// Something happened in setting up the request that triggered an Error
				enqueueSnackbar(`Failed to load dark room reports`, {variant: "error"})
				console.log('Error', error.message);
			}
		})()

		return () => ReportsApi.abort();
	}, [navState.sites, navState.siteIndex, enqueueSnackbar, history, dateRange])

	useEffect(() => {
		(async () => {
			try {
				setIsLoading(true);
				if (navState.sites.length === 0) {
					// Still waiting for sites to be populated
					return;
				}
				let response = await AlertsApi.get(navState.sites[navState.siteIndex].id, "dark_room");
				setAlerts(response.data);
			} catch (error) {
				if (axios.isCancel(error)) {
					return;
				}
				// Something happened in setting up the request that triggered an Error
				enqueueSnackbar(`Failed to find dark room alerts`, {variant: "error"})
				history.push("/not-found")
				console.log('Error', error.message);
			}
		})()

		return () => AlertsApi.abort();
	}, [navState.sites, navState.siteIndex, enqueueSnackbar, history])

	useEffect(() => {
		if (report && alerts) {
			setIsLoading(false);
		}
	}, [report, alerts])

	return (
		<Form
			heading={"Dark Room Dashboard"}
			dateRangeProps={{
				dateRange: dateRange,
				maxDate: new Date(),
				setDateRange: setDateRange
			}}
			loading={!report}
		>
			<AlertsBanner alerts={alerts}/>
			{report?.pupaeWeights &&
				<SingleDateAreaChart
					charts={[
						{key: 0, name: "Pre Pupae Weight", color: "mauve"},
						{key: 1, name: "Pupae Weight", color: "orange"}
					]}
					data={report.pupaeWeights.map(value => ({
						date: format(new Date(value.time), "dd/MM/yyyy"),
						0: value.prePupaeWeightMg !== null ? +formatFloats(value.prePupaeWeightMg) : null,
						1: value.pupaeWeightMg !== null ? +formatFloats(value.pupaeWeightMg) : null,
					}))}
					heading="Pre Pupae Weight In vs Pupae Weight Out"
					loading={isLoading}
					units="mg"
				>
					<CSVLink
						data={report.pupaeWeights.map(pupae => ({
							Date: format(new Date(pupae.time), "dd/MM/yyyy"),
							"Pre Pupae Weight (mg)": formatFloats(pupae.prePupaeWeightMg || 0),
							"Pupae Weight (mg)": formatFloats(pupae.pupaeWeightMg || 0),
							"Pupae Batch": pupae.pupaeBatch,
							"Pre Pupae Batch": pupae.prePupaeBatch,
						}))}
						filename={`MagOps_Dark_Room_Report_${format(dateRange[0] || 0, "dd/MM/yyyy")}-${format(dateRange[1] || 0, "dd/MM/yyyy")}`}
					>
						<Button color="primary" variant="contained" size="large">
							Download Data
						</Button>
					</CSVLink>
				</SingleDateAreaChart>
			}
		</Form>
	)
}

export default DarkRoomDashboard;