import {Grid} from "@mui/material";
import React, {useCallback, useContext, useEffect, useState} from "react";
import Aviary from "../../../components/forms/Aviary";
import FormSection from "../../../components/forms/FormSection";
import IAviary from "../../../shared/types/Aviary";
import AviaryApi from "../../../shared/api/AviaryApi";
import {useHistory, useParams} from "react-router-dom";
import Form from "../../../components/forms/Form";
import {useSnackbar} from "notistack";
import {NavContext} from "../../../shared/providers/NavProvider";
import {TodoContext} from "../../../shared/providers/TodoProvider";
import {nowTime, refreshTodo} from "../../../shared/helpers";
import IPupaeIntroMap from "../../../shared/types/PupaeIntroMap";
import PupaeIntroApi from "../../../shared/api/PupaeIntroApi";
import IPupaeIntro from "../../../shared/types/PupaeIntro";
import IBatch from "../../../shared/types/Batch";
import axios from "axios";
import useBatch from "../../../shared/helpers/useBatch";
import TaskApi from "../../../shared/api/TaskApi";

interface PupaeIntroParams {
	taskId?: string;
}

const PupaeIntroForm = () => {
	const [navState] = useContext(NavContext);
	const [aviaries, setAviaries] = useState<IAviary[]>();
	const [alertOpen, setAlertOpen] = useState(false);
	const [batch, setBatch] = useState<IBatch>();
	const [isLoading, setIsLoading] = useState(true);
	const [isLoadingPupaeIntro, setIsLoadingPupaeIntro] = useState(true);
	const [aviaryValues, setAviaryValues] = useState<Map<number, number>>(new Map())
	const [pupaeIntros, setPupaeIntros] = useState<IPupaeIntroMap>();
	const [, todoDispatch] = useContext(TodoContext);
	const {enqueueSnackbar} = useSnackbar();
	const [date, setDate] = useState<Date>(new Date())

	let history = useHistory();
	let {taskId} = useParams<PupaeIntroParams>();

	const setAviaryBiomassValue = (aviaryId: number, biomassKg: number) => {
		setAviaryValues(aviaryValues.set(aviaryId, biomassKg));
	}

	const getStockings = useCallback(async () => {
		try {
			if (batch) {
				setIsLoadingPupaeIntro(true);
				// After getting aviaries & batch, populate the eggs
				let response = await PupaeIntroApi.get(batch.id);
				let responseStockings: IPupaeIntroMap = {};
				response.data.forEach((stocking: IPupaeIntro) => {
					responseStockings[stocking.aviaryId] = stocking;
				});
				setPupaeIntros(responseStockings)
			}
		} catch (error) {
			if (axios.isCancel(error)) {
				return;
			}
			if (error.response) {
				if (error.response.status === 404) {
					let response: IPupaeIntroMap = {};
					setPupaeIntros(response)
				}
			} else {
				// Everything is impossible
				enqueueSnackbar("Couldn't load aviary stockings.", {variant: "error"})
				console.log('Error', error.message);
			}
			setIsLoadingPupaeIntro(false)
		}
	}, [enqueueSnackbar, batch])

	useBatch({
		taskIdStr: taskId,
		setBatch,
		setDate,
		setIsLoading
	})

	useEffect(() => {
		setIsLoadingPupaeIntro(false);
	}, [pupaeIntros])

	useEffect(() => {
		// Get the number of aviaries
		(async () => {
			try {
				setIsLoading(true);
				let response = await AviaryApi.getAll(navState.sites[navState.siteIndex].id);
				setAviaries(response.data.sort((a: IAviary, b: IAviary) => a.id - b.id))
			} catch (e) {
				if (axios.isCancel(e)) {
					return;
				}
				console.log(e)
			}
		})();

		return () => {
			AviaryApi.abort();
		}
	}, [navState.siteIndex, navState.sites])

	useEffect(() => {
		(async () => await getStockings())();

		return () => {
			return PupaeIntroApi.abort();
		}
	}, [aviaries, date, enqueueSnackbar, history, getStockings])

	useEffect(() => {
		if (batch && aviaries) {
			setIsLoading(false)
		}
	}, [batch, aviaries])

	const submitAviary = async (id: number, amount: number) => {
		if (batch) {
			try {
				// Amount is entered in kg, so we convert here to g
				amount = amount * 1000;
				setIsLoadingPupaeIntro(true)
				await PupaeIntroApi.create({
					aviaryId: id,
					batchId: batch.id,
					biomassG: amount,
					...(date && {
						time: nowTime(date)
					})
				});
				setAviaryValues(new Map());
				enqueueSnackbar("Successfully submitted aviary stocking", {variant: "success"})
				// Force update all the eggs
				await getStockings()
			} catch (e) {
				enqueueSnackbar("Failed to submit stocking entry", {variant: "error"})
				setIsLoadingPupaeIntro(false);
				console.log('Error', e.message);
			}
		}
	}

	const submitBatch = async () => {
		if (batch && pupaeIntros && Object.keys(pupaeIntros).length > 0 && taskId && aviaries) {
			const taskIdNum = parseInt(taskId);
			if (isNaN(taskIdNum)) {
				return
			}
			try {
				// Collecting 0 eggs isn't worth recording, we can assume it and fill zeroes with timescale
				setIsLoading(true)
				// Make sure we have the latest egg data from the server.
				let response = await PupaeIntroApi.get(batch.id);
				let responsePupaeIntros: IPupaeIntroMap = {};
				response.data.forEach((stocking: IPupaeIntro) => {
					responsePupaeIntros[stocking.aviaryId] = stocking;
				});
				setPupaeIntros(responsePupaeIntros)
				// Attempt to collate all egg measurements on the page
				for (let aviary of aviaries) {
					if (!pupaeIntros[aviary.id]) {
						// Check if we have a non-0 value in the other columns
						let amount = aviaryValues.get(aviary.id)
						if (amount && amount > 0) {
							amount = amount * 1000;
							await PupaeIntroApi.create({
								aviaryId: aviary.id,
								batchId: batch.id,
								biomassG: amount,
								...(date && {
									time: nowTime(date)
								})
							});
						}
					}
				}
				await TaskApi.postDone(taskIdNum)
				enqueueSnackbar("Successfully submitted pupae intro data", {variant: "success"})
				history.push("/")
			} catch (e) {
				enqueueSnackbar("Failed to submit pupae intro data", {variant: "error"});
				setIsLoading(false);
				console.log('Error', e.message);
			}
		}
	}

	const submit = async () => {
		await submitBatch();
		await refreshTodo(navState, todoDispatch);
	}

	const handleFormPaging = async (back = false) => {
		if (!back) {
			if (pupaeIntros && aviaries && aviaries.length - Object.keys(pupaeIntros).length === 0) {
				await submit();
			} else {
				setAlertOpen(true);
			}
		}
	}

	return (
		<Form
			heading="Pupae Intro"
			subheading={batch ? `Batch ${batch.localId.toString().padStart(4, "0")}` : ""}
			loading={isLoading}
			handleFormPaging={handleFormPaging}
			nextDisabled={isLoadingPupaeIntro || !((pupaeIntros && Object.keys(pupaeIntros).length > 0) || aviaryValues.size > 0)}
			dateProps={{
				date: date,
				setDate: setDate,
				readonly: true
			}}
			alertProps={{
				setOpen: setAlertOpen,
				open: alertOpen,
				missing: pupaeIntros && aviaries ? aviaries?.length - Object.keys(pupaeIntros).length - aviaryValues.size : 0,
				submit: submit
			}}
		>
			<FormSection>
				<Grid container spacing={3}>
					{aviaries?.map((aviary: IAviary) => {
						return <Aviary
							key={aviary.id}
							id={aviary.id}
							name={aviary.name}
							submit={submitAviary}
							valueIn={pupaeIntros && pupaeIntros[aviary.id] ? pupaeIntros[aviary.id].biomassG / 1000 : null}
							measurement="Biomass Introduced"
							units="kg"
							loading={isLoadingPupaeIntro}
							setAviaryValue={setAviaryBiomassValue}
						/>
					})}
				</Grid>
			</FormSection>
		</Form>
	)
}

export default PupaeIntroForm;