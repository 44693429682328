import ISite from "../types/Site";

export type NavState = {
	siteIndex: number;
	sites: ISite[];
	navOpen: boolean;
	navOpenMobile: boolean;
	todoOpen: boolean;
	todoOpenMobile: boolean;
	darkMode: boolean;
	disableNav: boolean;
}

export type NavAction =
	| { type: "SET_SITE", data: number }
	| { type: "SET_SITES", data: ISite[] }
	| { type: "SET_NAV_DISABLED", data: boolean }
	| { type: "OPEN_NAV" }
	| { type: "CLOSE_NAV" }
	| { type: "OPEN_TODO" }
	| { type: "CLOSE_TODO" }
	| { type: "CLOSE_NAV_MOBILE" }
	| { type: "OPEN_NAV_MOBILE" }
	| { type: "CLOSE_TODO_MOBILE" }
	| { type: "OPEN_TODO_MOBILE" }
	| { type: "SET_PAGE_DARK" }
	| { type: "SET_PAGE_LIGHT" }

export const navReducer = (state: NavState, action: NavAction): NavState => {
	switch (action.type) {
		case "SET_SITE":
			return {
				...state,
				siteIndex: action.data
			}
		case "SET_SITES":
			return {
				...state,
				sites: action.data
			}
		case "OPEN_NAV":
			return {
				...state,
				navOpen: true
			}
		case "CLOSE_NAV":
			return {
				...state,
				navOpen: false
			}
		case "CLOSE_TODO":
			return {
				...state,
				todoOpen: false
			}
		case "OPEN_TODO":
			return {
				...state,
				todoOpen: true
			}
		case "CLOSE_NAV_MOBILE":
			return {
				...state,
				navOpenMobile: false
			}
		case "OPEN_NAV_MOBILE":
			return {
				...state,
				navOpenMobile: true
			}
		case "CLOSE_TODO_MOBILE":
			return {
				...state,
				todoOpenMobile: false
			}
		case "OPEN_TODO_MOBILE":
			return {
				...state,
				todoOpenMobile: true
			}
		case "SET_PAGE_DARK":
			return {
				...state,
				darkMode: true
			}
		case "SET_PAGE_LIGHT":
			return {
				...state,
				darkMode: false
			}
		case "SET_NAV_DISABLED":
			return {
				...state,
				disableNav: action.data
			}
		default:
			return state;
	}
}

export const initialNavState: NavState = {
	sites: [],
	todoOpen: true,
	navOpen: true,
	darkMode: false,
	navOpenMobile: false,
	todoOpenMobile: false,
	disableNav: true,
	siteIndex: 0,
}