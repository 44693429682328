import AxiosRequest from "./AxiosRequest"
import IEggs from "../types/Eggs";
import AbstractMagOpsApi from "./AbstractMagOpsApi";

class EggsApi extends AbstractMagOpsApi {

	get(batchId: number) {
		return AxiosRequest.get<IEggs[]>("/eggs", {
			params: {
				batchId: batchId
			},
			signal: this.getRequestSignal()
		})
	}

	create(data: IEggs) {
		return AxiosRequest.post<IEggs>("/eggs", data)
	}
}

export default new EggsApi()