import {
	Drawer,
	DrawerProps,
	ListItemButton,
	ListItemButtonProps,
	ListItemIcon,
	ListItemIconProps,
	styled,
	SwipeableDrawer
} from "@mui/material";
import {MagOpsColors} from "../../shared/styles/MagOpsStyle";

interface NavListItemButtonProps extends ListItemButtonProps {
	nested?: boolean;
}

export const NavListItemButton = styled(ListItemButton)<NavListItemButtonProps>(({nested, selected, theme}) => ({
	...(nested && {
		paddingLeft: theme.spacing(4),
	}),
	...(selected && {
		borderRight: "3px solid",
		borderRightColor: MagOpsColors.primary.goterraSky,
		"& .MuiTypography-root": {
			color: MagOpsColors.primary.goterraSky,
			fontWeight: "bold",
		}
	})
}))

interface NavListItemIconProps extends ListItemIconProps {
	selected?: boolean;
}

export const NavListItemIcon = styled(ListItemIcon)<NavListItemIconProps>(({selected, theme}) => ({
	color: MagOpsColors.primary.maggotGrey,
	...(selected && {
		color: MagOpsColors.primary.goterraSky
	})
}))

interface StyledDrawerProps extends DrawerProps {
	drawerWidth: number
}

export const StyledDrawer = styled(Drawer)<StyledDrawerProps>(({drawerWidth, theme}) => ({
	width: drawerWidth,
	"& .MuiDrawer-paper": {
		flexGrow: 1,
		width: drawerWidth,
		margin: theme.spacing(1),
		marginTop: theme.spacing(9),
	}
}))

export const StyledDrawerMobile = styled(SwipeableDrawer)<StyledDrawerProps>(({drawerWidth, theme}) => ({
	width: drawerWidth,
	"& .MuiDrawer-paper": {
		width: drawerWidth,
	}
}))