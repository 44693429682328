import {Badge, Box, CardActionArea, CardHeader, Collapse, Theme, useMediaQuery, useTheme} from "@mui/material";
import {CheckCircle, ExpandLess, ExpandMore} from "@mui/icons-material";
import React, {useState} from "react";
import {MagOpsColors} from "../../shared/styles/MagOpsStyle";
import IProductionTask from "../../shared/types/ProductionTask";
import {StyledTodoListCard} from "./TodoStyles";

type Props = {
	isLoading: boolean;
	title: string;
	avatar: React.ReactNode;
	tasks: Array<IProductionTask>;
	mapper: (task: IProductionTask) => React.ReactNode;
}

const TodoListCard = ({isLoading, title, avatar, tasks, mapper}: Props) => {
	const smUp = useMediaQuery((theme: Theme) => theme.breakpoints.up("sm"));
	const [isOpen, setIsOpen] = useState(smUp);
	const theme = useTheme();

	const tasksLeft = tasks.filter(task => task.status !== "Optional" && task.status !== "Done").length;
	const optionalTasks = tasks.filter(task => task.status === "Optional").length;

	const openCard = (event: any) => {
		event.stopPropagation();
		setIsOpen(!isOpen);
	}

	return (
		<StyledTodoListCard>
			{
				!isLoading &&
				<Box>
					<CardActionArea onClick={openCard}>
						<CardHeader
							avatar={
								<Badge
									badgeContent={tasksLeft || optionalTasks} // Sometimes JS works as you want
									color={tasksLeft ? "error" : "warning"}
									// Only way to get an avatar to always inherit
									sx={{
										color: theme.palette.primary.main
									}}
								>
									{tasksLeft <= 0 ? <CheckCircle color="success"/> : avatar}
								</Badge>
							}
							title={title}
							titleTypographyProps={{
								variant: "h3",
								color: MagOpsColors.primary.goterraSky
							}}
							action={isOpen ? <ExpandLess color="secondary"/> : <ExpandMore color="secondary"/>}
						>

						</CardHeader>
					</CardActionArea>
					<Collapse in={isOpen} timeout="auto" unmountOnExit>
						{
							tasks.map(mapper)
						}
					</Collapse>
				</Box>
			}
		</StyledTodoListCard>
	)
}

export default TodoListCard;