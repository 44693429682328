import {Collapse, List, ListItemButton, ListItemText} from "@mui/material";
import {CalendarToday, Dashboard, DeveloperBoard, ExpandLess, ExpandMore, Home, Poll} from "@mui/icons-material";
import React, {useEffect, useState} from "react";
import SecondarySideNav from "./SecondarySideNav";
import NavListItem from "./NavListItem";
import {NavListItemIcon} from "./NavigationStyles";
import {useLocation} from "react-router-dom";

export const MainSideNav = () => {
	const location = useLocation()
	const [subNavOpen, setSubNavOpen] = useState(true);
	const [selected, setSelected] = useState(location.pathname.includes("lifecycle"))

	const handleClick = (event: any) => {
		event.stopPropagation();
		setSubNavOpen(!subNavOpen);
	};

	useEffect(() => {
		setSelected(location.pathname.includes("lifecycle"));
	}, [location])

	return (
		<List>
			<NavListItem route="/" text="Home">
				<Home/>
			</NavListItem>
			<NavListItem route="/batches" text="Batches">
				<CalendarToday/>
			</NavListItem>
			<NavListItem disabled route="/mibs" text="MIB Status">
				<DeveloperBoard/>
			</NavListItem>
			<NavListItem route="/waste" text="Waste">
				<Poll/>
			</NavListItem>
			<ListItemButton onClick={handleClick}>
				<NavListItemIcon selected={selected}>
					<Dashboard/>
				</NavListItemIcon>
				<ListItemText primary="Reports"/>
				{subNavOpen ? <ExpandLess/> : <ExpandMore/>}
			</ListItemButton>
			<Collapse in={subNavOpen} timeout="auto" unmountOnExit>
				<SecondarySideNav/>
			</Collapse>
		</List>
	);
}