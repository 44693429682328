import IAuthResponse from "../types/AuthResponse";
import AxiosRequest from "./AxiosRequest";
import AbstractMagOpsApi from "./AbstractMagOpsApi";

class AuthApi extends AbstractMagOpsApi {

	login(email: string, password: string) {
		return AxiosRequest.post<IAuthResponse>("/auth/sign_in", {
			email,
			password
		});
	}

	forcePasswordReset(email: string, password: string, session: string) {
		return AxiosRequest.post<IAuthResponse>("/auth/force_password_reset", {
			email,
			password,
			session
		});
	}

	create(email: string, password: string, groups?: string[]) {
		return AxiosRequest.post<{}>("/auth/register", {
			email,
			password,
			groups
		});
	}

	refreshToken(token: string | null) {
		return AxiosRequest.post<IAuthResponse>("/auth/refresh_token", {
			token
		});
	}

	logout(token: string) {
		return AxiosRequest.post<{}>("/auth/sign_out", {
			token
		});
	}
}

export default new AuthApi()