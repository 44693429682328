import AxiosRequest from "./AxiosRequest"
import AbstractMagOpsApi from "./AbstractMagOpsApi";
import IProductionTask from "../types/ProductionTask";

class TaskApi extends AbstractMagOpsApi {

	get(siteId: number) {
		return AxiosRequest.get<Array<IProductionTask>>("/tasks", {
			params: {
				siteId: siteId,
				timezone: Intl.DateTimeFormat().resolvedOptions().timeZone
			},
			signal: this.getRequestSignal()
		})
	}

	postDone(taskId: number) {
		return AxiosRequest.post<Array<IProductionTask>>("/task", {
			taskId: taskId
		})
	}
}

export default new TaskApi()