import AxiosRequest from "./AxiosRequest"
import IPupaeIntro from "../types/PupaeIntro";
import AbstractMagOpsApi from "./AbstractMagOpsApi";

class PupaeIntroApi extends AbstractMagOpsApi {

	create(data: IPupaeIntro) {
		return AxiosRequest.post<IPupaeIntro>("/pupae_intro", data)
	}

	get(batchId: number) {
		return AxiosRequest.get<IPupaeIntro[]>("/pupae_intro", {
			params: {
				batchId: batchId
			},
			signal: this.getRequestSignal()
		})
	}
}

export default new PupaeIntroApi()