import AxiosRequest from "./AxiosRequest"
import Config from "../types/Config";
import AbstractMagOpsApi from "./AbstractMagOpsApi";

class ConfigApi extends AbstractMagOpsApi {

	get(siteId: number) {
		return AxiosRequest.get<Config>("/config", {
			params: {
				siteId: siteId
			},
			signal: this.getRequestSignal()
		})
	}

	create(config: Config) {
		return AxiosRequest.post<Config>("/config", config)
	}

	default(siteId: number) {
		return AxiosRequest.post<{}>("/config/default", {siteId: siteId})
	}
}

export default new ConfigApi()