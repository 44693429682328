import React, {useContext, useEffect, useState} from "react";
import {useHistory, useParams} from "react-router-dom";
import Form from "../../../components/forms/Form";
import ValueInputCard from "../../../components/forms/ValueInputCard";
import FormOutputs from "../../../components/forms/FormOutputs";
import {useSnackbar} from "notistack";
import {NavContext} from "../../../shared/providers/NavProvider";
import {TodoContext} from "../../../shared/providers/TodoProvider";
import IBatch from "../../../shared/types/Batch";
import {nowTime, refreshTodo} from "../../../shared/helpers";
import SiftingApi from "../../../shared/api/SiftingApi";
import useBatch from "../../../shared/helpers/useBatch";
import ValueCard from "../../../components/forms/ValueCard";

interface SiftingParams {
	taskId?: string;
}

const SiftingForm = () => {
	const [navState] = useContext(NavContext);
	const [batch, setBatch] = useState<IBatch>();
	const [isLoading, setIsLoading] = useState(true)
	const [traysAvailable, setTraysAvailable] = useState<number>();
	const [biomass, setBiomass] = useState<number>(0);
	const [biomassValid, setBiomassValid] = useState<boolean>(false);
	const [prePupaeWeight, setPrePupaeWeight] = useState<number>(0);
	const [prePupaeWeightValid, setPrePupaeWeightValid] = useState<boolean>(false);
	const [frassPercentage, setFrassPercentage] = useState<number>(0);
	const [frassPercentageValid, setFrassPercentageValid] = useState<boolean>(false);
	const [date, setDate] = useState<Date>(new Date());
	const [, todoDispatch] = useContext(TodoContext);
	const {enqueueSnackbar} = useSnackbar();

	let history = useHistory();
	let {taskId} = useParams<SiftingParams>()

	useBatch({
		taskIdStr: taskId,
		setBatch,
		setDate,
		setIsLoading
	})

	useEffect(() => {
		if (batch && batch.transfers && batch.transfers[0].breederTrays) {
			setTraysAvailable(batch.transfers[0].breederTrays);
		}
	}, [batch])

	useEffect(() => {
		if (traysAvailable && batch) {
			setIsLoading(false)
		}
	}, [traysAvailable, batch])

	const submit = async () => {
		if (batch && date !== null && taskId) {
			const taskIdNum = parseInt(taskId);
			if (isNaN(taskIdNum)) {
				return
			}
			try {
				setIsLoading(true)
				await SiftingApi.create({
					batchId: batch.id,
					biomassG: biomass,
					prePupaeWeightMg: prePupaeWeight,
					frassPercentage: frassPercentage,
					taskId: taskIdNum,
					time: nowTime(date)
				})
				enqueueSnackbar("Successfully submitted sifting data", {variant: "success"})
				history.push("/")
			} catch (e) {
				enqueueSnackbar("Failed to submit sifting data", {variant: "error"});
				setIsLoading(false);
				console.log('Error', e.message);
			}
		}
	}

	const handleFormPaging = async (back = false) => {
		if (!back) {
			await submit()
			await refreshTodo(navState, todoDispatch)
		}
	}

	return (
		<Form
			heading="Sifting"
			subheading={`Batch ${batch?.localId.toString().padStart(4, "0")}`}
			loading={isLoading}
			handleFormPaging={handleFormPaging}
			nextDisabled={!biomassValid || !prePupaeWeightValid || !frassPercentageValid}
			dateProps={{
				date: date,
				setDate: setDate,
				readonly: true
			}}
		>
			<FormOutputs>
				<ValueInputCard
					heading="Total Biomass"
					valid={biomassValid}
					setValue={value => setBiomass(value * 1000)}
					setValid={setBiomassValid}
					units="kg"
				/>
				<ValueInputCard
					heading="Pre Pupae Weight"
					valid={prePupaeWeightValid}
					setValue={setPrePupaeWeight}
					setValid={setPrePupaeWeightValid}
					units="mg"
				/>
				<ValueInputCard
					heading="Frass Percentage"
					valid={frassPercentageValid}
					setValue={value => setFrassPercentage(value)}
					setValid={setFrassPercentageValid}
					units="%"
				/>
				<ValueCard
					heading="Frass"
					value={frassPercentage && biomass ? (frassPercentage / 100 * biomass) / 1000 + "kg" : 0}
				/>
			</FormOutputs>
		</Form>
	)
}

export default SiftingForm;