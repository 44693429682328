import AxiosRequest from "./AxiosRequest"
import IAlert from "../types/Alert";
import AbstractMagOpsApi from "./AbstractMagOpsApi";

type Report = "incubator" | "grow_room" | "dark_room"

class AlertsApi extends AbstractMagOpsApi {

	get(siteId: number, report?: Report) {
		return AxiosRequest.get<IAlert[]>("/alerts", {
				params: {
					timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
					siteId: siteId,
					...(report && {
						report
					})
				},
				signal: this.getRequestSignal()
			}
		)
	}
}

export default new AlertsApi()