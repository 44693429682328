import AxiosRequest from "./AxiosRequest"
import AbstractMagOpsApi from "./AbstractMagOpsApi";
import IHatchingTrays from "../types/IncubatorTransfer";
import ICreateHatchingTraysRequest from "../types/CreateHatchingTraysRequest";

class HatchingTraysApi extends AbstractMagOpsApi {
	get(batchId: number) {
		return AxiosRequest.get<IHatchingTrays>("/hatching_trays", {
			params: {
				batchId: batchId
			},
			signal: this.getRequestSignal()
		})
	}

	create(data: ICreateHatchingTraysRequest) {
		return AxiosRequest.post<ICreateHatchingTraysRequest>("/hatching_trays", data)
	}
}

export default new HatchingTraysApi()