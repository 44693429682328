import {Box, CircularProgress, Stack, Typography} from "@mui/material";
import React, {useContext} from "react";
import {NavContext} from "../../shared/providers/NavProvider";
import {MagOpsColors} from "../../shared/styles/MagOpsStyle";
import LOADING_MESSAGES from "../../shared/LoadingMessages";

type Props = {
	children?: React.ReactNode | React.ReactNode[]
	loading?: boolean
	small?: boolean;
	lame?: boolean;
};

const Layout = ({children, loading, small = false, lame}: Props) => {
	const [navState,] = useContext(NavContext);

	return (
		<React.Fragment>
			{loading
				?
				lame ? <CircularProgress size={small ? 20 : 40} sx={{
						...(navState.darkMode && {
							color: "white"
						})
					}}/>
					:
					<Box display="flex" flexGrow={1} height="80vh" justifyContent="center" alignItems="center">
						<Stack display="flex" justifyContent="center" alignItems="center">
							<Typography fontSize="1.1rem" p={3} sx={{
								color: MagOpsColors.primary.goterraSky,
								...(navState.darkMode && {
									color: "white"
								})
							}}>
								{LOADING_MESSAGES[Math.floor(Math.random() * LOADING_MESSAGES.length)]}
							</Typography>
							<CircularProgress size={small ? 20 : 40} sx={{
								...(navState.darkMode && {
									color: "white"
								})
							}}/>
						</Stack>
					</Box>
				: children
			}
		</React.Fragment>
	)
}

export default Layout;