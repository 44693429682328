import Form from "../../../components/forms/Form";
import React, {useContext, useEffect, useState} from "react";
import {useHistory} from "react-router-dom";
import {NavContext} from "../../../shared/providers/NavProvider";
import ReportsApi from "../../../shared/api/ReportsApi";
import {format, subDays} from "date-fns";
import {useSnackbar} from "notistack";
import AviaryChart from "../../../components/dashboard/AviaryChart";
import {DateRange} from "@mui/lab/DateRangePicker/RangeTypes";
import IAviaryReport from "../../../shared/types/AviaryReport";
import SingleDateAreaChart from "../../../components/dashboard/SingleDateAreaChart";
import axios from "axios";
import {formatFloats} from "../../../shared/helpers";
import IAviaryEggs from "../../../shared/types/AviaryEggs";
import IAviaryBiomass from "../../../shared/types/AviaryBiomass";
import {Box, Button, Checkbox, FormControlLabel, Typography} from "@mui/material";
import {CSVLink} from "react-csv";

const AviaryDashboard = () => {
	const [navState] = useContext(NavContext);
	const [report, setReport] = useState<IAviaryReport>();
	const [isLoading, setIsLoading] = useState(true);
	const [aviaryDateRange, setAviaryDateRange] = React.useState<DateRange<Date>>(
		[subDays(new Date(), 7), new Date()]
	);
	const {enqueueSnackbar} = useSnackbar();
	const [fgs, setFgs] = useState<boolean>(true);

	let history = useHistory();

	useEffect(() => {
		(async () => {
			try {
				setIsLoading(true);
				if (navState.sites.length === 0 || !aviaryDateRange[0] || !aviaryDateRange[1]) {
					// Still waiting for sites to be populated
					return;
				}
				let response = await ReportsApi.getAviary(navState.sites[navState.siteIndex].id, aviaryDateRange[0], aviaryDateRange[1]);
				setReport(response.data);
			} catch (error) {
				if (axios.isCancel(error)) {
					return;
				}
				// Something happened in setting up the request that triggered an Error
				enqueueSnackbar(`Failed to load aviary reports`, {variant: "error"})
				console.log('Error', error.message);
			}
		})()

		return () => ReportsApi.abort();
	}, [navState.sites, navState.siteIndex, enqueueSnackbar, history, aviaryDateRange])

	useEffect(() => {
		if (report) {
			setIsLoading(false);
		}
	}, [report])

	return (
		<Form
			heading={"Aviary Dashboard"}
			dateRangeProps={{
				dateRange: aviaryDateRange,
				maxDate: new Date(),
				setDateRange: setAviaryDateRange
			}}
			loading={!report}
		>
			{report?.reportGraph &&
				<React.Fragment>
					<SingleDateAreaChart
						charts={[
							{key: 0, name: "Eggs (g)", color: "mauve"},
							{key: 1, name: "Biomass (Kg)", color: "blue"},
							{key: 2, name: "Pupae Weight (mg)", color: "orange"},
						]}
						data={report.reportGraph.map(value => ({
							date: format(new Date(value.time), "dd/MM/yyyy"),
							0: value.eggs != null ? value.eggs / 1000 : null,
							1: value.biomass != null ? value.biomass / 1000 : null,
							2: value.pupae != null ? value.pupae : null
						}))}
						heading="Eggs Collected vs Biomass Introduced vs Pupae Weight"
						loading={isLoading}
					>
						<CSVLink
							data={report.reportGraph.map(report => ({
								Date: format(new Date(report.time), "dd/MM/yyyy"),
								"Eggs (g)": (report.eggs || 0) / 1000,
								"Biomass (kg)": (report.biomass || 0) / 1000,
								"Pupae (mg)": (report.pupae || 0),
								"Eggs Batch": report.eggsBatch,
								"Pupae Intro Batch": report.pupaeIntroBatch,
							}))}
							filename={`MagOps_Aviaries_Report_${format(aviaryDateRange[0] || 0, "dd/MM/yyyy")}-${format(aviaryDateRange[1] || 0, "dd/MM/yyyy")}`}
						>
							<Button color="primary" variant="contained" size="large">
								Download Data
							</Button>
						</CSVLink>
					</SingleDateAreaChart>
				</React.Fragment>
			}
			{report &&
				<React.Fragment>
					<AviaryChart
						title="Eggs Collected by Aviary (g)"
						subtitle={`Total: ${formatFloats(report.aviaryEggs.filter(value => !fgs ? value.aviary !== "Future Green Solutions" : true).reduce((accum: number, egg: IAviaryEggs) => accum + egg.eggsG, 0))}g`}
						data={report && report.aviaryEggs.filter(value => !fgs ? value.aviary !== "Future Green Solutions" : true).map(value => ({
							name: value.aviary,
							value: value.eggsG
						}))}
						loading={isLoading}
					>
						<CSVLink
							data={report.aviaryEggs.map(report => ({
								Aviary: report.aviary,
								"Eggs (g)": (report.eggsG || 0),
							}))}
							filename={`MagOps_Aviary_Eggs_Collected_${format(aviaryDateRange[0] || 0, "dd/MM/yyyy")}-${format(aviaryDateRange[1] || 0, "dd/MM/yyyy")}`}
						>
							<Button color="primary" variant="contained" size="large">
								Download Data
							</Button>
						</CSVLink>
						<Box marginX={2}>
							<FormControlLabel control={<Checkbox checked={fgs} onChange={() => setFgs(!fgs)}/>}
																label={<Typography variant={"h4"}>Show FGS</Typography>}/>
						</Box>
					</AviaryChart>
					<AviaryChart
						title="Biomass Introduced by Aviary (Kg)"
						subtitle={`Total: ${formatFloats(report.aviaryBiomass.reduce((accum: number, biomass: IAviaryBiomass) => accum + biomass.biomassKg, 0))}kg`}
						data={report && report.aviaryBiomass.map(value => ({
							name: value.aviary,
							value: value.biomassKg
						}))}
						loading={isLoading}
					>
						<CSVLink
							data={report.aviaryBiomass.map(report => ({
								Aviary: report.aviary,
								"Biomass (kg)": (report.biomassKg || 0),
							}))}
							filename={`MagOps_Aviary_Biomass_Introduced_${format(aviaryDateRange[0] || 0, "dd/MM/yyyy")}-${format(aviaryDateRange[1] || 0, "dd/MM/yyyy")}`}
						>
							<Button color="primary" variant="contained" size="large">
								Download Data
							</Button>
						</CSVLink>
					</AviaryChart>
				</React.Fragment>
			}
		</Form>
	)
}

export default AviaryDashboard;