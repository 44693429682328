import React, {useEffect, useState} from "react";
import {useHistory} from "react-router-dom";
import axios from "axios";
import ICustomerSite from "../../shared/types/CustomerSite";
import CustomerSiteApi from "../../shared/api/CustomerSiteApi";
import {useSnackbar} from "notistack";
import SiteApi from "../../shared/api/SiteApi";
import Form from "../../components/forms/Form";
import IBillableCustomer from "../../shared/types/BillableCustomer";
import useCustomers from "../../shared/helpers/useCustomers";
import FormSection from "../../components/forms/FormSection";
import {Autocomplete, Grid, Typography} from "@mui/material";
import {FormValueCard, FormValueTextField} from "../../components/forms/FormStyles";
import Heading from "../../components/forms/Heading";
import Loading from "../../components/layout/Loading";
import Waste from "../../components/forms/Waste";
import WasteApi from "../../shared/api/WasteApi";

const WasteForm = () => {
	const [isLoading, setIsLoading] = useState(true)
	const [loadingCustomerSites, setLoadingCustomerSites] = useState(false)
	const [customerSites, setCustomerSites] = useState<ICustomerSite[]>([]);
	const [customerSite, setCustomerSite] = useState<ICustomerSite | null>(null);
	const [customers, setCustomers] = useState<IBillableCustomer[]>([]);
	const [customer, setCustomer] = useState<IBillableCustomer | null>(null);
	const [date, setDate] = useState<Date | null>(new Date());
	const {enqueueSnackbar} = useSnackbar();

	let history = useHistory();

	useEffect(() => {
		(async () => {
			if (customer && customer.id) {
				try {
					setLoadingCustomerSites(true);
					let response = await CustomerSiteApi.list(customer.id);
					setCustomerSites(response.data);
				} catch (error) {
					if (axios.isCancel(error)) {
						return;
					}
					// Something happened in setting up the request that triggered an Error
					enqueueSnackbar(`Failed to find customers`, {variant: "error"})
					console.log('Error', error.message);
				}
			}
		})()

		return () => SiteApi.abort();
	}, [enqueueSnackbar, history, customer])

	useCustomers({setCustomers, setIsLoading})

	useEffect(() => {
		if (customers) {
			setIsLoading(false);
		}
	}, [customers])

	useEffect(() => {
		if (customerSites) {
			setLoadingCustomerSites(false);
		}
	}, [customerSites])

	const showError = (error: Error) => {
		enqueueSnackbar("Failed to create customer information", {variant: "error"})
		console.log('Error', error.message);
		setIsLoading(false);
	}

	// @ts-ignore
	return (
		<Form
			heading="Waste Collection"
			loading={isLoading}
			dateProps={{
				date: date,
				setDate: setDate,
			}}
		>
			{customers &&
				<FormSection half>
					<Grid container spacing={3} justifyContent="center" alignContent="center">
						<Grid item xs={12}>
							<Heading subheading="Customer"/>
						</Grid>
						<Grid item xs={12} xl={6} sx={{display: "flex"}}>
							<FormValueCard variant="outlined" style={{flexGrow: 1}}>
								<Typography component="p" variant="h6" align="center">
									Select Customer
								</Typography>
								<Autocomplete
									value={customer}
									options={customers}
									onChange={(event, newValue) => {
										setCustomer(newValue);
									}}
									getOptionLabel={option => option.name}
									renderInput={(params) => (
										<FormValueTextField
											{...params}
											variant="standard"
										/>
									)}
								/>
							</FormValueCard>
						</Grid>
					</Grid>
				</FormSection>
			}
			<FormSection half>
				<Grid container spacing={3} justifyContent="center" alignContent="center">
					<Grid item xs={12}>
						<Heading subheading="Site"/>
					</Grid>
					<Loading loading={loadingCustomerSites} lame>
						<Grid item xs={12} xl={6} sx={{display: "flex"}}>
							<FormValueCard variant="outlined" style={{flexGrow: 1}}>
								<Typography component="p" variant="h6" align="center">
									Select Customer Site
								</Typography>
								<Autocomplete
									value={customerSite}
									options={customerSites}
									onChange={(event, newValue) => {
										setCustomerSite(newValue);
									}}
									getOptionLabel={option => option.name}
									renderInput={(params) => (
										<FormValueTextField
											{...params}
											variant="standard"
										/>
									)}
								/>
							</FormValueCard>
						</Grid>
					</Loading>
				</Grid>
			</FormSection>
			{customerSite &&
				<Waste
					show={true}
					header="Add Waste"
					submit={(async (site, customerSiteIn, binType, bins, wasteG, notes) => {
						try {
							if (date && customerSiteIn.id) {
								setIsLoading(true);
								await WasteApi.create({
									binType: binType,
									bins: bins,
									customerSiteId: customerSiteIn.id,
									notes: notes,
									siteId: site.id,
									time: date,
									wasteG: wasteG
								});
								setIsLoading(false);
								enqueueSnackbar(`Successfully added waste`, {variant: "success"})
							}
						} catch (error) {
							showError(error);
						}
					})}
					customerSite={customerSite}
				/>
			}
		</Form>
	)
}

export default WasteForm;