import {
	AccordionDetails,
	AccordionDetailsProps,
	AccordionSummary,
	AccordionSummaryProps,
	alpha,
	Card,
	CardProps,
	Paper,
	PaperProps,
	styled,
	TextField,
	TextFieldProps
} from "@mui/material";
import {MagOpsColors} from "../../shared/styles/MagOpsStyle";

interface ActiveAccordionSummaryProps extends AccordionSummaryProps {
	active: boolean;
}

export const StyledAccordionSummary = styled(AccordionSummary)<ActiveAccordionSummaryProps>(({active, theme}) => ({
	backgroundColor: MagOpsColors.primary.duskBlue,
	padding: theme.spacing(0),
	paddingLeft: theme.spacing(2),
	paddingRight: theme.spacing(2),
	"& .MuiAccordionSummary-content": {
		display: "flex",
		alignItems: "center"
	},
	...(active && {
		backgroundColor: MagOpsColors.primary.maggotLightGrey,
		padding: theme.spacing(0),
		paddingLeft: theme.spacing(2),
		paddingRight: theme.spacing(2),
	}),
}))

export const StyledAccordionDetails = styled(AccordionDetails)<AccordionDetailsProps>(({theme}) => ({
	padding: theme.spacing(2),
	display: "flex",
	alignItems: "center"
}))


export const FormPaper = styled(Paper)<PaperProps>(({theme}) => ({
	padding: theme.spacing(3)
}));

export const FormSubmitLayout = styled("div")(({theme}) => ({
	display: "flex",
	justifyContent: "space-between"
}));

export const FormValueCard = styled(Card)<CardProps>(({color, theme}) => ({
	padding: theme.spacing(1),
	...(color && color === "primary" && {
		backgroundColor: MagOpsColors.primary.duskBlue
	}),
	...(color && color === "warning" && {
		backgroundColor: alpha(MagOpsColors.secondary.sunsetOrange, 0.2)
	}),
	...(color && color === "success" && {
		backgroundColor: alpha(MagOpsColors.secondary.forestGreen, 0.2)
	}),
	...(color && color === "error" && {
		backgroundColor: alpha(MagOpsColors.secondary.coralRed, 0.25)
	}),
}));

export const FormValueTextField = styled(TextField)<TextFieldProps>(({error = false, theme}) => ({
	"& .MuiInputBase-input ": {
		textAlign: "center",
		fontWeight: theme.typography.h2.fontWeight,
		color: theme.typography.h1.color,
		fontSize: theme.typography.h2.fontSize,
		...(error && {
			color: theme.palette.error.main,
		})
	},
	"& .MuiFormHelperText-root": {
		textAlign: "center",
		fontSize: "1rem",
		fontStyle: "italic",
		...(error && {
			color: theme.palette.error.main,
		})
	}
}));

export const DateValueTextField = styled(TextField)<TextFieldProps>(({error = false, theme}) => ({
	"& .MuiInputBase-input ": {
		...(error && {
			color: theme.palette.error.main,
		})
	},
}));
