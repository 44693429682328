import AxiosRequest from "./AxiosRequest"
import IGrowRoom from "../types/GrowRoom";
import AbstractMagOpsApi from "./AbstractMagOpsApi";

class GrowRoomApi extends AbstractMagOpsApi {
	getAll(siteId: number) {
		return AxiosRequest.get<IGrowRoom[]>("/grow_rooms", {
			params: {
				"siteId": siteId
			},
			signal: this.getRequestSignal()
		})
	}

	get(id: number) {
		return AxiosRequest.get<IGrowRoom>(`/grow_room/${id}`, {
			signal: this.getRequestSignal()
		})
	}

	create(data: { siteId: number, name: string }) {
		return AxiosRequest.post<IGrowRoom>("/grow_room", data)
	}

	delete(id: number) {
		return AxiosRequest.delete<{}>(`/grow_room/${id}`)
	}
}

export default new GrowRoomApi()