import {Chip, Divider, Grid} from "@mui/material";
import React from "react";
import {formatRelative} from "date-fns";
import {enAU} from "date-fns/locale";

type Props = {
	date: Date;
}

type formatLocaleIndex = "lastWeek" | "yesterday" | "today" | "tomorrow" | "nextWeek" | "other";

const formatRelativeLocale = {
	lastWeek: "'Last' eeee",
	yesterday: "'Yesterday'",
	today: "'Today'",
	tomorrow: "'Tomorrow'",
	nextWeek: "'Next' eeee",
	other: 'LLLL do, yyyy',
};

const locale = {
	...enAU,
	formatRelative: (token: formatLocaleIndex) => formatRelativeLocale[token],
};

const DateDivider = ({date}: Props) => (
	<Grid item xs={12}>
		<Divider light variant="middle">
			<Chip
				label={formatRelative(date, new Date(), {locale})}
			/>
		</Divider>
	</Grid>
)

export default DateDivider;