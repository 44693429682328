import React, {useEffect, useState} from "react";
import {useSnackbar} from "notistack";
import {Link, useHistory, useParams} from "react-router-dom";
import Form from "../../components/forms/Form";
import IMib from "../../shared/types/Mib";
import MibApi from "../../shared/api/MibApi";
import {GridColDef, GridRowParams} from "@mui/x-data-grid";
import {Box, Button, Grid, Hidden, Typography} from "@mui/material";
import {DataGridStyled} from "../../components/layout/DataGridStyles";
import FormSection from "../../components/forms/FormSection";
import MibServiceApi from "../../shared/api/MibServiceApi";
import IMibService from "../../shared/types/MibService";
import Heading from "../../components/forms/Heading";
import {format, subDays} from "date-fns";
import {Add, CheckCircle, Error} from "@mui/icons-material";
import {DateRange} from "@mui/lab/DateRangePicker/RangeTypes";
import SingleDateAreaChart from "../../components/dashboard/SingleDateAreaChart";
import axios from "axios";
import MibListActions from "../../components/forms/MibListActions";
import MibDialog from "../../components/forms/MibDialog";

const columns: GridColDef[] = [
	{
		field: "startDate",
		headerName: "Start Date",
		flex: 2,
	},
	{
		field: "endDate",
		headerName: "End Date",
		flex: 2
	},
	{
		field: "trays",
		headerName: "Trays",
		flex: 1
	},
	{
		field: "status",
		headerName: "Status",
		flex: 2,
		renderCell: (cellValues) => {
			return (
				<React.Fragment>
					<Box pr={1}>
						{
							cellValues.value === "Completed"
								?
								<CheckCircle color="success"/>
								:
								<Error color="primary"/>
						}
					</Box>
					<Typography>
						{cellValues.value}
					</Typography>
				</React.Fragment>
			)
		}
	},
	{
		field: "service",
		headerName: "Actions",
		flex: 2,
		renderCell: (cellValues => typeof cellValues.value === "number" ? MibListActions({id: cellValues.value}) :
			<Box/>)
	}
];

const mobileColumns: GridColDef[] = [
	{
		field: "startDate",
		headerName: "Start",
		flex: 1,
	},
	{
		field: "status",
		headerName: "Status",
		flex: 1,
		renderCell: (cellValues) => {
			return (
				<React.Fragment>
					<Box pr={1}>
						{
							cellValues.value === "Completed"
								?
								<CheckCircle color="success"/>
								:
								<Error color="primary"/>
						}
					</Box>
					<Typography>
						{cellValues.value}
					</Typography>
				</React.Fragment>
			)
		}
	},
];

type DisplayMibService = {
	id: number;
	service: number | null;
	startDate: string;
	endDate: string;
	trays: number;
	status: "Completed" | "In Progress";
}

interface MibSummaryParams {
	mibId?: string;
}

const MibSummary = () => {
	const [mib, setMib] = useState<IMib>();
	const [services, setServices] = useState<DisplayMibService[]>();
	const [isLoading, setIsLoading] = useState(true);
	const [mibServiceId, setMibServiceId] = useState<number>();
	const [dateRange, setDateRange] = React.useState<DateRange<Date>>(
		[subDays(new Date(), 7), new Date()]
	);
	const {enqueueSnackbar} = useSnackbar();

	let history = useHistory();
	let {mibId} = useParams<MibSummaryParams>()

	useEffect(() => {
		(async () => {
			try {
				setIsLoading(true);
				if (mibId !== undefined && !isNaN(parseInt(mibId)) && dateRange[0] && dateRange[1]) {
					const id = parseInt(mibId);
					let response = await MibApi.get(id, dateRange[0], dateRange[1]);
					setMib(response.data);
				}
			} catch (error) {
				if (axios.isCancel(error)) {
					return;
				}
				// Something happened in setting up the request that triggered an Error
				enqueueSnackbar(`Failed to find mib with id ${mibId}`, {variant: "error"})
				history.push("/not-found")
				console.log('Error', error.message);
			}
		})()

		return () => MibApi.abort();
	}, [mibId, enqueueSnackbar, history, dateRange])

	useEffect(() => {
		if (mib && services) {
			setIsLoading(false);
		}
	}, [mib, services])

	useEffect(() => {
		(async () => {
			setIsLoading(true);
			try {
				if (mib) {
					let response = await MibServiceApi.getAll(mib.id);
					const servicesIn = response.data.map((service: IMibService): DisplayMibService => ({
						id: service.id,
						service: service.completed ? null : service.id,
						startDate: format(new Date(service.startDate), "dd/MM/yyyy"),
						endDate: service.endDate !== undefined
							? format(new Date(service.endDate), "dd/MM/yyyy")
							: `${format(new Date(service.proposedEndDate), "dd/MM/yyyy")} (Planned)`,
						trays: service.trays,
						status: service.completed ? "Completed" : "In Progress"
					}))
					setServices(servicesIn);
				}
			} catch (error) {
				if (axios.isCancel(error)) {
					return;
				}
				// Something happened in setting up the request that triggered an Error
				enqueueSnackbar(`Failed to find mib services for this MIB`, {variant: "error"})
				console.log('Error', error.message);
				setIsLoading(false);
			}
		})()
	}, [mib, enqueueSnackbar, history])

	const onRowClick = (params: GridRowParams) => {
		if (typeof params.id === "number") {
			if (params.getValue(params.id, "status") !== "Completed") {
				setMibServiceId(params.id);
			}
		}
	}

	return (
		<Form
			heading="MIB Overview"
			subheading={`MIB ${mib?.name}`}
			loading={isLoading}
			dateRangeProps={{
				dateRange: dateRange,
				maxDate: new Date(),
				setDateRange: setDateRange
			}}
		>
			{mib?.feed &&
				<React.Fragment>
					<SingleDateAreaChart
						charts={[
							{key: 0, name: "Total Feed for Trays (Kg)", color: "mauve"}
						]}
						data={mib.feed.map(value => ({
							date: format(new Date(value.time), "dd/MM/yyyy"),
							// 0: value.feedKg !== null ? +formatFloats(value.feedKg) : null,
						}))}
						heading="Waste Fed to MIB"
						loading={isLoading}
						units="Kg"
					/>
				</React.Fragment>
			}
			{services && mib &&
				<MibDialog
					close={() => setMibServiceId(undefined)}
					id={mibServiceId}
				/>
			}
			{services && mib &&
				<FormSection>
					<Grid container justifyContent="space-between" pb={3}>
						<Grid item xs={12} md={6}>
							<Heading subheading="Services"/>
						</Grid>
						<Grid item xs={12} md={6}>
							<Box width="100%" display="flex" justifyContent="end">
								<Link to={`/mibs/${mib.id}/service`}>
									<Button
										size="large"
										color="primary"
										variant="contained"
										startIcon={<Add/>}
									>
										New Service
									</Button>
								</Link>
							</Box>
						</Grid>
					</Grid>
					<Box height={424}>
						<Hidden mdDown>
							<DataGridStyled
								columns={columns}
								rows={services}
								loading={isLoading}
							/>
						</Hidden>
						<Hidden mdUp>
							<DataGridStyled
								columns={mobileColumns}
								rows={services}
								loading={isLoading}
								onRowClick={onRowClick}
							/>
						</Hidden>
					</Box>
				</FormSection>}
		</Form>
	)
}

export default MibSummary;