import Heading from "../forms/Heading";
import {
	Area,
	AreaChart,
	CartesianGrid,
	Label,
	Legend,
	ReferenceLine,
	ResponsiveContainer,
	Tooltip,
	TooltipProps,
	XAxis,
	YAxis
} from "recharts";
import FormSection from "../forms/FormSection";
import React from "react";
import {MagOpsColors} from "../../shared/styles/MagOpsStyle";
import {Card, Skeleton} from "@mui/material";
import {NameType, ValueType} from "recharts/types/component/DefaultTooltipContent";

type Data = {
	date: string;
	[key: number]: number | null;
}

type ChartArea = {
	key: number;
	name: string;
	color: "red" | "green" | "mauve" | "yellow" | "orange" | "blue";
}

type Target = {
	name: string;
	value: number;
}

type Props = {
	charts: ChartArea[]
	data: Data[]
	heading: string;
	units?: string;
	loading: boolean;
	target?: Target;
	children?: React.ReactNode;
}

const colorMap = {
	green: MagOpsColors.secondary.forestGreen,
	mauve: MagOpsColors.secondary.marvelousMauve,
	red: MagOpsColors.secondary.coralRed,
	yellow: MagOpsColors.secondary.lemonYellow,
	orange: MagOpsColors.secondary.sunsetOrange,
	blue: MagOpsColors.primary.periwinkleBlue
}

export const CustomTooltip = ({active, payload, label}: TooltipProps<ValueType, NameType>) => {
	if (active && payload && payload.length) {
		return (
			// <div className="custom-tooltip">
			// 	<p className="label">{`${label} : ${payload[0].value}`}</p>
			// 	<div>
			// 		{payload.map((pld) => (
			// 			<div style={{display: "inline-block", padding: 10}}>
			// 				<div style={{color: pld.fill}}>{pld.value}</div>
			// 				<div>{pld.dataKey}</div>
			// 			</div>
			// 		))}
			// 	</div>
			// </div>
			<Card

			>

			</Card>
		);
	}

	return null;
};

const SingleDateAreaChart = ({charts, heading, units, data, loading, target, children}: Props) => {
	return (
		<FormSection>
			<Heading subheading={heading}/>
			{loading
				?
				<Skeleton width="100%" height="100%"/>
				:
				<ResponsiveContainer width="100%" height={500} key={Math.random()}>
					<AreaChart data={data}>
						<defs>
							<linearGradient id="mauve" x1="0" y1="0" x2="0" y2="1">
								<stop offset="0%" stopColor={MagOpsColors.secondary.marvelousMauve} stopOpacity={0.6}/>
								<stop offset="95%" stopColor={MagOpsColors.secondary.marvelousMauve} stopOpacity={0.1}/>
							</linearGradient>
							<linearGradient id="green" x1="0" y1="0" x2="0" y2="1">
								<stop offset="0%" stopColor={MagOpsColors.secondary.forestGreen} stopOpacity={0.6}/>
								<stop offset="95%" stopColor={MagOpsColors.secondary.forestGreen} stopOpacity={0.1}/>
							</linearGradient>
							<linearGradient id="red" x1="0" y1="0" x2="0" y2="1">
								<stop offset="0%" stopColor={MagOpsColors.secondary.coralRed} stopOpacity={0.6}/>
								<stop offset="95%" stopColor={MagOpsColors.secondary.coralRed} stopOpacity={0.1}/>
							</linearGradient>
							<linearGradient id="yellow" x1="0" y1="0" x2="0" y2="1">
								<stop offset="0%" stopColor={MagOpsColors.secondary.lemonYellow} stopOpacity={0.6}/>
								<stop offset="95%" stopColor={MagOpsColors.secondary.lemonYellow} stopOpacity={0.1}/>
							</linearGradient>
							<linearGradient id="orange" x1="0" y1="0" x2="0" y2="1">
								<stop offset="0%" stopColor={MagOpsColors.secondary.sunsetOrange} stopOpacity={0.6}/>
								<stop offset="95%" stopColor={MagOpsColors.secondary.sunsetOrange} stopOpacity={0.1}/>
							</linearGradient>
							<linearGradient id="blue" x1="0" y1="0" x2="0" y2="1">
								<stop offset="0%" stopColor={MagOpsColors.primary.periwinkleBlue} stopOpacity={0.6}/>
								<stop offset="95%" stopColor={MagOpsColors.primary.periwinkleBlue} stopOpacity={0.1}/>
							</linearGradient>
						</defs>
						<CartesianGrid/>
						<XAxis dataKey="date"/>
						<YAxis unit={units}/>
						<Tooltip/>
						<Legend iconType="circle"/>
						{charts.map((chart) => (
							<Area
								connectNulls
								type="monotone"
								dataKey={chart.key}
								stroke={colorMap[chart.color]}
								fillOpacity={1}
								fill={`url(#${chart.color})`}
								legendType="circle"
								name={chart.name}
								strokeWidth={3}
								dot
								unit={units}
							/>
						))}
						{target &&
							<ReferenceLine
								stroke={MagOpsColors.secondary.coralRed}
								strokeWidth={2}
								y={target.value}
							>
								<Label position="top">{target.name}</Label>
							</ReferenceLine>
						}
					</AreaChart>
				</ResponsiveContainer>
			}
			{children}
		</FormSection>
	)
}

export default SingleDateAreaChart;