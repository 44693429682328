import {Accordion, Button, Fade, Grid, InputAdornment, Skeleton, TextField, Typography} from "@mui/material";
import React from "react";
import {CheckCircle, ExpandMore, GroupWork} from "@mui/icons-material";
import {onChangeNumber} from "../../shared/helpers";
import {StyledAccordionDetails, StyledAccordionSummary} from "./FormStyles";

type Props = {
	id: number;
	submit: (id: number, amount: number) => void;
	valueIn: number | null;
	name: string;
	measurement: string;
	units: string;
	loading?: boolean;
	setAviaryValue: (aviaryId: number, amount: number) => void
};

const Aviary = ({id, submit, valueIn, name, measurement, units, setAviaryValue, loading = false}: Props) => {
	const [expanded, setExpanded] = React.useState<string | false>('panel');
	const [value, setValue] = React.useState<number>(valueIn !== null ? valueIn : 0);
	const [isValid, setIsValid] = React.useState(true)

	const handleChange = (panel: string) => (event: React.ChangeEvent<{}>, isExpanded: boolean) => {
		setExpanded(isExpanded ? panel : false);
	};

	return (
		<Grid item xs={12} sm={6} md={4} lg={4}>
			{loading ?
				<Skeleton height={64}/>
				:
				<Accordion
					expanded={expanded === 'panel'}
					onChange={handleChange('panel')}
					variant="elevation"
				>
					<StyledAccordionSummary
						active={valueIn !== null}
						expandIcon={<ExpandMore/>}
					>
						{
							valueIn !== null ? <CheckCircle color="success" sx={{flexBasis: "15%"}}/> :
								<GroupWork color="primary" sx={{flexBasis: "15%"}}/>
						}
						<Typography variant="h3" sx={{flexBasis: "50%"}}>{name}</Typography>
						<Fade in={!expanded}>
							<Typography variant="h4" sx={{marginLeft: "auto", marginRight: "auto"}}>
								{valueIn === null ? "" : valueIn + units}
							</Typography>
						</Fade>
					</StyledAccordionSummary>
					<StyledAccordionDetails>
						<TextField
							error={!isValid}
							defaultValue={valueIn !== null ? valueIn : 0}
							disabled={valueIn !== null}
							label={measurement}
							variant="standard"
							onChange={event => onChangeNumber(event.target.value, amount => {
								setValue(amount);
								setAviaryValue(id, amount);
							}, setIsValid)}
							InputProps={{
								endAdornment: <InputAdornment position="start">{units}</InputAdornment>,
							}}
							sx={{
								flexBasis: "50%"
							}}
						/>
						<Button
							disabled={!isValid || valueIn !== null}
							color="primary"
							variant="contained"
							onClick={() => submit(id, value)}
							sx={{
								marginLeft: "auto"
							}}
						>
							Submit
						</Button>
					</StyledAccordionDetails>
				</Accordion>
			}
		</Grid>
	);
}

export default Aviary;