import Heading from "../forms/Heading";
import {Skeleton, Step, StepLabel, Stepper, Theme, Typography, useMediaQuery} from "@mui/material";
import {addDays, format} from "date-fns";
import {CheckCircle, Error} from "@mui/icons-material";
import FormSection from "../forms/FormSection";
import React, {useContext, useEffect, useState} from "react";
import IBatch from "../../shared/types/Batch";
import {ConfigContext} from "../../shared/providers/ConfigProvider";
import {TaskStatus} from "../../shared/types/ProductionTask";

type ProgressItem = {
	days: number;
	status?: TaskStatus
	title: string;
}

type Props = {
	batch: IBatch;
	date: Date;
}

const msInDay = 24 * 60 * 60 * 1000;

const getDayDiff = (startDate: Date, endDate: Date): number => {
	return Math.round(Math.abs(endDate.getTime() - startDate.getTime()) / msInDay);
}

const BatchProgress = ({batch, date}: Props) => {
	const [progressItems, setProgressItems] = useState<ProgressItem[]>([]);
	const [configState] = useContext(ConfigContext);
	const [step,] = useState(0);
	const [isLoading, setIsLoading] = useState(true);
	const smUp = useMediaQuery((theme: Theme) => theme.breakpoints.up("sm"));

	useEffect(() => {
		if (batch?.tasks) {
			const items = batch.tasks.map((task): ProgressItem => {
				switch (task.type) {
					case "Eggs":
						return {
							days: getDayDiff(new Date(batch.time), new Date(task.timeDue)),
							status: task.dependsOn ? "Blocked" : task.status,
							title: "Egg Collection"
						}
					case "HatchingTrays":
						return {
							days: getDayDiff(new Date(batch.time), new Date(task.timeDue)),
							status: task.dependsOn ? "Blocked" : task.status,
							title: "Create Hatching Trays"
						}
					case "Transfer":
						return {
							days: getDayDiff(new Date(batch.time), new Date(task.timeDue)),
							status: task.dependsOn ? "Blocked" : task.status,
							title: "Transfer"
						}
					case "GrowRoomFeed":
						return {
							days: getDayDiff(new Date(batch.time), new Date(task.timeDue)),
							status: task.dependsOn ? "Blocked" : task.status,
							title: "Manufacture Trays"
						}
					case "Sifting":
						return {
							days: getDayDiff(new Date(batch.time), new Date(task.timeDue)),
							status: task.dependsOn ? "Blocked" : task.status,
							title: "Sifting"
						}
					case "PupaeIntroPrep":
						return {
							days: getDayDiff(new Date(batch.time), new Date(task.timeDue)),
							status: task.dependsOn ? "Blocked" : task.status,
							title: "Prepare Pupae for Aviaries"
						}
					case "PupaeIntro":
						return {
							days: getDayDiff(new Date(batch.time), new Date(task.timeDue)),
							status: task.dependsOn ? "Blocked" : task.status,
							title: "Pupae Intro"
						}
					default:
						return {
							days: getDayDiff(new Date(batch.time), new Date(task.timeDue)),
							status: task.dependsOn ? "Blocked" : task.status,
							title: "Take Sample"
						}
				}
			});
			items.sort((p1, p2) => p1.days - p2.days)
			setProgressItems(items);
		}
	}, [batch, configState.config])

	useEffect(() => {
		if (progressItems && progressItems.length > 0) {
			setIsLoading(false);
		}
	}, [progressItems])

	return (
		<FormSection>
			<Heading subheading="Progress"/>
			{
				isLoading
					?
					<Skeleton height={80}/>
					:
					<Stepper alternativeLabel={smUp} activeStep={step} orientation={smUp ? "horizontal" : "vertical"}>
						{progressItems.map(progress => {
							const progressDate = addDays(date, progress.days - 1);

							return (
								<Step>
									<StepLabel
										StepIconProps={{
											icon: progress.status === "Blocked" ? <Error color="info" fontSize="large"/>
												: progress.status === "Overdue" ? <Error color="error" fontSize="large"/>
													: progress.status === "Done" ? <CheckCircle color="success" fontSize="large"/>

														: <Error color="primary" fontSize="large"/>
										}}
									>
										<Typography variant="body1">
											{format(progressDate, "dd/MM/yyyy")}
										</Typography>
										<Typography variant="body2">
											{`${progress.title}: Day ${progress.days}`}
										</Typography>
									</StepLabel>
								</Step>
							)
						})}
					</Stepper>
			}
		</FormSection>
	)
}

export default BatchProgress;