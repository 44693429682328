import AxiosRequest from "./AxiosRequest"
import IGrowRoomFeed from "../types/GrowRoomFeed";
import AbstractMagOpsApi from "./AbstractMagOpsApi";

class GrowRoomFeedApi extends AbstractMagOpsApi {

	create(data: IGrowRoomFeed) {
		return AxiosRequest.post<IGrowRoomFeed>("/grow_room_feed", data)
	}
}

export default new GrowRoomFeedApi()