import React, {useContext, useEffect, useState} from "react";
import {ConfigContext} from "../../shared/providers/ConfigProvider";
import ConfigApi from "../../shared/api/ConfigApi";
import FormOutputs from "../../components/forms/FormOutputs";
import Form from "../../components/forms/Form";
import Config from "../../shared/types/Config";
import {FormValueCard, FormValueTextField} from "../../components/forms/FormStyles";
import {InputAdornment, Typography} from "@mui/material";
import {onChangeNumberOrArray} from "../../shared/helpers";
import {useSnackbar} from "notistack";
import {NavContext} from "../../shared/providers/NavProvider";
import ConfigHelperText from "../../shared/helpers/SettingsHelper";

// Backwards logic so we fix things better
type ConfigInvalid = Record<string, boolean>

const ConfigForm = () => {

	const [configState, configDispatch] = useContext(ConfigContext);
	const [newConfig, setNewConfig] = useState<Config>();
	const [configInvalid, setConfigInvalid] = useState<ConfigInvalid>({});
	const [isLoading, setIsLoading] = useState(configState.config === undefined);
	const [navState,] = useContext(NavContext);

	const {enqueueSnackbar} = useSnackbar();

	useEffect(() => {
		if (configState.config) {
			setNewConfig(configState.config);
			setIsLoading(false);
		}
	}, [setNewConfig, configState.config])

	const submit = async () => {
		if (newConfig) {
			try {
				setIsLoading(true)
				await ConfigApi.create({
					siteId: navState.sites[navState.siteIndex].id,
					...newConfig
				});
				enqueueSnackbar("Successfully updated production config", {variant: "success"})
			} catch (e) {
				enqueueSnackbar("Failed to update production config", {variant: "error"})
				setIsLoading(false)
				console.log('Error', e.message);
			}
		}
	}

	const handleFormPaging = async (back = false) => {
		if (!back) {
			try {
				await submit();
				let response = await ConfigApi.get(navState.sites[navState.siteIndex].id);
				configDispatch({
					type: "SET_CONFIG",
					data: response.data
				});
			} catch (e) {
				console.log(e);
			}
		}
	}

	return (
		<Form
			heading="Settings"
			subheading="Production Config"
			loading={isLoading}
			handleFormPaging={handleFormPaging}
			nextDisabled={
				configInvalid
				&& Object.values(configInvalid).length > 0
				&& Object.values(configInvalid).reduce((value, acc) => value && acc)
			}
		>
			<FormOutputs>
				{
					newConfig && Object.entries(newConfig).map(([key, value]) => {
						return (
							<FormValueCard variant="outlined" sx={{flexGrow: 1}}>
								<Typography component="p" variant="h6" align="center">
									{ConfigHelperText[key] && ConfigHelperText[key].name}
								</Typography>
								<FormValueTextField
									error={configInvalid[key]}
									fullWidth
									variant="standard"
									defaultValue={value}
									helperText={ConfigHelperText[key] && ConfigHelperText[key].description}
									onChange={event => onChangeNumberOrArray(
										event.target.value,
										val => {
											setNewConfig({
												...newConfig,
												[key]: val
											})
										},
										valid => {
											setConfigInvalid({
												...configInvalid,
												[key]: !valid
											})
										},
									)}
									InputProps={{
										...(ConfigHelperText[key] && ConfigHelperText[key].unit && {
											endAdornment: <InputAdornment position="start" disableTypography>
												<Typography component="p" variant="h6">{ConfigHelperText[key].unit}</Typography>
											</InputAdornment>
										})
									}}
								/>
							</FormValueCard>
						)
					})
				}
			</FormOutputs>
		</Form>
	)
}

export default ConfigForm;