import React, {useContext, useEffect, useState} from "react";
import {useHistory, useParams} from "react-router-dom";
import Form from "../../../components/forms/Form";
import ValueInputCard from "../../../components/forms/ValueInputCard";
import FormOutputs from "../../../components/forms/FormOutputs";
import {useSnackbar} from "notistack";
import {NavContext} from "../../../shared/providers/NavProvider";
import {TodoContext} from "../../../shared/providers/TodoProvider";
import {refreshTodo} from "../../../shared/helpers";
import MibApi from "../../../shared/api/MibApi";
import IMib from "../../../shared/types/Mib";
import MibServiceApi from "../../../shared/api/MibServiceApi";
import IBatchTrays from "../../../shared/types/BatchTrays";
import TraysApi from "../../../shared/api/TraysApi";
import {Autocomplete, Avatar, Box, Chip, Typography} from "@mui/material";
import {FormValueCard, FormValueTextField} from "../../../components/forms/FormStyles";
import axios from "axios";

interface MibServiceParams {
	mibId?: string;
}

const MibServiceForm = () => {
	const [navState] = useContext(NavContext);
	const [mib, setMib] = useState<IMib>();
	const [traysAvailable, setTraysAvailable] = useState<IBatchTrays[]>();
	const [selectedTrays, setSelectedTrays] = useState<IBatchTrays[]>([]);
	const [isLoading, setIsLoading] = useState(true)
	const [larvaeWeight, setLarvaeWeight] = useState<number>(0);
	const [larvaeWeightValid, setLarvaeWeightValid] = useState<boolean>(false);
	const [trays, setTrays] = useState<number>(0);
	const [traysValid, setTraysValid] = useState<boolean>(false);
	const [totalTraysAvailable, setTotalTraysAvailable] = useState<number>(0);
	const [date, setDate] = useState<Date | null>(new Date());
	const [, todoDispatch] = useContext(TodoContext);
	const {enqueueSnackbar} = useSnackbar();

	let history = useHistory();
	let {mibId} = useParams<MibServiceParams>()

	useEffect(() => {
		(async () => {
			try {
				setIsLoading(true);
				if (mibId !== undefined && !isNaN(parseInt(mibId))) {
					let response = await MibApi.get(parseInt(mibId));
					setMib(response.data);
				}
			} catch (error) {
				if (axios.isCancel(error)) {
					return;
				}
				// Something happened in setting up the request that triggered an Error
				enqueueSnackbar(`Failed to load mib with id ${mibId}`, {variant: "error"})
				console.log('Error', error.message);
			}
		})()

		return () => MibApi.abort();
	}, [mibId, enqueueSnackbar, history])

	useEffect(() => {
		(async () => {
			try {
				setIsLoading(true);
				let response = await TraysApi.getFeeders();
				setTraysAvailable(response.data);
			} catch (error) {
				if (axios.isCancel(error)) {
					return;
				}
				// Something happened in setting up the request that triggered an Error
				enqueueSnackbar(`Failed to load trays for mib ${mib?.id}`, {variant: "error"})
				console.log('Error', error.message);
			}
		})()

		return () => TraysApi.abort();
	}, [mibId, enqueueSnackbar, mib?.id])

	useEffect(() => {
		if (mib && traysAvailable) {
			setIsLoading(false);
		}
	}, [mib, traysAvailable])

	const submit = async () => {
		if (mib && date !== null) {
			try {
				setIsLoading(true)

				// Get the new trays to send down
				let newTrays = 0;
				const newBatchTrays = selectedTrays.map<IBatchTrays>(value => {
					if (newTrays + value.trays > trays) {
						// We have added all the trays we need, we can stop here
						const addedTrays = trays - newTrays;
						newTrays = trays;
						return {
							batchId: value.batchId,
							trays: addedTrays
						}
					} else {
						newTrays += value.trays;
						// Copy the current value
						return value;
					}
				});

				await MibServiceApi.create({
					date: date,
					larvae_g: larvaeWeight,
					mib_id: mib.id,
					trays: newBatchTrays
				})
				enqueueSnackbar("Successfully submitted mib service", {variant: "success"})
				history.push(`/mibs/${mib.id}`)
			} catch (e) {
				enqueueSnackbar("Failed to submit mib service", {variant: "error"});
				setIsLoading(false);
				console.log('Error', e.message);
			}
		}
	}

	const handleFormPaging = async (back = false) => {
		if (!back) {
			await submit()
			await refreshTodo(navState, todoDispatch)
		}
	}

	return (
		<Form
			heading="Service MIB"
			subheading={`MIB ${mib?.name}`}
			loading={isLoading}
			handleFormPaging={handleFormPaging}
			nextDisabled={!larvaeWeightValid || !traysValid}
			dateProps={{
				date: date,
				setDate: setDate,
			}}
		>
			<FormOutputs>
				{traysAvailable &&
					<FormValueCard variant="outlined" sx={{flexGrow: 1}}>
						<Typography component="p" variant="h6" align="center">
							Select Batches
						</Typography>
						<Autocomplete
							multiple
							options={traysAvailable}
							onChange={(event, options) => {
								setSelectedTrays(options);
								setTotalTraysAvailable(options.map(option => option.trays).reduce((val, prev) => val + prev))
							}}
							getOptionLabel={(option) => `Batch ${option.batchId.toString().padStart(4, "0")}`}
							filterSelectedOptions
							renderOption={(props, option) => (
								<Box component="li" {...props}>
									Batch {option.batchId.toString().padStart(4, "0")}: {option.trays} Trays
								</Box>
							)}
							renderInput={(params) => (
								<FormValueTextField
									{...params}
									variant="standard"
								/>
							)}
							renderTags={(value: readonly IBatchTrays[], getTagProps) =>
								value.map((option: IBatchTrays, index: number) => (
									<Chip
										avatar={<Avatar>{option.trays}</Avatar>}
										color="primary"
										label={`Batch ${option.batchId}`}
										{...getTagProps({index})}
									/>
								))
							}
						/>
					</FormValueCard>
				}
				<ValueInputCard
					heading="Larvae Introduced"
					valid={larvaeWeightValid}
					setValue={value => setLarvaeWeight(value * 1000)}
					setValid={setLarvaeWeightValid}
					units="Kg"
				/>
				<ValueInputCard
					heading="Trays Introduced"
					valid={traysValid}
					setValue={setTrays}
					setValid={setTraysValid}
					checkValid={value => value <= totalTraysAvailable}
					units={`/${totalTraysAvailable}`}
				/>
			</FormOutputs>
		</Form>
	)
}

export default MibServiceForm;