import React, {useContext, useState} from "react";
import {useHistory, useParams} from "react-router-dom";
import Form from "../../../components/forms/Form";
import ValueInputCard from "../../../components/forms/ValueInputCard";
import FormOutputs from "../../../components/forms/FormOutputs";
import {useSnackbar} from "notistack";
import IBatch from "../../../shared/types/Batch";
import {NavContext} from "../../../shared/providers/NavProvider";
import {TodoContext} from "../../../shared/providers/TodoProvider";
import {refreshTodo} from "../../../shared/helpers";
import useBatch from "../../../shared/helpers/useBatch";


interface SampleParams {
	taskId?: string;
}

type Props = {
	successMessage: string;
	// Doesn't matter what create returns, it just does need to return
	create: (batchId: number, value: number, taskIdNum: number, date?: Date) => any;
	heading: string;
	inputHeading: string;
	inputUnits: string;
	inputConversion?: number;
}

const GrowRoomForm = ({successMessage, create, heading, inputHeading, inputConversion = 1, inputUnits}: Props) => {
	const [navState] = useContext(NavContext)
	const [, todoDispatch] = useContext(TodoContext)
	const [batch, setBatch] = useState<IBatch>();
	const [isLoading, setIsLoading] = useState(true)
	const [value, setValue] = useState<number>(0);
	const [valueValid, setValueValid] = useState<boolean>(false);
	const [date, setDate] = useState<Date>(new Date());
	const {enqueueSnackbar} = useSnackbar();

	let history = useHistory();
	let {taskId} = useParams<SampleParams>()

	useBatch({
		taskIdStr: taskId,
		setBatch,
		setDate,
		setIsLoading
	})

	const submit = async () => {
		if (batch && taskId) {
			const taskIdNum = parseInt(taskId);
			if (isNaN(taskIdNum)) {
				return
			}
			try {
				setIsLoading(true);
				date ? await create(batch.id, value, taskIdNum, date) : await create(batch.id, value, taskIdNum);
				enqueueSnackbar(successMessage, {variant: "success"});
				history.push("/");
			} catch (e) {
				enqueueSnackbar(`Failed to submit ${heading} data`, {variant: "error"});
				setIsLoading(false);
				console.log('Error', e.message);
			}
		}
	}

	const handleFormPaging = async (back = false) => {
		if (!back) {
			await submit()
			await refreshTodo(navState, todoDispatch)
		}
	}

	return (
		<Form
			heading={heading}
			subheading={`Batch ${batch?.localId.toString().padStart(4, "0")}`}
			loading={isLoading}
			handleFormPaging={handleFormPaging}
			nextDisabled={!valueValid || value === 0}
			dateProps={{
				date: date,
				setDate: setDate,
				readonly: true,
			}}
		>
			<FormOutputs>
				<ValueInputCard
					heading={inputHeading}
					valid={valueValid}
					setValue={value => setValue(value * inputConversion)}
					setValid={setValueValid}
					units={inputUnits}
				/>
			</FormOutputs>
		</Form>
	)
}

export default GrowRoomForm;