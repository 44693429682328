import {Grid} from "@mui/material";
import React from "react";
import FormSection from "./FormSection";
import Heading from "./Heading";

type Props = {
	children?: React.ReactNode | React.ReactNode[];
	show?: boolean;
	header?: string;
	subheader?: string;
	half?: boolean;
}

const FormOutputs = ({children, show, header, subheader, half}: Props) => {
	return (
		<FormSection show={show} half={half}>
			<Grid container xs={12} spacing={3} justifyContent="center" alignContent="center">
				{header &&
					<Grid item xs={12}>
						<Heading subheading={header} smallSubHeading={subheader}/>
					</Grid>
				}
				{children && Array.isArray(children) ?
					children.map((child, index) => {
						if (!child) {
							return null;
						}
						return (
							<Grid key={index} item xs={12} sm={4} md={half ? 12 : 4} lg={half ? 12 : 3} sx={{display: "flex"}}>
								{child}
							</Grid>
						)
					}) :
					<Grid item xs={12} sm={4} md={half ? 12 : 4} lg={half ? 12 : 3} sx={{display: "flex"}}>
						{children}
					</Grid>
				}
			</Grid>
		</FormSection>

	)
}

export default FormOutputs;