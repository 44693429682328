import React, {useContext, useEffect, useState} from "react";
import {useHistory, useParams} from "react-router-dom";
import Form from "../../../components/forms/Form";
import ValueInputCard from "../../../components/forms/ValueInputCard";
import FormOutputs from "../../../components/forms/FormOutputs";
import {useSnackbar} from "notistack";
import {NavContext} from "../../../shared/providers/NavProvider";
import {TodoContext} from "../../../shared/providers/TodoProvider";
import {refreshTodo} from "../../../shared/helpers";
import MibHarvestApi from "../../../shared/api/MibHarvestApi";
import MibServiceApi from "../../../shared/api/MibServiceApi";
import IMibService from "../../../shared/types/MibService";
import {format} from "date-fns";
import axios from "axios";

interface MibHarvestParams {
	mibServiceId?: string;
}

const MibHarvestForm = () => {
	const [navState] = useContext(NavContext);
	const [mibService, setMibService] = useState<IMibService>();
	const [isLoading, setIsLoading] = useState(true)
	const [larvaeWeight, setLarvaeWeight] = useState<number>(0);
	const [larvaeWeightValid, setLarvaeWeightValid] = useState<boolean>(false);
	const [frass, setFrass] = useState<number>(0);
	const [frassValid, setFrassValid] = useState<boolean>(false);
	const [contamination, setContamination] = useState<number>(0);
	const [contaminationValid, setContaminationValid] = useState<boolean>(false);
	const [date, setDate] = useState<Date | null>(new Date());
	const [, todoDispatch] = useContext(TodoContext);
	const {enqueueSnackbar} = useSnackbar();

	let history = useHistory();
	let {mibServiceId} = useParams<MibHarvestParams>()

	useEffect(() => {
		(async () => {
			try {
				setIsLoading(true);
				if (mibServiceId !== undefined && !isNaN(parseInt(mibServiceId))) {
					let response = await MibServiceApi.get(parseInt(mibServiceId));
					setMibService(response.data);
				}
			} catch (error) {
				if (axios.isCancel(error)) {
					return;
				}
				// Something happened in setting up the request that triggered an Error
				enqueueSnackbar(`Failed to load mib service with id ${mibServiceId}`, {variant: "error"})
				console.log('Error', error.message);
			}
		})()

		return () => MibServiceApi.abort();
	}, [mibServiceId, enqueueSnackbar, history])

	useEffect(() => {
		if (mibService) {
			setIsLoading(false);
		}
	}, [mibService])

	const submit = async () => {
		if (mibService && date !== null && frassValid && larvaeWeightValid && contaminationValid) {
			try {
				setIsLoading(true)
				await MibHarvestApi.create({
					mibServiceId: mibService.id,
					date: date,
					larvaeG: larvaeWeight,
					frassG: frass,
					contaminationG: contamination,
				})
				enqueueSnackbar("Successfully submitted mib harvest", {variant: "success"})
				history.push(`/mibs/${mibService.mibId}`)
			} catch (e) {
				enqueueSnackbar("Failed to submit mib harvest", {variant: "error"});
				setIsLoading(false);
				console.log('Error', e.message);
			}
		}
	}

	const handleFormPaging = async (back = false) => {
		if (!back) {
			await submit()
			await refreshTodo(navState, todoDispatch)
		}
	}

	return (
		<Form
			heading="Harvest MIB"
			subheading={mibService && format(new Date(mibService?.startDate), "dd/MM/yyyy")}
			loading={isLoading}
			handleFormPaging={handleFormPaging}
			nextDisabled={!larvaeWeightValid || !contaminationValid}
			dateProps={{
				date: date,
				setDate: setDate,
			}}
		>
			<FormOutputs>
				<ValueInputCard
					heading="Larvae Weight"
					valid={larvaeWeightValid}
					setValue={value => setLarvaeWeight(value * 1000)}
					setValid={setLarvaeWeightValid}
					units="Kg"
				/>
				<ValueInputCard
					heading="Frass"
					valid={frassValid}
					setValue={value => setFrass(value * 1000)}
					setValid={setFrassValid}
					units="Kg"
				/>
				<ValueInputCard
					heading="Contamination"
					valid={contaminationValid}
					setValue={value => setContamination(value * 1000)}
					setValid={setContaminationValid}
					units="Kg"
				/>
			</FormOutputs>
		</Form>
	)
}

export default MibHarvestForm;