import {Grid} from "@mui/material";
import React, {useContext, useEffect, useState} from "react";
import Aviary from "../../../components/forms/Aviary";
import FormSection from "../../../components/forms/FormSection";
import IBatch from "../../../shared/types/Batch";
import IAviary from "../../../shared/types/Aviary";
import AviaryApi from "../../../shared/api/AviaryApi";
import IEggs from "../../../shared/types/Eggs";
import EggsApi from "../../../shared/api/EggsApi";
import IAviaryEggMap from "../../../shared/types/AviaryEggMap";
import {useHistory, useParams} from "react-router-dom";
import Form from "../../../components/forms/Form";
import {useSnackbar} from "notistack";
import {NavContext} from "../../../shared/providers/NavProvider";
import {TodoContext} from "../../../shared/providers/TodoProvider";
import {nowTime, refreshTodo} from "../../../shared/helpers";
import axios from "axios";
import useBatch from "../../../shared/helpers/useBatch";
import TaskApi from "../../../shared/api/TaskApi";

interface EggsParams {
	taskId?: string;
}

const EggsForm = () => {
	const [navState] = useContext(NavContext);
	const [batch, setBatch] = useState<IBatch>();
	const [aviaries, setAviaries] = useState<IAviary[]>();
	const [isLoading, setIsLoading] = useState(true);
	const [isLoadingEggs, setIsLoadingEggs] = useState(true);
	const [aviaryValues, setAviaryValues] = useState<Map<number, number>>(new Map())
	const [eggs, setEggs] = useState<IAviaryEggMap>();
	const [alertOpen, setAlertOpen] = useState(false);
	const [, todoDispatch] = useContext(TodoContext);
	const {enqueueSnackbar} = useSnackbar();
	const [date, setDate] = useState<Date | null>(new Date())

	let history = useHistory();
	let {taskId} = useParams<EggsParams>();

	const setAviaryEggsValue = (aviaryId: number, eggsG: number) => {
		setAviaryValues(aviaryValues.set(aviaryId, eggsG));
	}

	const getEggs = async (aviariesIn?: IAviary[], batchIn?: IBatch) => {
		try {
			if (aviariesIn && batchIn) {
				setIsLoadingEggs(true);
				// After getting aviaries & batch, populate the eggs
				let response = await EggsApi.get(batchIn.id);
				let responseEggs: IAviaryEggMap = {};
				response.data.forEach((egg: IEggs) => {
					responseEggs[egg.aviaryId] = egg;
				});
				setEggs(responseEggs)
			}
		} catch (error) {
			if (axios.isCancel(error)) {
				return;
			}
			if (error.response) {
				if (error.response.status === 404) {
					let responseEggs: IAviaryEggMap = {};
					setEggs(responseEggs)
				}
			} else {
				// Everything is impossible
				console.log('Error', error.message);
			}
		}
	}

	useEffect(() => {
		setIsLoadingEggs(false);
	}, [eggs])

	useBatch({
		taskIdStr: taskId,
		setBatch,
		setDate,
		setIsLoading,
	})

	useEffect(() => {
		// Get the number of aviaries
		(async () => {
			try {
				setIsLoading(true);
				if (navState.sites.length === 0) {
					// Still loading
					return;
				}
				let response = await AviaryApi.getAll(navState.sites[navState.siteIndex].id);
				setAviaries(response.data.sort((a: IAviary, b: IAviary) => a.id - b.id));
			} catch (e) {
				if (axios.isCancel(e)) {
					return;
				}
				console.log(e)
			}
		})();

		return () => {
			AviaryApi.abort();
		}
	}, [navState.siteIndex, navState.sites])

	useEffect(() => {
		(async () => await getEggs(aviaries, batch))();

		return () => {
			EggsApi.abort();
		}
	}, [aviaries, batch])

	useEffect(() => {
		if (batch && aviaries) {
			setIsLoading(false)
		}
	}, [batch, aviaries])

	const submitAviary = async (id: number, amount: number) => {
		if (batch) {
			try {
				// Amount is entered in grams, so we convert here to mg
				amount = amount * 1000;
				setIsLoadingEggs(true)
				await EggsApi.create({
					aviaryId: id,
					batchId: batch.id,
					eggsMg: amount,
					...(date && {time: nowTime(date)})
				});
				setAviaryValues(new Map());
				enqueueSnackbar("Successfully submitted egg measurement", {variant: "success"})
				// Force update all the eggs
				await getEggs(aviaries, batch)
			} catch (e) {
				if (axios.isCancel(e)) {
					return;
				}
				console.log('Error', e.message);
			}
		}
	}

	const submitBatch = async () => {
		if (batch && eggs && Object.keys(eggs).length > 0 && taskId && aviaries) {
			const taskIdNum = parseInt(taskId);
			if (isNaN(taskIdNum)) {
				return
			}
			try {
				// Collecting 0 eggs isn't worth recording, we can assume it and fill zeroes with timescale
				setIsLoading(true)
				// Make sure we have the latest egg data from the server.
				let response = await EggsApi.get(batch.id);
				let responseEggs: IAviaryEggMap = {};
				response.data.forEach((egg: IEggs) => {
					responseEggs[egg.aviaryId] = egg;
				});
				setEggs(responseEggs)
				// Attempt to collate all egg measurements on the page
				for (let aviary of aviaries) {
					if (!eggs[aviary.id]) {
						// Check if we have a non-0 value in the other columns
						let amount = aviaryValues.get(aviary.id)
						if (amount && amount > 0) {
							amount = amount * 1000;
							await EggsApi.create({
								aviaryId: aviary.id,
								batchId: batch.id,
								eggsMg: amount,
								...(date && {time: nowTime(date)})
							});
						}
					}
				}

				await TaskApi.postDone(taskIdNum)
				enqueueSnackbar("Successfully submitted egg collection data", {variant: "success"})
				history.push("/")
			} catch (e) {
				enqueueSnackbar("Failed to submit egg collection data", {variant: "error"});
				setIsLoading(false);
				console.log('Error', e.message);
			}
		}
	}

	const submit = async () => {
		await submitBatch();
		await refreshTodo(navState, todoDispatch);
	}

	const handleFormPaging = async (back = false) => {
		if (!back) {
			if (eggs && aviaries && aviaries.length - Object.keys(eggs).length === 0) {
				await submit();
			} else {
				setAlertOpen(true);
			}
		}
	}

	return (
		<Form
			heading="Egg Collection"
			subheading={`Batch ${batch?.localId.toString().padStart(4, "0")}`}
			loading={isLoading}
			handleFormPaging={handleFormPaging}
			nextDisabled={isLoadingEggs || !((eggs && Object.keys(eggs).length > 0) || aviaryValues.size > 0)}
			dateProps={{
				date: date,
				setDate: setDate,
				readonly: true
			}}
			alertProps={{
				setOpen: setAlertOpen,
				open: alertOpen,
				missing: eggs && aviaries ? aviaries?.length - Object.keys(eggs).length - aviaryValues.size : 0,
				submit: submit
			}}
		>
			<FormSection>
				<Grid container spacing={3}>
					{aviaries?.map((aviary: IAviary) => {
						return <Aviary
							key={aviary.id}
							id={aviary.id}
							name={aviary.name}
							submit={submitAviary}
							valueIn={eggs && eggs[aviary.id] ? eggs[aviary.id].eggsMg / 1000 : null}
							measurement="Eggs Collected"
							units="g"
							loading={isLoadingEggs}
							setAviaryValue={setAviaryEggsValue}
						/>
					})}
				</Grid>
			</FormSection>
		</Form>
	)
}

export default EggsForm;