import {GlobalStyles} from "@mui/material";
import React, {useContext} from "react";
import {NavContext} from "../../shared/providers/NavProvider";
import {MagOpsColors} from "../../shared/styles/MagOpsStyle";
import LayoutContainer from "./LayoutContainer";
import NavigationLayout from "./NavigationLayout";

type LayoutProps = {
	children?: React.ReactNode,
};

const Layout = ({children}: LayoutProps) => {
	const [navState,] = useContext(NavContext);

	return (
		<React.Fragment>
			<GlobalStyles
				styles={{
					"body": {
						backgroundColor: navState.darkMode ? MagOpsColors.primary.goterraSky : MagOpsColors.primary.duskBlue
					}
				}}
			/>
			<LayoutContainer>
				<NavigationLayout>
					{children}
				</NavigationLayout>
			</LayoutContainer>
		</React.Fragment>
	)
}

export default Layout;