import React, {useContext, useEffect, useState} from "react";
import {useHistory} from "react-router-dom";
import Form from "../../../components/forms/Form";
import {useSnackbar} from "notistack";
import {NavContext} from "../../../shared/providers/NavProvider";
import ISite from "../../../shared/types/Site";
import SiteApi from "../../../shared/api/SiteApi";
import SiteInfrastructure from "../../../components/forms/SiteInfrastructure";
import AviaryApi from "../../../shared/api/AviaryApi";
import IncubatorApi from "../../../shared/api/IncubatorApi";
import GrowRoomApi from "../../../shared/api/GrowRoomApi";
import DarkRoomApi from "../../../shared/api/DarkRoomApi";
import Site from "../../../components/forms/Site";
import StackApi from "../../../shared/api/StackApi";
import IStack from "../../../shared/types/Stack";
import ConfigApi from "../../../shared/api/ConfigApi";
import axios from "axios";

const InfrastructureForm = () => {
	const [, navDispatch] = useContext(NavContext)
	const [isLoading, setIsLoading] = useState(true)
	const [sites, setSites] = useState<ISite[]>([]);
	const [stacks, setStacks] = useState<IStack[]>();
	const [stacksDirty, setStacksDirty] = useState(false);
	const [sitesDirty, setSitesDirty] = useState(false);
	const [date, setDate] = useState<Date | null>(new Date());
	const {enqueueSnackbar} = useSnackbar();

	let history = useHistory();

	useEffect(() => {
		(async () => {
			try {
				setIsLoading(true);
				let response = await SiteApi.getAll();
				setSites(response.data);
				setSitesDirty(false);
			} catch (error) {
				if (axios.isCancel(error)) {
					return;
				}
				// Something happened in setting up the request that triggered an Error
				enqueueSnackbar(`Failed to find sites`, {variant: "error"})
				console.log('Error', error.message);
			}
		})()

		return () => SiteApi.abort();
	}, [enqueueSnackbar, history, sitesDirty])


	useEffect(() => {
		(async () => {
			try {
				setIsLoading(true);
				let response = await StackApi.getAll();
				setStacks(response.data);
				setStacksDirty(false);
			} catch (error) {
				if (axios.isCancel(error)) {
					return;
				}
				// Something happened in setting up the request that triggered an Error
				enqueueSnackbar(`Failed to find stacks`, {variant: "error"})
				console.log('Error', error.message);
			}
		})()

		return () => StackApi.abort();
	}, [enqueueSnackbar, history, stacksDirty])

	useEffect(() => {
		if (sites && stacks) {
			setIsLoading(false);
		}
	}, [sites, stacks])

	const showError = (error: Error) => {
		enqueueSnackbar("Failed to create infrastructure", {variant: "error"})
		console.log('Error', error.message);
		setIsLoading(false);
	}

	return (
		<Form
			heading="Add Infrastructure"
			loading={isLoading}
			dateProps={{
				date: date,
				setDate: setDate,
				readonly: true
			}}
		>
			<Site
				header="Add Site"
				submit={(async (location) => {
					try {
						if (location !== "") {
							setIsLoading(true);
							const response = await SiteApi.create({
								location: location
							});
							// Generate default config for the new site
							await ConfigApi.default(response.data.id)

							// Refresh the list of sites
							let sites = await SiteApi.getAll();
							navDispatch({
								type: "SET_SITES",
								data: sites.data
							});

							setIsLoading(false);
							setSitesDirty(true);
							enqueueSnackbar(`Successfully added site with id ${response.data.id}`,
								{variant: "success"})
						}
					} catch (error) {
						showError(error);
					}
				})}
			/>
			<SiteInfrastructure
				show={sites && sites?.length > 0}
				header="Add Aviary"
				submit={(async (siteId, name) => {
					try {
						if (siteId > 0 && name !== "") {
							setIsLoading(true);
							const response = await AviaryApi.create({
								name: name,
								siteId: siteId
							});
							setIsLoading(false);
							enqueueSnackbar(`Successfully added aviary with id ${response.data.id}`,
								{variant: "success"})
						}
					} catch (error) {
						showError(error);
					}
				})}
				sites={sites}
			/>
			<SiteInfrastructure
				show={sites && sites?.length > 0}
				header="Add Incubator"
				submit={(async (siteId, name) => {
					try {
						if (siteId > 0 && name !== "") {
							setIsLoading(true);
							const response = await IncubatorApi.create({
								name: name,
								siteId: siteId
							});
							setIsLoading(false);
							enqueueSnackbar(`Successfully added incubator with id ${response.data.id}`,
								{variant: "success"})
						}
					} catch (error) {
						showError(error);
					}
				})}
				sites={sites}
			/>
			<SiteInfrastructure
				show={sites && sites?.length > 0}
				header="Add Grow Room"
				submit={(async (siteId, name) => {
					try {
						if (siteId > 0 && name !== "") {
							setIsLoading(true);
							const response = await GrowRoomApi.create({
								name: name,
								siteId: siteId
							});
							setIsLoading(false);
							enqueueSnackbar(`Successfully added grow room with id ${response.data.id}`,
								{variant: "success"})
						}
					} catch (error) {
						showError(error);
					}
				})}
				sites={sites}
			/>
			<SiteInfrastructure
				show={sites && sites?.length > 0}
				header="Add Dark Room"
				submit={(async (siteId, name) => {
					try {
						if (siteId > 0 && name !== "") {
							setIsLoading(true);
							const response = await DarkRoomApi.create({
								name: name,
								siteId: siteId
							});
							setIsLoading(false);
							enqueueSnackbar(`Successfully added dark room with id ${response.data.id}`,
								{variant: "success"})
						}
					} catch (error) {
						showError(error);
					}
				})}
				sites={sites}
			/>
			<SiteInfrastructure
				show={sites && sites?.length > 0}
				header="Add Stack"
				submit={(async (siteId, name) => {
					try {
						if (name) {
							setIsLoading(true);
							const response = await StackApi.create({
								name: name,
								siteId: siteId
							});
							setIsLoading(false);
							setStacksDirty(true);
							enqueueSnackbar(`Successfully added stack with id ${response.data.id}`,
								{variant: "success"})
						}
					} catch (error) {
						showError(error);
					}
				})}
				sites={sites}
			/>
		</Form>
	)
}

export default InfrastructureForm;