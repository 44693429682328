import AxiosRequest from "./AxiosRequest"
import IDarkRoom from "../types/DarkRoom";
import AbstractMagOpsApi from "./AbstractMagOpsApi";

class DarkRoomApi extends AbstractMagOpsApi {
	getAll(siteId: number) {
		return AxiosRequest.get<IDarkRoom[]>("/dark_rooms", {
			params: {
				"siteId": siteId
			},
			signal: this.getRequestSignal()
		})
	}

	get(id: number) {
		return AxiosRequest.get<IDarkRoom>(`/dark_room/${id}`, {
			signal: this.getRequestSignal()
		})
	}

	create(data: { siteId: number, name: string }) {
		return AxiosRequest.post<IDarkRoom>("/dark_room", data)
	}

	delete(id: number) {
		return AxiosRequest.delete<{}>(`/dark_room/${id}`)
	}
}

export default new DarkRoomApi()