import AxiosRequest from "./AxiosRequest"
import IBatchTrays from "../types/BatchTrays";
import AbstractMagOpsApi from "./AbstractMagOpsApi";

class TraysApi extends AbstractMagOpsApi {
	getFeeders() {
		return AxiosRequest.get<IBatchTrays[]>("/trays/feeders", {
			signal: this.getRequestSignal()
		})
	}
}

export default new TraysApi()