import React, {useContext, useEffect, useState} from "react";
import {useHistory, useParams} from "react-router-dom";
import Form from "../../../components/forms/Form";
import ValueInputCard from "../../../components/forms/ValueInputCard";
import FormOutputs from "../../../components/forms/FormOutputs";
import {useSnackbar} from "notistack";
import {NavContext} from "../../../shared/providers/NavProvider";
import {TodoContext} from "../../../shared/providers/TodoProvider";
import TransferApi from "../../../shared/api/TransferApi";
import {ConfigContext} from "../../../shared/providers/ConfigProvider";
import IBatch from "../../../shared/types/Batch";
import {formatFloats, formatInt, nowTime, refreshTodo} from "../../../shared/helpers";
import {getTransferMath, TransferMath} from "../../../shared/helpers/MaggotMath";
import ValueCard from "../../../components/forms/ValueCard";
import useBatch from "../../../shared/helpers/useBatch";

type TransferType = "Wet" | "Dry"

enum TransferFormPage {
	Data,
	Math
}

interface TransferParams {
	taskId?: string;
}

const TransferForm = () => {
	const [navState] = useContext(NavContext);
	const [configState] = useContext(ConfigContext);
	const [batch, setBatch] = useState<IBatch>();
	const [transferMath, setTransferMath] = useState<TransferMath>();
	const [isLoading, setIsLoading] = useState(true)
	const [biomass, setBiomass] = useState<number>(0);
	const [biomassValid, setBiomassValid] = useState<boolean>(false);
	const [avgNeoSamplesWeight, setAvgNeoSamplesWeight] = useState<number>();
	const [avgNeoSamplesWeightValid, setAvgNeoSamplesWeightValid] = useState<boolean>(false);
	const [avgIndividualNeoWeight, setAvgIndividualNeoWeight] = useState<number>(0);
	const [avgIndividualNeoWeightValid, setAvgIndividualNeoWeightValid] = useState<boolean>(false);
	const [eggs, setEggs] = useState<number>(0);
	const [eggsValid, setEggsValid] = useState<boolean>(false);
	const [page, setPage] = useState(TransferFormPage.Data);
	const [date, setDate] = useState<Date | null>(new Date());
	const [transferType, setTransferType] = useState<TransferType>("Dry")
	const [estHatchingRate, setEstHatchingRate] = useState<number>();
	const [estHatchingRateValid, setEstHatchingRateValid] = useState<boolean>(false);
	const [, todoDispatch] = useContext(TodoContext);
	const {enqueueSnackbar} = useSnackbar();

	let history = useHistory();
	let {taskId} = useParams<TransferParams>()

	useBatch({
		taskIdStr: taskId,
		setBatch,
		setDate,
		setIsLoading
	})

	useEffect(() => {
		if ((avgNeoSamplesWeightValid || estHatchingRateValid) && avgIndividualNeoWeightValid && biomassValid && eggsValid
			&& batch && configState.config) {
			const tmath = getTransferMath({
				batchId: batch.id,
				biomassG: biomass,
				neoSampleMg: avgNeoSamplesWeightValid ? avgNeoSamplesWeight : undefined,
				neoIndivUg: avgIndividualNeoWeight,
				estimatedHatchingRate: estHatchingRateValid ? estHatchingRate : undefined
			}, configState.config, eggs);
			setTransferMath(tmath);
		}

	}, [avgIndividualNeoWeightValid, avgNeoSamplesWeightValid, estHatchingRateValid, estHatchingRate, biomassValid, batch, eggsValid, eggs,
		configState.config, avgIndividualNeoWeight, avgNeoSamplesWeight, biomass])

	const submit = async () => {
		if (batch && date !== null && taskId) {
			const taskIdNum = parseInt(taskId);
			if (isNaN(taskIdNum)) {
				return
			}
			try {
				setIsLoading(true)
				await TransferApi.create({
					batchId: batch.id,
					biomassG: biomass,
					neoSampleMg: avgNeoSamplesWeightValid ? avgNeoSamplesWeight : undefined,
					neoIndivUg: avgIndividualNeoWeight,
					time: nowTime(date),
					taskId: taskIdNum,
					estimatedHatchingRate: estHatchingRateValid ? estHatchingRate : undefined
				})
				enqueueSnackbar("Successfully submitted transfer data", {variant: "success"})
				history.push("/")
			} catch (e) {
				enqueueSnackbar("Failed to submit transfer data", {variant: "error"});
				setIsLoading(false);
				console.log('Error', e.message);
			}
		}
	}

	const handleFormPaging = async (back = false) => {
		if (back) {
			if (page > 0) {
				setPage(page - 1);
			}
		} else if (page < (Object.keys(TransferFormPage).length / 2) - 1) {
			setPage(page + 1)
		} else {
			await submit()
			await refreshTodo(navState, todoDispatch)
		}
	}

	return (
		<Form
			heading="Transfer Batch"
			subheading={`Batch ${batch?.localId.toString().padStart(4, "0")}`}
			loading={isLoading}
			handleFormPaging={handleFormPaging}
			nextDisabled={!(avgNeoSamplesWeightValid || estHatchingRateValid) || !avgIndividualNeoWeightValid || !biomassValid || !eggsValid}
			page={page}
			pages={Object.keys(TransferFormPage).length / 2}
			dateProps={{
				date: date,
				setDate: setDate,
				readonly: true
			}}
		>
			<FormOutputs show={page === TransferFormPage.Data}>
				<ValueInputCard
					heading="Transfer Type"
					valid={avgIndividualNeoWeightValid}
					setValueStr={value => {
						setTransferType(value as TransferType)
						setEstHatchingRate(undefined)
						setAvgNeoSamplesWeight(undefined)
						setEstHatchingRateValid(false)
						setAvgNeoSamplesWeightValid(false)
					}}
					setValid={setAvgIndividualNeoWeightValid}
					defaultValue={"Dry"}
					options={["Dry", "Wet"]}
				/>
				<ValueInputCard
					heading="Total Batch Weight"
					valid={biomassValid}
					setValue={value => setBiomass(value * 1000)}
					setValid={setBiomassValid}
					units="kg"
				/>
				{transferType === "Dry" && <ValueInputCard
					heading="Larvae Fraction"
					valid={avgNeoSamplesWeightValid}
					setValue={value => setAvgNeoSamplesWeight(value * 1000)}
					setValid={setAvgNeoSamplesWeightValid}
					helperText={`Use the ${configState.config?.actualSampleWeight}g sample larvae weight fraction here`}
					units="g"
				/>}
				{transferType === "Wet" && <ValueInputCard
					heading="Estimated Hatching Rate"
					valid={estHatchingRateValid}
					setValue={value => setEstHatchingRate(value / 100)}
					setValid={setEstHatchingRateValid}
					units="%"
				/>}
				<ValueInputCard
					heading="Average Neonate Weight"
					valid={avgIndividualNeoWeightValid}
					setValue={value => setAvgIndividualNeoWeight(value * 1000)}
					setValid={setAvgIndividualNeoWeightValid}
					units="mg"
				/>
				<ValueInputCard
					heading="Eggs"
					valid={eggsValid}
					setValue={value => setEggs(value * 1000)}
					setValid={setEggsValid}
					units="g"
				/>
			</FormOutputs>
			{transferMath &&
				<FormOutputs show={page === TransferFormPage.Math} header="Batch Stats">
					<ValueCard
						heading="Total Biomass"
						value={formatFloats(biomass / 1000) + "kg"}
					/>
					<ValueCard
						heading="% Neonates in Substrate"
						value={formatFloats(transferMath.maggotMath.percentNeoInSubstrate * 100) + "%"}
					/>
					<ValueCard
						heading="# Neonates in Batch"
						value={formatInt(transferMath.maggotMath.neoInBatch)}
					/>
					<ValueCard
						heading="Hatching Rate"
						value={formatFloats(transferMath.maggotMath.hatchingRate * 100) + "%"}
					/>
				</FormOutputs>
			}
			{transferMath &&
				<FormOutputs show={page === TransferFormPage.Math} header="Breeders">
					<ValueCard
						heading="Possible Breeder Trays"
						value={formatInt(transferMath.maggotMath.breederTraysPossible)}
					/>
					<ValueCard
						heading="Substrate per Breeder Tray"
						value={formatFloats(transferMath.maggotMath.substratePerBreederTrayMg / 1000) + "g"}
					/>
					<ValueCard
						heading="Total Breeder Substrate"
						value={formatFloats(transferMath.maggotMath.totalBreedingSubstrateMg / 1000) + "g"}
					/>
				</FormOutputs>
			}
			{transferMath &&
				<FormOutputs show={page === TransferFormPage.Math} header="Feeders">
					<ValueCard
						heading="Feeder Larvae"
						value={formatInt(transferMath.maggotMath.feederMaggots)}
					/>
					<ValueCard
						heading="Substrate per Feeder Tray"
						value={formatFloats(transferMath.maggotMath.substratePerFeederTrayMg / 1000) + "g"}
					/>
					<ValueCard
						heading="Total Feeder Substrate"
						value={formatFloats(transferMath.maggotMath.totalFeedingSubstrateMg / 1000) + "g"}
					/>
				</FormOutputs>
			}
			{transferMath &&
				<FormOutputs show={page === TransferFormPage.Math} header="Tray Outputs">
					<ValueCard
						heading="Breeder Trays"
						value={formatInt(transferMath.breederTrays)}
					/>
					<ValueCard
						heading="Breeder Tray Density"
						value={formatInt(configState.config?.breederTrayDensity || 0)}
					/>
					<ValueCard
						heading="Feeder Trays"
						value={formatInt(transferMath.feederTrays)}
					/>
					<ValueCard
						heading="Feeder Tray Density"
						value={formatInt(configState.config?.feederTrayDensity || 0)}
					/>
				</FormOutputs>
			}
		</Form>
	)
}

export default TransferForm;