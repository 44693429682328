import {Hidden} from "@mui/material";
import React, {ReactNode} from "react";
import {StyledTodoDrawer, StyledTodoDrawerMobile} from "./TodoStyles";

type Props = {
	onClose: (() => void);
	open: boolean;
	openMobile: boolean;
	children?: ReactNode;
	drawerWidth: number
};

const TodoDrawer = ({onClose, open, openMobile, children, drawerWidth}: Props) => {
	return (
		<React.Fragment>
			<Hidden smUp>
				<StyledTodoDrawerMobile
					variant="temporary"
					anchor="bottom"
					open={openMobile}
					onClose={onClose}
					PaperProps={{
						elevation: 2,
						square: false
					}}
					ModalProps={{
						keepMounted: true, // Better open performance on mobile.
						onClick: onClose
					}}
				>
					{children}
				</StyledTodoDrawerMobile>
			</Hidden>
			<Hidden smDown>
				<StyledTodoDrawer
					drawerWidth={drawerWidth}
					variant={"persistent"}
					anchor="right"
					open={open}
					onClose={onClose}
				>
					{children}
				</StyledTodoDrawer>

			</Hidden>
		</React.Fragment>
	);
}

export default TodoDrawer;