import ITodo from "../types/Todo";
import IProductionTask from "../types/ProductionTask";

export type TodoState = {
	todoList: ITodo;
	todoLength: number;
	loading: boolean;
}

export type TodoAction =
	| { type: "SET_TASKS", data: IProductionTask[] }
	| { type: "SET_LOADING" }

const taskFilter = (task: IProductionTask) => task.status === "Todo" || task.status === "Overdue"

const sortTasks = (tasks: IProductionTask[]): ITodo => {
	return {
		aviary: tasks.filter(task => task.group === "Aviary"),
		incubator: tasks.filter(task => task.group === "Incubator"),
		growRoom: tasks.filter(task => task.group === "GrowRoom"),
		sifting: tasks.filter(task => task.group === "Sifting"),
		darkRoom: tasks.filter(task => task.group === "DarkRoom")
	}
}

export const todoReducer = (state: TodoState, action: TodoAction): TodoState => {
	switch (action.type) {
		case "SET_TASKS":
			return {
				...state,
				todoList: sortTasks(action.data),
				todoLength: action.data.filter(taskFilter).length,
				loading: false
			}
		case
		"SET_LOADING":
			return {
				todoList: {
					aviary: [],
					incubator: [],
					growRoom: [],
					sifting: [],
					darkRoom: [],
				},
				todoLength: 0,
				loading: true
			}
		default:
			return state;
	}
}

export const initialTodoState: TodoState = {
	todoList: {
		aviary: [],
		incubator: [],
		growRoom: [],
		sifting: [],
		darkRoom: [],
	},
	todoLength: 0,
	loading: true
}