import {Button, Grid} from "@mui/material";
import React, {useState} from "react";
import FormSection from "./FormSection";
import Heading from "./Heading";
import ValueInputCard from "./ValueInputCard";

type Props = {
	show?: boolean;
	header: string;
	submit: (name: string) => void;
	validIds?: number[];
}

const Site = ({submit, show, header}: Props) => {
	const [location, setLocation] = useState<string>("");
	const [locationValid, setLocationValid] = useState<boolean>(false);

	return (
		<FormSection show={show}>
			<Grid container spacing={3} justifyContent="center" alignContent="center">
				<Grid item xs={12}>
					<Heading subheading={header}/>
				</Grid>
				<Grid item xs={12} sm={6} md={4} lg={3} sx={{display: "flex"}}>
					<ValueInputCard
						heading="Location"
						valid={locationValid}
						setValueStr={setLocation}
						setValid={setLocationValid}
					/>
				</Grid>
				<Grid item xs={12}>
					<Button
						size="large"
						color="primary"
						variant="contained"
						disabled={!locationValid}
						onClick={() => submit(location)}
					>
						{`${header}`}
					</Button>
				</Grid>
			</Grid>
		</FormSection>

	)
}

export default Site;