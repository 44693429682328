import GrowRoomSampleApi from "../../../../shared/api/GrowRoomSampleApi";
import {nowTime} from "../../../../shared/helpers";
import GrowRoomForm from "../GrowRoomForm";

const GrowRoomSampleForm = () => {
	return (
		<GrowRoomForm
			create={(async (batchId, value, taskId, date) => {
				return await GrowRoomSampleApi.create({
					batchId: batchId,
					larvaeWeightMg: value,
					taskId: taskId,
					...(date && {time: nowTime(date)})
				})
			})}
			heading="Sample Measurement"
			inputHeading="Larvae Weight Sample"
			inputUnits="mg"
			successMessage="Successfully submitted larvae sample"
		/>
	)
}

export default GrowRoomSampleForm;