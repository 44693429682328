import React, {useContext, useEffect, useState} from "react";
import IBatch from "../../../shared/types/Batch";
import {useHistory, useParams} from "react-router-dom";
import Form from "../../../components/forms/Form";
import ValueInputCard from "../../../components/forms/ValueInputCard";
import ValueCard from "../../../components/forms/ValueCard";
import FormOutputs from "../../../components/forms/FormOutputs";
import {useSnackbar} from "notistack";
import {NavContext} from "../../../shared/providers/NavProvider";
import {TodoContext} from "../../../shared/providers/TodoProvider";
import {nowTime, refreshTodo} from "../../../shared/helpers";
import {ConfigContext} from "../../../shared/providers/ConfigProvider";
import HatchingTraysApi from "../../../shared/api/HatchingTraysApi";
import useBatch from "../../../shared/helpers/useBatch";


interface HatchingTraysParams {
	taskId?: string;
}

const HatchingTraysForm = () => {
	const [navState] = useContext(NavContext);
	const [configState] = useContext(ConfigContext);
	const [batch, setBatch] = useState<IBatch>();
	const [isLoading, setIsLoading] = useState(true);
	const [feed, setFeed] = useState<number>(configState.config?.hatchingTraysFeedAmountG || 0 / 1000);
	const [feedValid, setFeedValid] = useState<boolean>(!!configState.config?.hatchingTraysFeedAmountG);
	const [, todoDispatch] = useContext(TodoContext);
	const {enqueueSnackbar} = useSnackbar();
	const [date, setDate] = useState<Date | null>(new Date())

	let history = useHistory();
	let {taskId} = useParams<HatchingTraysParams>();

	useBatch({
		taskIdStr: taskId,
		setBatch,
		setDate,
		setIsLoading,
	})

	useEffect(() => {
		if (batch) {
			setIsLoading(false)
		}
	}, [batch])

	const submitBatch = async () => {
		if (batch && feed && taskId) {
			const taskIdNum = parseInt(taskId);
			if (isNaN(taskIdNum)) {
				return
			}
			try {
				// Collecting 0 eggs isn't worth recording, we can assume it and fill zeroes with timescale
				setIsLoading(true)
				await HatchingTraysApi.create({
					batchId: batch.id,
					feedG: feed,
					taskId: taskIdNum,
					...(date && {time: nowTime(date)})
				})
				enqueueSnackbar("Successfully submitted hatching trays data", {variant: "success"})
				history.push("/")
			} catch (e) {
				enqueueSnackbar("Failed to submit hatching trays data", {variant: "error"});
				setIsLoading(false);
				console.log('Error', e.message);
			}
		}
	}

	const submit = async () => {
		await submitBatch();
		await refreshTodo(navState, todoDispatch);
	}

	const handleFormPaging = async (back = false) => {
		if (!back) {
			await submit()
			await refreshTodo(navState, todoDispatch)
		}
	}

	const totalEggsGrams = batch?.eggs?.map(e => e.eggsMg / 1000).reduce((accum, egg) => accum + egg);

	return (
		<Form
			heading="Hatching Trays"
			subheading={`Batch ${batch?.localId.toString().padStart(4, "0")}`}
			loading={isLoading}
			handleFormPaging={handleFormPaging}
			nextDisabled={!feedValid}
			dateProps={{
				date: date,
				setDate: setDate,
				readonly: true
			}}
		>
			<FormOutputs>
				<ValueCard
					heading="Total Eggs"
					value={totalEggsGrams ? totalEggsGrams + "g" : 0}
				/>
				<ValueCard
					heading="Eggs per Tray"
					value={(configState.config?.eggTrayDensity || 0) + "g"}
				/>
				<ValueCard
					heading="Total Trays"
					value={totalEggsGrams && configState.config ?
						Math.ceil(totalEggsGrams / configState.config.eggTrayDensity) : 0}
				/>
				<ValueInputCard
					heading="Feed per Tray"
					valid={feedValid}
					setValue={value => setFeed(value * 1000)}
					setValid={setFeedValid}
					units="g"
					defaultValue={feed}
					// helperText={`Defaults to SOP value of ${(configState.config?.hatchingTraysFeedAmountG || 0) / 1000}kg`}
				/>
			</FormOutputs>
		</Form>
	)
}

export default HatchingTraysForm;