import React, {useEffect, useState} from "react";
import Form from "../../../components/forms/Form";
import {useSnackbar} from "notistack";
import InfrastructureName from "../../../components/forms/InfrastructureName";
import CustomerApi from "../../../shared/api/CustomerApi";
import IBillableCustomer from "../../../shared/types/BillableCustomer";
import CustomerSite from "../../../components/forms/CustomerSite";
import CustomerSiteApi from "../../../shared/api/CustomerSiteApi";
import useCustomers from "../../../shared/helpers/useCustomers";

const CustomerForm = () => {
	const [isLoading, setIsLoading] = useState(true)
	const [customers, setCustomers] = useState<IBillableCustomer[]>();
	const [customersDirty, setCustomersDirty] = useState<boolean>(true);
	const [date, setDate] = useState<Date | null>(new Date());
	const {enqueueSnackbar} = useSnackbar();

	useCustomers({
		setCustomers,
		setIsLoading,
		setDirty: setCustomersDirty,
		dirty: customersDirty
	})

	useEffect(() => {
		if (customers) {
			setIsLoading(false);
		}
	}, [customers])

	const showError = (error: Error) => {
		enqueueSnackbar("Failed to create customer information", {variant: "error"})
		console.log('Error', error.message);
		setIsLoading(false);
	}

	return (
		<Form
			heading="Add Customers"
			loading={isLoading}
			dateProps={{
				date: date,
				setDate: setDate,
				readonly: true
			}}
		>
			<InfrastructureName
				header="Add Customer"
				submit={(async (name) => {
					try {
						if (name) {
							setIsLoading(true);
							const response = await CustomerApi.create({
								name: name
							});
							setCustomersDirty(true);
							setIsLoading(false);
							enqueueSnackbar(`Successfully added customer with id ${response.data.id}`,
								{variant: "success"})
						}
					} catch (error) {
						showError(error);
					}
				})}
			/>
			{customers &&
				<CustomerSite
					show={customers.length > 0}
					header="Add Customer Sites"
					submit={(async (names, site, customer, binType) => {
						try {
							if (names && customer.id) {
								setIsLoading(true);
								await CustomerSiteApi.create({
									billableCustomerId: customer.id,
									binType: binType,
									names: names,
									siteId: site.id
								});
								setCustomersDirty(true);
								setIsLoading(false);
								enqueueSnackbar(`Successfully added customer sites`,
									{variant: "success"})
							}
						} catch (error) {
							showError(error);
						}
					})}
					customers={customers}
				/>
			}
		</Form>
	)
}

export default CustomerForm;