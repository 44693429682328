import Loading from "../layout/Loading";
import {Box, Button, Grid, Grow, InputAdornment} from "@mui/material";
import Heading from "./Heading";
import React from "react";
import {DateValueTextField, FormPaper, FormSubmitLayout, FormValueCard} from "./FormStyles";
import {DatePicker, DateRangePicker} from "@mui/lab";
import {Event} from "@mui/icons-material";
import AlertDialogue from "./AlertDialogue";
import {DateRange} from "@mui/lab/DateRangePicker/RangeTypes";

type DateRangeProps = {
	dateRange: DateRange<Date>;
	setDateRange: (range: DateRange<Date>) => void;
	minDate?: Date;
	maxDate?: Date;
}

type DateProps = {
	date: Date | null;
	setDate: (date: Date) => void;
	minDate?: Date;
	maxDate?: Date;
	readonly?: boolean
}

type AlertProps = {
	setOpen: (open: boolean) => void;
	open: boolean;
	missing: number;
	submit: () => void;
}

type Props = {
	children?: React.ReactNode | React.ReactNode[];
	heading: string;
	subheading?: string;
	loading?: boolean;
	handleFormPaging?: (back?: boolean) => void;
	nextDisabled?: boolean;
	page?: number;
	pages?: number;
	dateProps?: DateProps;
	alertProps?: AlertProps;
	dateRangeProps?: DateRangeProps;
}
// Defaults set to what is needed for a single-page form
const Form = ({
								children,
								heading,
								subheading,
								loading,
								handleFormPaging,
								nextDisabled = true,
								page = 0,
								pages = 1,
								dateProps,
								alertProps,
								dateRangeProps
							}: Props) => {

	return (
		<Loading loading={loading}>
			{alertProps && <AlertDialogue {...alertProps}/>}
			<Box width="100%">
				<FormPaper sx={{marginBottom: 3}}>
					<Grid container justifyContent="space-between">
						<Grid item xs={12} sm={8} md={6}>
							<Heading heading={heading} subheading={subheading}/>
						</Grid>
						{dateProps &&
							<Grid item xs={12} sm={4} md={3} xl={2} justifySelf="end">
								<DatePicker
									inputFormat="dd/MM/yyyy"
									value={dateProps.date}
									readOnly={dateProps.readonly}
									onChange={(date: Date | null) => date ? dateProps.setDate(date) : () => {
									}}
									minDate={dateProps.minDate}
									maxDate={dateProps.maxDate}
									renderInput={(params) => {
										return <DateValueTextField
											{...params}
											fullWidth
											variant="outlined"
											helperText={`${heading} Date`}
										/>
									}}
									components={{
										OpenPickerIcon: () => <Event color="primary"/>
									}}
								/>
							</Grid>
						}
						{dateRangeProps &&
							<Grid item xs={12} md={6} xl={4}>
								<Box alignItems="center" justifyContent="center" display="flex">
									<DateRangePicker
										inputFormat="dd/MM/yyyy"
										startText=""
										endText=""
										maxDate={dateRangeProps.maxDate}
										value={dateRangeProps.dateRange}
										onChange={(newValue) => {
											dateRangeProps.setDateRange(newValue);
										}}
										renderInput={(startProps, endProps) => (
											<FormValueCard sx={{flexGrow: 1}} variant="outlined">
												<Box display="flex" alignItems="center" justifyContent="center">
													<DateValueTextField
														{...startProps}
														variant="standard"
														helperText="Start Date"
														sx={{
															mr: 1
														}}
														InputProps={{
															endAdornment: (
																<InputAdornment position="end">
																	<Event color="primary"/>
																</InputAdornment>
															),
														}}
													/>
													<DateValueTextField
														{...endProps}
														variant="standard"
														helperText="End Date"
														sx={{
															ml: 1
														}}
														InputProps={{
															endAdornment: (
																<InputAdornment position="end">
																	<Event color="primary"/>
																</InputAdornment>
															),
														}}
													/>
												</Box>
											</FormValueCard>
										)}
									/>
								</Box>
							</Grid>
						}
					</Grid>
				</FormPaper>
				<Grid container spacing={3}>
					{children}
				</Grid>
				{handleFormPaging &&
					<FormPaper sx={{marginTop: 2}}>
						<FormSubmitLayout>
							<Grow in={page !== 0}>
								<Button
									size="large"
									variant="contained"
									color="primary"
									onClick={() => handleFormPaging(true)}
								>
									Back
								</Button>
							</Grow>
							<Button
								size="large"
								variant="contained"
								color="primary"
								disabled={nextDisabled}
								onClick={() => handleFormPaging()}
							>
								{page < pages - 1 ? "Next" : "Submit"}
							</Button>
						</FormSubmitLayout>
					</FormPaper>
				}
			</Box>
		</Loading>
	)
}

export default Form