import {Container, Theme, useMediaQuery} from "@mui/material";
import React from "react";

type Props = {
	children: React.ReactNode
}

const LayoutContainer = ({children}: Props) => {
	const xlUp = useMediaQuery((theme: Theme) => theme.breakpoints.up("xl"));

	return (
		<React.Fragment>
			<Container maxWidth={xlUp ? "lg" : "xl"}>
				{children}
			</Container>
		</React.Fragment>
	)
}

export default LayoutContainer;