import AxiosRequest from "./AxiosRequest"
import IBatch from "../types/Batch";
import AbstractMagOpsApi from "./AbstractMagOpsApi";

export type Lifecycle =
	"Transfer"
	| "Eggs"
	| "GrowRoomSample"
	| "GrowRoomFeed"
	| "Sifting"
	| "PupaeIntro"
	| "HatchingTrays"
	| "PupaeIntroPrep"

class BatchApi extends AbstractMagOpsApi {

	get(id: number, lifecycle?: Lifecycle, detailed?: boolean) {
		return AxiosRequest.get<IBatch>(`/batch/${id}`, {
				params: {
					...(lifecycle && {lifecycle}),
					...(detailed && {detailed})
				},
				signal: this.getRequestSignal()
			}
		)
	}

	getByTask(taskId: number, detailed?: boolean) {
		return AxiosRequest.get<IBatch>("/batch", {
				params: {
					taskId: taskId,
					...(detailed && {detailed})
				},
				signal: this.getRequestSignal()
			}
		)
	}

	list(siteId: number, detailed?: boolean) {
		return AxiosRequest.get<IBatch[]>("/batches", {
				params: {
					siteId: siteId,
					...(detailed && {detailed})
				},
				signal: this.getRequestSignal()
			}
		)
	}
}

export default new BatchApi()