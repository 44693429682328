import React, {useContext, useEffect} from "react";
import {ConfigContext} from "../../shared/providers/ConfigProvider";
import ConfigApi from "../../shared/api/ConfigApi";
import {NavContext} from "../../shared/providers/NavProvider";
import {TodoContext} from "../../shared/providers/TodoProvider";
import SiteApi from "../../shared/api/SiteApi";
import TaskApi from "../../shared/api/TaskApi";
import useInterceptors from "../../shared/helpers/useInterceptors";
import {AuthContext} from "../../shared/providers/AuthProvider";

type Props = {
	children: React.ReactNode | React.ReactNode[]
}

/**
 * Loads the relevant data into reducers for global values.
 */
const GlobalsLoader = ({children}: Props) => {
	const [configState, configDispatch] = useContext(ConfigContext);
	const [navState, navDispatch] = useContext(NavContext);
	const [authState,] = useContext(AuthContext);
	const [, todoDispatch] = useContext(TodoContext);
	useInterceptors();

	useEffect(() => {
		(async () => {
			try {
				if (!configState.config && navState.sites.length > 0) {
					let response = await ConfigApi.get(navState.sites[navState.siteIndex].id);
					configDispatch({
						type: "SET_CONFIG",
						data: response.data
					});
				}
			} catch (e) {
				console.log(e)
			}
		})();
	}, [configDispatch, configState.config, navState.sites, navState.siteIndex])

	useEffect(() => {
		(async () => {
			// If this isn't present, we won't be authorised to do this anyway
			if (!authState.invalid) {
				try {
					let response = await SiteApi.getAll();
					navDispatch({
						type: "SET_SITES",
						data: response.data
					});
				} catch (e) {
					console.log(e)
				}
			}
		})();
	}, [navDispatch, authState.invalid])

	useEffect(() => {
		(async () => {
			try {
				if (navState.sites.length > 0) {
					todoDispatch({type: "SET_LOADING"});
					let response = await TaskApi.get(navState.sites[navState.siteIndex].id);
					todoDispatch({
						type: "SET_TASKS",
						data: response.data
					});
				}
			} catch (e) {
				console.log(e)
			}
		})();
	}, [todoDispatch, navState.sites, navState.siteIndex])

	return (
		<React.Fragment>
			{children}
		</React.Fragment>
	)
}

export default GlobalsLoader;