/**
 * userBatch will retrieve a batch from the given task in the routing information
 */
import IBatch from "../types/Batch";
import {useEffect} from "react";
import BatchApi from "../api/BatchApi";
import axios from "axios";
import {useSnackbar} from "notistack";
import {subDays} from "date-fns";

/**
 * Required parameters for setting the batch
 */
type UseBatchParams = {
	taskIdStr?: string;
	setBatch: (batch: IBatch) => void;
	setDate: (date: Date) => void;
	setIsLoading: (loading: boolean) => void;
}

const useBatch = ({taskIdStr, setBatch, setDate, setIsLoading}: UseBatchParams) => {
	const {enqueueSnackbar} = useSnackbar();

	useEffect(() => {
		(async () => {
			try {
				setIsLoading(true);
				if (!taskIdStr) {
					return
				}
				const id = parseInt(taskIdStr);
				if (!isNaN(id)) {
					let batchResponse = await BatchApi.getByTask(id, true);
					setBatch(batchResponse.data);
					if (batchResponse.data?.tasks) {
						const filteredTasks = batchResponse.data.tasks.filter(t => t.id === id);
						if (filteredTasks.length > 0) {
							setDate(subDays(new Date(filteredTasks[0].timeDue), 1));
						}
					}
					setIsLoading(false);
				}
			} catch (error) {
				if (axios.isCancel(error)) {
					return;
				}
				// Something happened in setting up the request that triggered an Error
				enqueueSnackbar(`Failed to load batch with id ${taskIdStr}`, {variant: "error"})
				console.log('Error', error.message);
			}
		})()
	}, [enqueueSnackbar, setBatch, setDate, setIsLoading, taskIdStr]);
}

export default useBatch;