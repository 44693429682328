import React, {useState} from "react";
import Form from "../../../components/forms/Form";
import {useSnackbar} from "notistack";
import {Box, Button, Grid, TextField} from "@mui/material";
import AuthApi from "../../../shared/api/AuthApi";
import FormSection from "../../../components/forms/FormSection";
import Heading from "../../../components/forms/Heading";
import {validatePassword} from "../../../shared/helpers";

const InviteUserForm = () => {
	const [isLoading, setIsLoading] = useState(false);
	const [email, setEmail] = useState<string>();
	const [emailValid, setEmailValid] = useState(true);
	const [tempPassword, setTempPassword] = useState<string>();
	const [tempPasswordValid, setTempPasswordValid] = useState(true);
	const {enqueueSnackbar} = useSnackbar();

	const submit = async (admin?: boolean) => {
		if (emailValid && tempPasswordValid && email && tempPassword) {
			try {
				setIsLoading(true)
				await AuthApi.create(
					email,
					tempPassword,
					admin ? ["Admin"] : undefined
				);
				enqueueSnackbar("Invite Sent!", {variant: "success"});
				setIsLoading(false);
			} catch (error) {
				if (error.response) {
					enqueueSnackbar(error.response.data.message, {variant: "error"});
				} else {
					enqueueSnackbar("Failed to invite user.", {variant: "error"});
				}
				setIsLoading(false);
				console.log('Error', error.message);
			}
		}

	}

	return (
		<Form
			heading="Invite User"
			loading={isLoading}
		>
			<FormSection>
				<Grid container spacing={3} alignContent="center">
					<Grid item xs={12}>
						<Heading subheading="Please enter the user's details"/>
					</Grid>
					<Grid item xs={12} sm={6} md={4} lg={3} sx={{display: "flex"}}>
						<TextField
							error={!emailValid}
							label="Email"
							fullWidth
							variant="outlined"
							type="email"
							onChange={event => {
								if (event.target.value !== "" && event.target.value.indexOf("@") > 0) {
									setEmailValid(true);
									setEmail(event.target.value);
								} else {
									setEmailValid(false);
								}
							}}
						/>
					</Grid>
					<Grid item xs={12} sm={6} md={4} lg={3} sx={{display: "flex"}}>
						<TextField
							error={!tempPasswordValid}
							label="Password"
							fullWidth
							type="password"
							variant="outlined"
							onChange={event => {
								if (validatePassword(event.target.value)) {
									setTempPasswordValid(true);
									setTempPassword(event.target.value);
								} else {
									setTempPasswordValid(false);
								}
							}}
							helperText="Requires a number, uppercase letter, lowercase letter and at least 8 characters."
						/>
					</Grid>
					<Grid item xs={12}>
						<Box display="flex">
							<Box mr={3}>
								<Button
									size="large"
									color="primary"
									variant="contained"
									disabled={!emailValid || !tempPasswordValid || !tempPassword || !email}
									onClick={() => submit()}
								>
									Add User
								</Button>
							</Box>
							<Box mr={3}>
								<Button
									size="large"
									color="primary"
									variant="contained"
									disabled={!emailValid || !tempPasswordValid || !tempPassword || !email}
									onClick={() => submit(true)}
								>
									Add User as Admin
								</Button>
							</Box>
						</Box>
					</Grid>
				</Grid>
			</FormSection>
		</Form>
	)
}

export default InviteUserForm;