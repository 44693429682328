import {AuthenticationResult} from "../types/AuthResponse";
import {AuthAction} from "../reducers/AuthReducer";
import jwt_decode from "jwt-decode";
import IIdToken from "../types/IdToken";
import IAccessToken from "../types/AccessToken";

const REFRESH_TOKEN_STORAGE = "goterra_refresh_token";

export const loadRefreshToken = () => {
	return localStorage.getItem(REFRESH_TOKEN_STORAGE);
}

export const saveTokens = (tokens: AuthenticationResult, authDispatch: (dispatch: AuthAction) => void) => {
	// Persist the refresh token
	if (tokens.RefreshToken) {
		// If this is present, its a new sign in, otherwise its a refresh so no updates are needed
		localStorage.setItem(REFRESH_TOKEN_STORAGE, tokens.RefreshToken);
	}
	// Set the in-memory tokens
	authDispatch({
		type: "SET_TOKENS",
		data: {
			idToken: jwt_decode<IIdToken>(tokens.IdToken),
			accessToken: jwt_decode<IAccessToken>(tokens.AccessToken),
			accessTokenStr: tokens.AccessToken
		}
	})
}