import {Theme, useMediaQuery} from "@mui/material";
import React, {useContext} from "react";
import {MainSideNav} from "../navigation/MainSideNav";
import {Main} from "./LayoutStyles";
import NavDrawer from "../navigation/NavDrawer";
import {NavContext} from "../../shared/providers/NavProvider";
import {TodoLayout} from "../todo/TodoLayout";
import HeaderBar from "./HeaderBar";
import TodoDrawer from "../todo/TodoDrawer";

type Props = {
	children?: React.ReactNode
};

const navDrawerWidth = 220;
const todoDrawerWidth = 280;

const NavigationLayout = ({children}: Props) => {
	const [navState, navDispatch] = useContext(NavContext);
	const xlUp = useMediaQuery((theme: Theme) => theme.breakpoints.up("xl"));

	if (navState.disableNav) {
		return (<React.Fragment>{children}</React.Fragment>);
	}

	return (
		<React.Fragment>
			<HeaderBar/>
			<NavDrawer
				drawerWidth={navDrawerWidth}
				onClose={() => navDispatch({type: "CLOSE_NAV_MOBILE"})}
				onOpen={() => navDispatch({type: "OPEN_NAV_MOBILE"})}
				open={navState.navOpen}
				openMobile={navState.navOpenMobile}
			>
				<MainSideNav/>
			</NavDrawer>
			<Main
				leftDrawerWidth={navDrawerWidth}
				rightDrawerWidth={todoDrawerWidth}
				leftShift={navState.navOpen && !xlUp}
				rightShift={navState.todoOpen && !xlUp}
			>
				{children}
			</Main>
			<TodoDrawer
				drawerWidth={todoDrawerWidth}
				onClose={() => navDispatch({type: "CLOSE_TODO_MOBILE"})}
				open={navState.todoOpen}
				openMobile={navState.todoOpenMobile}
			>
				<TodoLayout/>
			</TodoDrawer>
		</React.Fragment>
	);
}

export default NavigationLayout;