import IAccessToken from "../types/AccessToken";
import IIdToken from "../types/IdToken";

export type AuthState = {
	idToken?: IIdToken;
	accessToken?: IAccessToken;
	accessTokenStr?: string;
	retrying?: boolean;
	invalid?: boolean;
};

export type AuthAction =
	| { type: "SET_TOKENS", data: { idToken: IIdToken, accessToken: IAccessToken, accessTokenStr: string } }
	| { type: "CLEAR_TOKENS" }
	| { type: "SET_RETRY" }

export const AuthReducer = (state: AuthState, action: AuthAction): AuthState => {
	switch (action.type) {
		case "SET_TOKENS":
			return {
				...state,
				retrying: false,
				invalid: false,
				...action.data
			}
		case "CLEAR_TOKENS":
			return {
				...state,
				idToken: undefined,
				accessToken: undefined,
				accessTokenStr: undefined,
				invalid: true
			}
		case "SET_RETRY":
			return {
				...state,
				retrying: true,
				invalid: false
			}
		default:
			return state;
	}
}
export const initialAuthState: AuthState = {};