import React, {createContext, useReducer} from "react";
import {initialNavState, NavAction, navReducer, NavState} from "../reducers/NavReducer";

export type NavContextType = [NavState, (action: NavAction) => void]

export const NavContext = createContext<NavContextType>([
	initialNavState,
	() => null
])

type NavProviderProps = {
	children?: React.ReactNode
}

export const NavProvider = ({children}: NavProviderProps) => {
	const [state, dispatch] = useReducer(navReducer, initialNavState);

	return (
		<NavContext.Provider value={[state, dispatch]}>
			{children}
		</NavContext.Provider>
	)
}