/**
 * Representation of transfer results when math has been performed
 */
import Config from "../types/Config";
import ITransfer, {IMaggotMath} from "../types/Transfer";

export interface TransferMath {
	maggotMath: IMaggotMath;
	breederTrays: number;
	feederTrays: number;
}

/**
 * Calculates all the generated values from the batch math from the config items and submitted transfer info
 * @param transfer The transfer data pending submission
 * @param config Link to the configs required for constants
 * @param eggsMg the amount of eggs in mg
 */
export const getTransferMath = (transfer: ITransfer, config: Config, eggsMg: number): TransferMath => {
	// Now we do the math

	// Get config values into constants so we cna go cross language like total geniuses
	const breederTrayDensity = config.breederTrayDensity
	const requiredBreederTrays = config.requiredBreederTrays
	const feederTrayDensity = config.feederTrayDensity
	const sampleSizeG = config.transferSampleSize

	// The batch stats
	const biomassMg = transfer.biomassG * 1000
	let neoInBatch = 0
	let hatchingRate = 0
	let percentNeoInSubstrate = 0
	if (!transfer.estimatedHatchingRate && transfer.neoSampleMg) {
		percentNeoInSubstrate = (transfer.neoSampleMg / 1000) / sampleSizeG
		neoInBatch = (biomassMg * percentNeoInSubstrate) / (transfer.neoIndivUg / 1000)
		hatchingRate = neoInBatch / (config.maggotsPerEgg * (eggsMg / 1000))
	} else if (transfer.estimatedHatchingRate) {
		hatchingRate = transfer.estimatedHatchingRate
		neoInBatch = hatchingRate * (config.maggotsPerEgg * (eggsMg / 1000))
		percentNeoInSubstrate = (neoInBatch * (transfer.neoIndivUg / 1000)) / biomassMg
	}


	// How much is needed for breeding trays
	const breederTraysPossible = neoInBatch / breederTrayDensity
	const numberBreedingTraysActual = breederTraysPossible < requiredBreederTrays
		? breederTraysPossible : requiredBreederTrays
	const substratePerBreederTrayMg = biomassMg / breederTraysPossible
	const totalBreedingSubstrateMg = numberBreedingTraysActual * substratePerBreederTrayMg

	// How many feeder trays we can make
	const feederMaggots = ((biomassMg - totalBreedingSubstrateMg) * percentNeoInSubstrate
	) / (transfer.neoIndivUg / 1000)
	const numberFeederTrays = Math.floor(feederMaggots / feederTrayDensity)
	let substratePerFeederTrayMg = (biomassMg - totalBreedingSubstrateMg) / numberFeederTrays
	let totalFeedingSubstrateMg = numberFeederTrays * substratePerFeederTrayMg

	substratePerFeederTrayMg = isNaN(substratePerFeederTrayMg) ? 0 : substratePerFeederTrayMg
	totalFeedingSubstrateMg = isNaN(totalFeedingSubstrateMg) ? 0 : totalFeedingSubstrateMg

	return {
		maggotMath: {
			percentNeoInSubstrate,
			neoInBatch,
			breederTraysPossible,
			substratePerBreederTrayMg,
			totalBreedingSubstrateMg,
			feederMaggots,
			substratePerFeederTrayMg,
			totalFeedingSubstrateMg,
			hatchingRate,
			breederTrayDensity,
			feederTrayDensity
		},
		breederTrays: numberBreedingTraysActual,
		feederTrays: numberFeederTrays
	}

}