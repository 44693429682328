import {Box, BoxProps, InputBase, InputBaseProps, styled} from "@mui/material";
import {classPrefix, MagOpsColors} from "../../shared/styles/MagOpsStyle";

export const appBarClasses = {
	logo: `${classPrefix}-logo`
}

export const AppBarTitle = styled("div")(({theme}) => ({
	flexGrow: 1,
	marginTop: -8
}))

interface MainProps extends BoxProps {
	leftShift: boolean;
	rightShift: boolean;
	leftDrawerWidth: number;
	rightDrawerWidth: number;
}

export const Main = styled(Box)<MainProps>(({leftDrawerWidth, rightDrawerWidth, leftShift, rightShift, theme}) => ({
	marginTop: theme.spacing(8),
	flexGrow: 1,
	padding: theme.spacing(1),
	display: "flex",
	flexDirection: "column",
	alignItems: "center",
	transition: theme.transitions.create('margin', {
		easing: theme.transitions.easing.sharp,
		duration: theme.transitions.duration.leavingScreen,
	}),
	...(leftShift && {
		[theme.breakpoints.up("md")]: {
			transition: theme.transitions.create('margin', {
				easing: theme.transitions.easing.easeOut,
				duration: theme.transitions.duration.enteringScreen,
			}),
			marginLeft: leftDrawerWidth,
		}
	}),
	...(rightShift && leftShift && {
		[theme.breakpoints.up("md")]: {
			transition: theme.transitions.create('margin', {
				easing: theme.transitions.easing.easeOut,
				duration: theme.transitions.duration.enteringScreen,
			}),
			marginRight: rightDrawerWidth,
			marginLeft: leftDrawerWidth,
		}
	}),
	[theme.breakpoints.up("lg")]: {
		marginLeft: leftDrawerWidth,
		marginRight: rightDrawerWidth,
	},
	[theme.breakpoints.up("xl")]: {
		marginLeft: 0,
		marginRight: 0
	}
}));

interface AppBarInputProps extends InputBaseProps {
	dark?: boolean
}

export const AppBarInput = styled(InputBase)<AppBarInputProps>(({dark, theme}) => ({
	"& .MuiInputBase-input": {
		borderBottom: "1px solid",
		paddingLeft: theme.spacing(1),
		border: "none",
		transition: theme.transitions.create(["color"]),
		...(dark && {
			color: "white",
		}),
		"&:focus": {
			backgroundColor: "#0000"
		},
	},
	"& .MuiSvgIcon-root": {
		color: MagOpsColors.primary.goterraSky,
		transition: theme.transitions.create(["color"]),
		...(dark && {
			color: "white",
		}),
	}
}));