import AxiosRequest from "./AxiosRequest"
import IStack from "../types/Stack";
import AbstractMagOpsApi from "./AbstractMagOpsApi";

class StackApi extends AbstractMagOpsApi {
	getAll(siteId?: number) {
		return AxiosRequest.get<IStack[]>("/stacks", {
			params: {
				...(siteId && {siteId: siteId})
			},
			signal: this.getRequestSignal()
		})
	}

	get(id: number) {
		return AxiosRequest.get<IStack>(`/stack/${id}`, {
			signal: this.getRequestSignal()
		})
	}

	create(data: { name: string, siteId: number }) {
		return AxiosRequest.post<IStack>("/stack", data)
	}

	delete(id: number) {
		return AxiosRequest.delete<{}>(`/stack/${id}`)
	}
}

export default new StackApi()