import React, {useState} from "react";
import {useSnackbar} from "notistack";
import MibApi from "../../shared/api/MibApi";
import {Box, Button, CircularProgress} from "@mui/material";
import {Fastfood, Restaurant} from "@mui/icons-material";
import {Link} from "react-router-dom";

type Props = {
	id: number;
	close?: () => void;
}

const MibListActions = ({id, close}: Props) => {
	const [isLoading, setIsLoading] = useState(false);
	const {enqueueSnackbar} = useSnackbar();

	const submit = async (event: any) => {
		event.stopPropagation();
		try {
			setIsLoading(true);
			await MibApi.feed(id);
			enqueueSnackbar(`Successfully fed MIB Service`, {variant: "success"})
			setIsLoading(false);

			if (close) {
				close();
			}
		} catch (error) {
			// Something happened in setting up the request that triggered an Error
			enqueueSnackbar(`Failed to feed MIB Service`, {variant: "error"})
			console.log('Error', error.message);
			setIsLoading(false);
		}
	}

	return (
		<React.Fragment>
			<Box display="flex" justifyContent="start" flexGrow={1}>
				<Link to={`/mibs/${id}/harvest`}>
					<Button
						color="primary"
						variant="contained"
						startIcon={<Restaurant/>}
					>
						Harvest
					</Button>
				</Link>
			</Box>
			<Box display="flex" justifyContent="start" flexGrow={1}>
				{isLoading
					?
					<CircularProgress size={24}/>
					:
					<Button
						color="primary"
						variant="contained"
						startIcon={<Fastfood/>}
						onClick={submit}
					>
						Feed
					</Button>
				}
			</Box>
		</React.Fragment>
	)
}

export default MibListActions;