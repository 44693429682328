import {Collapse, Grid} from "@mui/material";
import React from "react";
import {FormPaper} from "./FormStyles";

type Props = {
	children?: React.ReactNode | React.ReactNode[];
	show?: boolean;
	half?: boolean
}

const FormSection = ({children, show = true, half}: Props) => {
	return (
		<Grid item xs={12} md={half ? 6 : 12}>
			<Collapse in={show}>
				<FormPaper>
					{children}
				</FormPaper>
			</Collapse>
		</Grid>
	)
}

export default FormSection;