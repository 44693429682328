import {Autocomplete, InputAdornment, Typography} from "@mui/material";
import {FormValueCard, FormValueTextField} from "./FormStyles";
import React from "react";
import {onChangeNumber} from "../../shared/helpers";

type Props = {
	heading: string;
	valid: boolean;
	setValue?: (value: number) => void;
	setValueStr?: (value: string) => void;
	setValid: (value: boolean) => void;
	checkValid?: (value: number) => boolean;
	options?: Array<string>;
	units?: string;
	type?: string;
	helperText?: string;
	defaultValue?: number | string;
}

const ValueInputCard = ({
													heading,
													type,
													valid,
													setValue,
													setValid,
													checkValid,
													units,
													options,
													setValueStr,
													helperText,
													defaultValue
												}: Props) => {

	const onChange = (value: string) => {
		if (setValue) {
			onChangeNumber(value, setValue, setValid, checkValid);
		} else if (setValueStr) {
			const newValid = value.length > 0
			if (newValid) {
				setValueStr(value)
			}
			setValid(newValid);
		}
	}

	return (
		<FormValueCard variant="outlined" sx={{flexGrow: 1}}>
			<Typography component="p" variant="h6" align="center">
				{heading}
			</Typography>
			{
				options
					?
					<Autocomplete
						options={options}
						onChange={(event, value) => onChange(value ? value : "")}
						onInputChange={(event, value) => onChange(value ? value : "")}
						defaultValue={defaultValue as string}
						renderInput={params => <FormValueTextField
							{...params}
							error={!valid}
							fullWidth
							variant="standard"
						/>}
					/>
					:
					<FormValueTextField
						error={!valid}
						fullWidth
						variant="standard"
						onChange={event => onChange(event.target.value)}
						type={type}
						helperText={helperText}
						defaultValue={defaultValue}
						InputProps={{
							...(units && {
								endAdornment: <InputAdornment position="start" disableTypography>
									<Typography component="p" variant="h6">{units}</Typography>
								</InputAdornment>
							})
						}}
					/>
			}
		</FormValueCard>
	);
}

export default ValueInputCard