import Form from "../../../components/forms/Form";
import React, {useContext, useEffect, useState} from "react";
import {useHistory} from "react-router-dom";
import {NavContext} from "../../../shared/providers/NavProvider";
import ReportsApi from "../../../shared/api/ReportsApi";
import {addDays, format, subDays} from "date-fns";
import {useSnackbar} from "notistack";
import {DateRange} from "@mui/lab/DateRangePicker/RangeTypes";
import SingleDateAreaChart from "../../../components/dashboard/SingleDateAreaChart";
import IIncubatorReport from "../../../shared/types/IncubatorReport";
import {formatFloats} from "../../../shared/helpers";
import AlertsBanner from "../../../components/dashboard/AlertsBanner";
import IAlert from "../../../shared/types/Alert";
import AlertsApi from "../../../shared/api/AlertsApi";
import {ConfigContext} from "../../../shared/providers/ConfigProvider";
import axios from "axios";
import {CSVLink} from "react-csv";
import {Button} from "@mui/material";

const IncubatorDashboard = () => {
	const [navState] = useContext(NavContext);
	const [configState] = useContext(ConfigContext);
	const [report, setReport] = useState<IIncubatorReport>();
	const [isLoading, setIsLoading] = useState(true);
	const [alerts, setAlerts] = useState<Array<IAlert>>([]);
	const [dateRange, setDateRange] = React.useState<DateRange<Date>>(
		[subDays(new Date(), 7), addDays(new Date(), 5)] // Include the projected dates by default
	);
	const {enqueueSnackbar} = useSnackbar();

	let history = useHistory();

	useEffect(() => {
		(async () => {
			try {
				setIsLoading(true);
				if (navState.sites.length === 0 || !dateRange[0] || !dateRange[1]) {
					// Still waiting for sites to be populated
					return;
				}
				let response = await ReportsApi.getIncubator(navState.sites[navState.siteIndex].id, dateRange[0], dateRange[1]);
				setReport(response.data);
			} catch (error) {
				if (axios.isCancel(error)) {
					return;
				}
				// Something happened in setting up the request that triggered an Error
				enqueueSnackbar(`Failed to load incubator reports`, {variant: "error"})
				console.log('Error', error.message);
			}
		})()

		return () => ReportsApi.abort();
	}, [navState.sites, navState.siteIndex, enqueueSnackbar, history, dateRange])

	useEffect(() => {
		(async () => {
			try {
				setIsLoading(true);
				if (navState.sites.length === 0) {
					// Still waiting for sites to be populated
					return;
				}
				let response = await AlertsApi.get(navState.sites[navState.siteIndex].id, "incubator");
				setAlerts(response.data);
			} catch (error) {
				if (axios.isCancel(error)) {
					return;
				}
				// Something happened in setting up the request that triggered an Error
				enqueueSnackbar(`Failed to load incubator alerts`, {variant: "error"})
				console.log('Error', error.message);
			}
		})()

		return () => AlertsApi.abort();
	}, [navState.sites, navState.siteIndex, enqueueSnackbar, history])

	useEffect(() => {
		if (report) {
			setIsLoading(false);
		}
	}, [report])

	return (
		<Form
			heading={"Incubator Dashboard"}
			dateRangeProps={{
				dateRange: dateRange,
				maxDate: addDays(new Date(), 5),
				setDateRange: setDateRange
			}}
			loading={!report}
		>
			<AlertsBanner alerts={alerts}/>
			{report?.feederTrays &&
				<SingleDateAreaChart
					charts={[
						{key: 0, name: "Trays", color: "yellow"},
						{key: 1, name: "Forecasted Trays", color: "mauve"}
					]}
					data={report.feederTrays.map(value => ({
						date: format(new Date(value.time), "dd/MM/yyyy"),
						0: value.trays,
						1: value.projected,
					}))}
					heading="Number of Feeder Trays"
					loading={isLoading}
				>
					<CSVLink
						data={report.feederTrays.map(feeders => ({
							Date: format(new Date(feeders.time), "dd/MM/yyyy"),
							"Trays": (feeders.trays || 0),
							"Forecasted Trays": feeders.projected,
							"Batch": feeders.eggsBatch,
						}))}
						filename={`MagOps_Feeder_Trays_Report_${format(dateRange[0] || 0, "dd/MM/yyyy")}-${format(dateRange[1] || 0, "dd/MM/yyyy")}`}
					>
						<Button color="primary" variant="contained" size="large">
							Download Data
						</Button>
					</CSVLink>
				</SingleDateAreaChart>
			}
			{report?.hatchingRate &&
				<SingleDateAreaChart
					charts={[
						{key: 0, name: "Hatching Rate (%)", color: "mauve"}
					]}
					data={report.hatchingRate.map(value => ({
						date: format(new Date(value.time), "dd/MM/yyyy"),
						0: value.rate !== null ? +formatFloats(value.rate * 100) : null,
					}))}
					heading="Hatching Rate"
					loading={isLoading}
					target={{
						name: `Hatching Rate Minimum: ${configState.config?.hatchingRateThreshold}%`,
						value: 50
					}}
				>
					<CSVLink
						data={report.hatchingRate.map(rate => ({
							Date: format(new Date(rate.time), "dd/MM/yyyy"),
							"Hatching Rate (%)": +formatFloats((rate.rate || 0) * 100),
							"Batch": rate.batch,
						}))}
						filename={`MagOps_Hatching_Rate_Report_${format(dateRange[0] || 0, "dd/MM/yyyy")}-${format(dateRange[1] || 0, "dd/MM/yyyy")}`}
					>
						<Button color="primary" variant="contained" size="large">
							Download Data
						</Button>
					</CSVLink>
				</SingleDateAreaChart>
			}
		</Form>
	)
}

export default IncubatorDashboard;