import React from 'react';
import {CssBaseline, ThemeProvider} from "@mui/material";
import {MagOpsTheme} from "./shared/styles/MagOpsStyle";
import Router from './components/navigation/Router';
import {SnackbarProvider} from "notistack";
import {NavProvider} from "./shared/providers/NavProvider";
import {TodoProvider} from "./shared/providers/TodoProvider";
import {ConfigProvider} from "./shared/providers/ConfigProvider";
import {LocalizationProvider} from "@mui/lab";
import DateAdapter from '@mui/lab/AdapterDateFns';
import {AuthProvider} from "./shared/providers/AuthProvider";


require("dotenv").config();

const App = () => {


	return (
		<ThemeProvider theme={MagOpsTheme}>
			<LocalizationProvider dateAdapter={DateAdapter}>
				<SnackbarProvider autoHideDuration={3000} preventDuplicate={true}>
					<AuthProvider>
						<NavProvider>
							<TodoProvider>
								<ConfigProvider>
									<CssBaseline/>
									<Router/>
								</ConfigProvider>
							</TodoProvider>
						</NavProvider>
					</AuthProvider>
				</SnackbarProvider>
			</LocalizationProvider>
		</ThemeProvider>
	);
}

export default App;
