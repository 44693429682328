import React, {useContext, useEffect, useState} from "react";
import {Redirect, Route, RouteProps} from "react-router-dom";
import {NavContext} from "../../shared/providers/NavProvider";
import {AuthContext} from "../../shared/providers/AuthProvider";
import {checkGroups} from "../../shared/helpers";
import {loadRefreshToken} from "../../shared/helpers/LocalStorageHelper";
import Loading from "../layout/Loading";

interface PrivateRouteProps extends RouteProps {
	admin?: boolean;
}

const PrivateRoute = ({children, admin, ...rest}: PrivateRouteProps) => {
	const [navState, navDispatch] = useContext(NavContext);
	const [authState,] = useContext(AuthContext);
	const [loading, setLoading] = useState(true);

	useEffect(() => {
		if (navState.disableNav) {
			navDispatch({type: "SET_NAV_DISABLED", data: false});
		}
	}, [navState.disableNav, navDispatch])

	useEffect(() => {
		if (!authState.idToken) {
			// We don't have a way to checking if the user can see this page, set loading and get refreshing
			const refreshToken = loadRefreshToken();
			if (!refreshToken) {
				setLoading(false);
			} else {
				setLoading(true);
			}
		} else {
			setLoading(false);
		}
	}, [authState.idToken])

	useEffect(() => {
		if (authState.invalid) {
			// The auth is invalid, abort loading
			setLoading(false);
		}
	}, [authState.invalid])

	const groups = admin ? ["Admin"] : []

	return (
		<Route
			{...rest}
			render={({location}) =>
				loading
					?
					(<Loading loading={loading}/>)
					:
					// TODO make it so we can test groups in local dev
					(authState.idToken && (checkGroups(authState.idToken, groups) || process.env.NODE_ENV === "development")) ||
					(!process.env.NODE_ENV || process.env.NODE_ENV === "development") // Dev config
						?
						(children)
						:
						(<Redirect to={{
							pathname: "/login",
							state: {from: location}
						}}/>)
			}
		/>
	);
}

export default PrivateRoute;