import ValueCard from "../forms/ValueCard";
import FormOutputs from "../forms/FormOutputs";
import React from "react";
import IAlert from "../../shared/types/Alert";

type Props = {
	alerts?: IAlert[];
	heading?: string;
}

const AlertsBanner = ({alerts, heading}: Props) => {

	if (alerts && alerts.length > 0) {
		return (
			<FormOutputs header={heading}>
				{alerts.map(alert => (
					<ValueCard
						heading={alert.alert}
						value={alert.value}
						color={alert.status === "none" ? "primary" : alert.status}
						variant="elevation"
					/>
				))}
			</FormOutputs>
		)
	} else {
		return null
	}
}

export default AlertsBanner;

