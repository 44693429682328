import TaskApi from "./api/TaskApi";
import {NavState} from "./reducers/NavReducer";
import {TodoAction} from "./reducers/TodoReducer";
import IIdToken from "./types/IdToken";

/**
 * Sets the value and validity of a numeric field from a string input
 * @param entry the value that has been updated
 * @param setValue the state value to update
 * @param setValid the state validity value to update
 * @param checkValid the extra optional validity check
 */
export const onChangeNumber = (entry: string, setValue: (value: number) => void, setValid: (value: boolean) => void,
															 checkValid?: (value: number) => boolean) => {
	// Update the state for validation
	const value: number = Number(entry);
	if (!isNaN(value) && value >= 0) {
		setValue(value)
		setValid(checkValid ? checkValid(value) : true)
	} else {
		setValid(false)
	}
}
/**
 * Sets the value and validity of a numeric field from a string input
 * @param entry the value that has been updated
 * @param setValue the state value to update
 * @param setValid the state validity value to update
 * @param checkValid the extra optional validity check
 */
export const onChangeNumberOrArray = (entry: string, setValue: (value: number | number[]) => void, setValid: (value: boolean) => void,
																			checkValid?: (value: number | number[]) => boolean) => {
	// Update the state for validation
	const value: number = Number(entry);
	if (!isNaN(value) && value >= 0) {
		setValue(value)
		setValid(checkValid ? checkValid(value) : true)
	} else {
		// Check if it is a valid numeric array
		const arrayVal: number[] = entry.split(",").map(str => Number(str))
		if (arrayVal.length <= 0 || arrayVal.filter(num => isNaN(num)).length > 0) {
			setValid(false)
		} else {
			setValid(true)
			setValue(arrayVal)
		}
	}
}

export const refreshTodo = async (navState: NavState, todoDispatch: (dispatch: TodoAction) => void) => {
	try {
		if (navState.sites.length > 0) {
			todoDispatch({type: "SET_LOADING"});
			let response = await TaskApi.get(navState.sites[navState.siteIndex].id);
			todoDispatch({
				type: "SET_TASKS",
				data: response.data
			});
		}
	} catch (e) {
		console.log(e)
	}
}

export const formatFloats = (num: number): string => {
	return `${+num.toFixed(2)}`
}

export const formatInt = (num: number): string => {
	return num.toFixed(0)
}

export const nowTime = (date: Date): Date => {
	let nowish = new Date()
	nowish.setFullYear(date.getFullYear())
	nowish.setMonth(date.getMonth())
	nowish.setDate(date.getDate())
	return nowish;
}

export const dateOnly = (date: Date): Date => {
	let dateOnly = new Date()
	dateOnly.setFullYear(date.getFullYear())
	dateOnly.setMonth(date.getMonth())
	dateOnly.setDate(date.getDate())
	dateOnly.setHours(0, 0, 0, 0);
	return dateOnly;
}

export const formatDate = (date: Date): string => {
	const time = new Date(date.toLocaleString('en-US', {timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone}))
	const flooredDate = dateOnly(time);
	return flooredDate.toISOString();
}

export const validatePassword = (password: string) => {
	if (password.length < 8) {
		return false;
	}
	const hasUpperCase = /[A-Z]/.test(password);
	const hasLowerCase = /[a-z]/.test(password);
	const hasNumbers = /\d/.test(password);
	return hasUpperCase && hasLowerCase && hasNumbers;
}

export const checkGroups = (idToken: IIdToken, groups: Array<string>): boolean => {
	return groups.reduce<boolean>((acc, current) => acc && idToken["cognito:groups"] !== undefined &&
		idToken["cognito:groups"].includes(current), true)
}