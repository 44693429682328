import AxiosRequest from "./AxiosRequest"
import AbstractMagOpsApi from "./AbstractMagOpsApi";
import IGrowRoomSample from "../types/GrowRoomSample";

class GrowRoomSampleApi extends AbstractMagOpsApi {

	create(data: IGrowRoomSample) {
		return AxiosRequest.post<IGrowRoomSample>("/grow_room_sample", data)
	}
}

export default new GrowRoomSampleApi()