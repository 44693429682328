import * as React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogTitle from '@mui/material/DialogTitle';
import MibListActions from "./MibListActions";

type Props = {
	close: () => void;
	id?: number;
}

const AlertDialog = ({close, id}: Props) => {
	const handleClose = () => {
		close();
	};

	return (
		<Dialog
			open={id !== undefined}
			onClose={handleClose}
		>
			<DialogTitle>
				Select MIB Action
			</DialogTitle>
			<DialogActions>
				{id &&
					<MibListActions id={id} close={close}/>
				}
			</DialogActions>
		</Dialog>
	);
}

export default AlertDialog;
