const LOADING_MESSAGES = [
	"Generating witty dialog...",
	"Swapping time and space...",
	"Spinning violently around the y-axis...",
	"Filtering morale...",
	"Don't think of purple maggots...",
	"Have a good day.",
	"...and enjoy the elevator music...",
	"Please wait while the elves draw your map",
	"Don't worry - a few maggots tried to escape, but we caught them",
	"Would you like fries with that?",
	"Go ahead -- hold your breath!",
	"...at least you're not on hold...",
	"Hum something loud while others stare",
	"The server is running on a potato",
	"We're testing your patience",
	"Why don't you order a coffee?",
	"Dig on the 'X' for buried treasure... ARRR!",
	"The last time I tried this the monkey didn't survive. Let's hope it works better this time.",
	"My other loading screen is much faster.",
	"Are we there yet?",
	"Just count to 10",
	"Why so serious?",
	"It's not you. It's me.",
	"Counting backwards from Infinity",
	"Don't panic...",
	"Warning: Don't set yourself on fire.",
	"We're making you a cookie.",
	"Spinning the wheel of fortune...",
	"Loading the enchanted maggot...",
	"Computing chance of success",
	"All your web browser are belong to us",
	"I feel like im supposed to be loading something. . .",
	"What do you call 8 Hobbits? A Hobbyte.",
	"Please wait until the sloth starts moving.",
	"Don't break your screen yet!",
	"The cake is not a lie...",
	"Time flies when you’re having fun.",
	"Sorry, cat walked across the keybo--dwawdauiul3n,cas",
	"Spinning the hamster...",
	"Stay awhile and listen..",
	"Convincing AI not to turn evil..",
	"Computing the secret to life, the universe, and everything.",
	"Constructing additional pylons...",
	"We are not liable for any broken screens as a result of waiting.",
	"Hello IT, have you tried turning it off and on again?",
	"If you type Google into Google you can break the internet",
	"Hello, IT... Have you tried forcing an unexpected reboot?",
	"Didn't know paint dried so quickly.",
	"Spawn more Overlords!",
	"If I’m not back in five minutes, just wait longer.",
	"We’re going to need a bigger boat.",
	"Looking for sense of humour, please hold on.",
	"Kindly hold on as we convert this bug to a feature...",
	"Distracted by cat gifs",
	"Finding someone to hold my beer",
	"Whatever you do, don't look behind you...",
	"Loading funny message...",
	"Feel free to spin in your chair",
	"Please wait, while we purge the Decepticons for you. Yes, You can thanks us later!",
	"Downloading more RAM..",
	"Never let a computer know you're in a hurry.",
	"Some things man was never meant to know. For everything else, there's Google.",
	"Unix is user-friendly. It's just very selective about who its friends are.",
	"Shovelling coal into the server",
	"How about this weather, eh?",
	"Everything in this universe is either a potato or not a potato",
	"Live long and prosper.",
	"You may call me Steve.",
	"You seem like a nice person...",
	"Discovering new ways of making you wait...",
	"Your time is very important to us. Please wait while we ignore you...",
	"Time flies like an arrow; fruit flies like a banana",
	"Sooooo... Have you seen my cat photos yet?",
	"Sorry we are busy catching em' all, we're done soon",
	"TODO: Insert elevator music",
	"Still faster than Windows update",
	"Please wait while the maggots do their work",
	"Grabbing extra maggots",
	"We're working very Hard .... Really",
	"Waking up the maggots",
	"Feeding unicorns...",
	"Don't mind me, just loading...",
	"Fetching the maggots, please wait...",
	"Busy chasing maggots out of the server...",
	"I had one of those... the wheels fell off",
	"Spawning more Overlords...",
	"MagOps 2: Electric Boogaloo",
];

export default LOADING_MESSAGES;