import {Link} from "react-router-dom";
import {CardActionArea, CardHeader} from "@mui/material";
import {CheckCircle, Error, Warning} from "@mui/icons-material";
import {StyledTodoCard} from "./TodoStyles";
import React from "react";
import IProductionTask from "../../shared/types/ProductionTask";
import {Lifecycle} from "../../shared/api/BatchApi";

export type TodoCardProps = {
	task: IProductionTask;
	link: string;
}

const localiseTaskType = (type: Lifecycle): string => {
	switch (type) {
		case "Transfer":
			return "Transfer Batch";
		case "Eggs":
			return "Collect Eggs";
		case "GrowRoomSample":
			return "Take Sample";
		case "GrowRoomFeed":
			return "Manufacture Trays";
		case "Sifting":
			return "Sift Batch";
		case "PupaeIntro":
			return "Introduce Pupae";
		case "HatchingTrays":
			return "Create Egg Hatching Trays";
		case "PupaeIntroPrep":
			return "Prepare Pupae for Aviaries";
	}
}

const TodoCard = ({task, link}: TodoCardProps) => {
	// const timeDue = new Date(task.timeDue);

	return (
		<StyledTodoCard status={task.dependsOn ? "Blocked" : task.status} key={task.id}>
			<Link to={{
				pathname: task.status === "Done" || task.dependsOn ? `/batches/${task.batchId}` : `${link}`
			}}>
				<CardActionArea disabled={!!task.dependsOn}>
					<CardHeader
						avatar={
							task.status === "Done" ? <CheckCircle color="success"/> :
								task.status === "Optional" ? <Warning color="warning"/> :
									task.dependsOn ? <Error color="info"/> :
										task.status === "Overdue" ? <Error color="error"/> :
											<Error color="primary"/>
						}
						title={localiseTaskType(task.type)}
						titleTypographyProps={{
							variant: "h6",
							color: task.dependsOn ? "info" : "primary"
						}}
						subheader={`Batch ${task.localBatchId.toString().padStart(4, "0")}`}
					/>
				</CardActionArea>
			</Link>
		</StyledTodoCard>
	)
}

export default TodoCard;