import {createTheme} from "@mui/material";

export const classPrefix = "MagOps";

export const MagOpsColors = {
	primary: {
		goterraSky: "#1a275b",
		duskBlue: "#dcdfea",
		darkerDusk: "#99a4cc",
		periwinkleBlue: "#6174d1",
		maggotGrey: "#979797",
		maggotLightGrey: "#F0F0F0",
		maggotGreyText: "#808080"
	},
	secondary: {
		forestGreen: "#5a9850",
		sunsetOrange: "#ef9478",
		lemonYellow: "#ffc549",
		coralRed: "#d86e6e",
		maggotBlood: "#c63f46",
		marvelousMauve: "#cf9cd8",
		perplexingPink: "#d86ab5",
		electricPurple: "#8300ff",
	}
}

export const MagOpsTheme = createTheme({
	palette: {
		primary: {
			main: MagOpsColors.primary.goterraSky,
		},
		secondary: {
			main: "#FFF",
			contrastText: MagOpsColors.primary.goterraSky
		},
		success: {
			main: MagOpsColors.secondary.forestGreen,
		},
		error: {
			main: MagOpsColors.secondary.coralRed,
		},
		warning: {
			main: MagOpsColors.secondary.sunsetOrange,
			contrastText: "#fff"
		},
		info: {
			main: MagOpsColors.primary.maggotGrey,
		}
	},
	typography: {
		h1: {
			color: MagOpsColors.primary.goterraSky,
			fontWeight: "bold",
			fontSize: "2rem"
		},
		h2: {
			color: MagOpsColors.primary.goterraSky,
			fontWeight: "bold",
			fontSize: "1.4rem"
		},
		h3: {
			color: MagOpsColors.primary.goterraSky,
			fontWeight: "bold",
			fontSize: "1.1rem"
		},
		h4: {
			color: MagOpsColors.primary.maggotGreyText,
			fontSize: "1rem",
		},
		h5: {
			color: MagOpsColors.primary.goterraSky,
			fontWeight: "bold",
		},
		h6: {
			fontWeight: "bold",
			fontSize: "1.05rem"
		}
	},
	breakpoints: {
		values: {
			xs: 0,
			sm: 600,
			md: 900,
			lg: 1366,
			xl: 1800
		}
	},
	components: {
		MuiUseMediaQuery: {
			defaultProps: {
				noSsr: true,
			},
		},
		MuiCssBaseline: {
			styleOverrides: {
				// body: {
				//     backgroundColor: MagOpsColors.primary.duskBlue,
				// },
				"a:active": {
					color: "inherit",
					textDecoration: "none"
				},
				"a:hover": {
					color: "inherit",
					textDecoration: "none"
				},
				"a:visited": {
					color: "inherit",
					textDecoration: "none"
				},
				"a:link": {
					color: "inherit",
					textDecoration: "none"
				}
			},
		},
	},
})