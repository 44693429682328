import AxiosRequest from "./AxiosRequest"
import IMibHarvest from "../types/MibHarvest";
import AbstractMagOpsApi from "./AbstractMagOpsApi";

class MibHarvestApi extends AbstractMagOpsApi {
	create(data: IMibHarvest) {
		return AxiosRequest.post<IMibHarvest>("/mib_harvest", data)
	}
}

export default new MibHarvestApi()