import {Autocomplete, Button, Grid, Typography} from "@mui/material";
import React, {useContext, useEffect, useState} from "react";
import FormSection from "./FormSection";
import Heading from "./Heading";
import ValueInputCard from "./ValueInputCard";
import ISite from "../../shared/types/Site";
import {FormValueCard, FormValueTextField} from "./FormStyles";
import ICustomerSite, {BinType} from "../../shared/types/CustomerSite";
import {NavContext} from "../../shared/providers/NavProvider";

type Props = {
	show?: boolean;
	header: string;
	submit: (site: ISite, customerSite: ICustomerSite, binType: BinType, bins: number, wasteG: number, notes?: string) => void;
	customerSite: ICustomerSite;
}

const Waste = ({submit, show, header, customerSite}: Props) => {
	const [navState] = useContext(NavContext);
	const [bins, setBins] = useState<number>();
	const [binsValid, setBinsValid] = useState<boolean>(false);
	const [wasteKg, setWasteKg] = useState<number>();
	const [wasteKgValid, setWasteKgValid] = useState<boolean>(false);
	const [notes, setNotes] = useState<string>();
	const [site, setSite] = useState<ISite | null>(navState.sites.filter(s => s.id === customerSite.siteId)[0]);
	const [binType, setBinType] = useState<string | null>(customerSite.binType);

	useEffect(() => {
		setBinType(customerSite.binType);
		setSite(navState.sites.filter(s => s.id === customerSite.siteId)[0]);
	}, [customerSite, navState])

	return (
		<FormSection show={show}>
			<Grid container spacing={3} justifyContent="center" alignContent="center">
				<Grid item xs={12}>
					<Heading subheading={header}/>
				</Grid>
				<Grid item xs={12} sm={6} md={4} lg={3} sx={{display: "flex"}}>
					<FormValueCard variant="outlined" style={{flexGrow: 1}}>
						<Typography component="p" variant="h6" align="center">
							Select Goterra Facility
						</Typography>
						<Autocomplete
							value={site}
							options={navState.sites}
							onChange={(event, newValue) => {
								setSite(newValue);
							}}
							getOptionLabel={option => option.location}
							renderInput={(params) => (
								<FormValueTextField
									{...params}
									variant="standard"
								/>
							)}
						/>
					</FormValueCard>
				</Grid>
				<Grid item xs={12} sm={6} md={4} lg={3} sx={{display: "flex"}}>
					<FormValueCard variant="outlined" style={{flexGrow: 1}}>
						<Typography component="p" variant="h6" align="center">
							Select Bin Type
						</Typography>
						<Autocomplete
							value={binType}
							options={["Bin240L", "Bin120L", "Bucket", "None"]}
							onChange={(event, newValue) => {
								setBinType(newValue);
							}}
							getOptionLabel={option => option}
							renderInput={(params) => (
								<FormValueTextField
									{...params}
									variant="standard"
								/>
							)}
						/>
					</FormValueCard>
				</Grid>
				{binType && binType !== "None" &&
					<Grid item xs={12} sm={6} md={4} lg={3} sx={{display: "flex"}}>
						<ValueInputCard
							heading={"Bins"}
							valid={binsValid}
							setValue={setBins}
							setValid={setBinsValid}
						/>
					</Grid>
				}
				<Grid item xs={12} sm={6} md={4} lg={3} sx={{display: "flex"}}>
					<ValueInputCard
						heading={"Waste (Kg)"}
						valid={wasteKgValid}
						setValue={setWasteKg}
						setValid={setWasteKgValid}
					/>
				</Grid>
				<Grid item xs={12} md={8} sx={{display: "flex"}}>
					<ValueInputCard
						heading={"Notes"}
						helperText={"Optional"}
						setValueStr={setNotes}
						valid
						setValid={() => {
						}}
					/>
				</Grid>
				<Grid item xs={12}>
					<Button
						size="large"
						color="primary"
						variant="contained"
						disabled={!(binsValid || binType === "None") || !wasteKgValid || site === null || binType === null}
						onClick={() => {
							if (site !== null && binType !== null && wasteKg) {
								submit(site, customerSite, binType as BinType, bins ? bins : 0, Math.round(wasteKg * 1000), notes) // This should be safe enough
							}
						}}
					>
						{header}
					</Button>
				</Grid>
			</Grid>
		</FormSection>

	)
}

export default Waste;