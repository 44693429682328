import {useContext, useEffect, useState} from "react";
import AxiosRequest from "../api/AxiosRequest";
import {loadRefreshToken, saveTokens} from "./LocalStorageHelper";
import AuthApi from "../api/AuthApi";
import {AuthContext} from "../providers/AuthProvider";
import {useHistory} from "react-router-dom";

const useInterceptors = () => {
	const [authState, authDispatch] = useContext(AuthContext);
	const [authInterceptor, setAuthInterceptor] = useState<number>();
	let history = useHistory();

	useEffect(() => {
		const interceptor = AxiosRequest.interceptors.response.use(async resp => (resp), async (error) => {
			if (error.response) {
				if (error.config && error.response.status === 401) {
					const refreshToken = loadRefreshToken();
					if (!authState.retrying) {
						authDispatch({type: "SET_RETRY"});
						try {
							const response = await AuthApi.refreshToken(refreshToken);
							saveTokens(response.data.AuthenticationResult, authDispatch);
							if (authInterceptor) {
								AxiosRequest.interceptors.response.eject(authInterceptor);
							}
							setAuthInterceptor(AxiosRequest.interceptors.request.use(config => {
								if (config && config.headers) {
									config.headers['Authorization'] = response.data.AuthenticationResult.AccessToken;
								}
								return config;
							}));
							error.config.headers["Authorization"] = response.data.AuthenticationResult;
							return AxiosRequest(error.config);
						} catch (error) {
							authDispatch({type: "CLEAR_TOKENS"});
						}
					}
				} else if (error.response.status === 404) {
					history.push("/not-found");
				}
			}
			return Promise.reject(error);
		});
		return () => {
			AxiosRequest.interceptors.response.eject(interceptor);
			if (authInterceptor) {
				AxiosRequest.interceptors.response.eject(authInterceptor);
			}
		}
	}, [history, authDispatch, authState.retrying, authInterceptor])
}

export default useInterceptors;