import React, {useContext, useEffect, useState} from "react";
import {useSnackbar} from "notistack";
import {useHistory, useParams} from "react-router-dom";
import Form from "../../components/forms/Form";
import BatchApi from "../../shared/api/BatchApi";
import FormOutputs from "../../components/forms/FormOutputs";
import ValueCard from "../../components/forms/ValueCard";
import {formatFloats, formatInt} from "../../shared/helpers";
import AviaryChart from "../../components/dashboard/AviaryChart";
import BatchProgress from "../../components/dashboard/BatchProgress";
import axios from "axios";
import IBatch from "../../shared/types/Batch";
import IEggs from "../../shared/types/Eggs";
import {ConfigContext} from "../../shared/providers/ConfigProvider";
import IPupaeIntro from "../../shared/types/PupaeIntro";
import {CSVLink} from "react-csv";
import {Box, Button, Checkbox, FormControlLabel, Typography} from "@mui/material";

interface BatchSummaryParams {
	batchId?: string;
}

const BatchSummary = () => {
	const [batch, setBatch] = useState<IBatch>();
	const [isLoading, setIsLoading] = useState(true)
	const [configState] = useContext(ConfigContext);
	const [date, setDate] = useState<Date>(new Date());
	const {enqueueSnackbar} = useSnackbar();
	const [fgs, setFgs] = useState<boolean>(true);

	let history = useHistory();
	let {batchId} = useParams<BatchSummaryParams>()

	useEffect(() => {
		(async () => {
			try {
				setIsLoading(true);
				if (batchId !== undefined && !isNaN(parseInt(batchId))) {
					const id = parseInt(batchId);
					let response = await BatchApi.get(id, undefined, true);
					setBatch(response.data);
				}
			} catch (error) {
				if (axios.isCancel(error)) {
					return;
				}
				// Something happened in setting up the request that triggered an Error
				enqueueSnackbar(`Failed to load batch with id ${batchId}`, {variant: "error"})
				console.log('Error', error.message);
			}
		})()

		return () => BatchApi.abort();
	}, [batchId, enqueueSnackbar, history])

	useEffect(() => {
		if (batch) {
			setDate(new Date(batch.time))
			setIsLoading(false);
		}
	}, [batch])

	return (
		<Form
			heading="Batch Overview"
			subheading={`Batch ${batch?.localId.toString().padStart(4, "0")}`}
			loading={isLoading}
		>
			{batch &&
				<BatchProgress batch={batch} date={date}/>
			}
			{batch?.eggs &&
				<AviaryChart
					title="Eggs Collected by Aviary (g)"
					subtitle={`Total: ${formatFloats(batch.eggs.filter(value => !fgs ? value.aviary?.name !== "Future Green Solutions" : true).reduce((accum: number, egg: IEggs) => accum + egg.eggsMg, 0) / 1000)}g`}
					data={batch.eggs.filter(value => !fgs ? value.aviary?.name !== "Future Green Solutions" : true).map(value => ({
						name: value.aviary ? value.aviary.name : value.aviaryId.toString(),
						value: value.eggsMg / 1000
					}))}
				>
					<CSVLink
						data={batch.eggs.map(eggs => ({
							Aviary: eggs.aviary?.name,
							"Eggs (g)": (eggs.eggsMg || 0) / 1000,
						}))}
						filename={`MagOps_Eggs_Collected_Batch_${batch?.localId.toString().padStart(4, "0")}`}
					>
						<Button color="primary" variant="contained" size="large">
							Download Data
						</Button>
					</CSVLink>
					<Box marginX={2}>
						<FormControlLabel control={<Checkbox checked={fgs} onChange={() => setFgs(!fgs)}/>}
															label={<Typography variant={"h4"}>Show FGS</Typography>}/>
					</Box>
				</AviaryChart>
			}
			{batch?.pupaeIntro &&
				<AviaryChart
					title="Biomass Introduced by Aviary (kg)"
					subtitle={`Total: ${formatFloats(batch.pupaeIntro.reduce((accum: number, bio: IPupaeIntro) => accum + bio.biomassG, 0) / 1000)}kg`}
					data={batch.pupaeIntro.map(value => ({
						name: value.aviary ? value.aviary.name : value.aviaryId.toString(),
						value: value.biomassG / 1000
					}))}
				>
					<CSVLink
						data={batch.pupaeIntro.map(pupaeIntro => ({
							Aviary: pupaeIntro.aviary?.name,
							"Biomass (kg)": (pupaeIntro.biomassG || 0) / 1000,
						}))}
						filename={`MagOps_Biomass_Introduced_Batch_${batch?.localId.toString().padStart(4, "0")}`}
					>
						<Button color="primary" variant="contained" size="large">
							Download Data
						</Button>
					</CSVLink>
				</AviaryChart>
			}
			{batch?.transfers && batch.transfers[0].transferMath &&
				<FormOutputs header="Batch Stats">
					<ValueCard
						heading="Total Biomass"
						value={formatFloats(batch.transfers[0].biomassG / 1000) + "kg"}
					/>
					<ValueCard
						heading="% Neonates in Substrate"
						value={formatFloats(batch.transfers[0].transferMath.percentNeoInSubstrate * 100) + "%"}
					/>
					<ValueCard
						heading="# Neonates in Batch"
						value={formatInt(batch.transfers[0].transferMath.neoInBatch)}
					/>
					<ValueCard
						heading="Hatching Rate"
						value={formatFloats(batch.transfers[0].transferMath.hatchingRate * 100) + "%"}
					/>
				</FormOutputs>
			}
			{batch?.transfers && batch.transfers[0].transferMath &&
				<FormOutputs header="Breeders">
					<ValueCard
						heading="Possible Breeder Trays"
						value={formatInt(batch.transfers[0].transferMath.breederTraysPossible)}
					/>
					<ValueCard
						heading="Substrate per Breeder Tray"
						value={formatFloats(batch.transfers[0].transferMath.substratePerBreederTrayMg / 1000) + "g"}
					/>
					<ValueCard
						heading="Total Breeder Substrate"
						value={formatFloats(batch.transfers[0].transferMath.totalBreedingSubstrateMg / 1000) + "g"}
					/>
				</FormOutputs>
			}
			{batch?.transfers && batch.transfers[0].transferMath &&
				<FormOutputs header="Feeders">
					<ValueCard
						heading="Feeder Larvae"
						value={formatInt(batch.transfers[0].transferMath.feederMaggots)}
					/>
					<ValueCard
						heading="Substrate per Feeder Tray"
						value={formatFloats(batch.transfers[0].transferMath.substratePerFeederTrayMg / 1000) + "g"}
					/>
					<ValueCard
						heading="Total Feeder Substrate"
						value={formatFloats(batch.transfers[0].transferMath.totalFeedingSubstrateMg / 1000) + "g"}
					/>
				</FormOutputs>
			}
			{batch?.transfers && batch.transfers[0].transferMath &&
				<FormOutputs header="Transfer Batch Tray Outputs">
					{batch?.transfers && batch.transfers[0].breederTrays &&
						<ValueCard heading="Breeder Trays" value={formatInt(batch.transfers[0].breederTrays)}/>}
					{configState.config?.breederTrayDensity &&
						<ValueCard heading="Breeder Tray Density"
											 value={formatInt(batch.transfers[0].transferMath.breederTrayDensity)}/>}
					{batch?.transfers && batch.transfers[0].feederTrays &&
						<ValueCard heading="Feeder Trays" value={formatInt(batch.transfers[0].feederTrays)}/>}
					{configState.config?.feederTrayDensity &&
						<ValueCard heading="Feeder Tray Density"
											 value={formatInt(batch.transfers[0].transferMath.feederTrayDensity)}/>}
				</FormOutputs>
			}
			{batch?.siftings &&
				<FormOutputs header="Sifting">
					<ValueCard
						heading="Frass %"
						value={`${formatFloats(batch.siftings[0].frassPercentage)}%`}
					/>
					<ValueCard
						heading="Total Biomass"
						value={`${formatFloats(batch.siftings[0].biomassG / 1000)}kg`}
					/>
					<ValueCard
						heading="Pre-Pupae Weight"
						value={`${formatFloats(batch.siftings[0].prePupaeWeightMg)}mg`}
					/>
				</FormOutputs>
			}
			{batch?.pupaeIntroPreps &&
				<FormOutputs header="Prepare Pupae for Aviaries">
					<ValueCard
						heading="Frass %"
						value={`${formatFloats(batch.pupaeIntroPreps[0].frassPercentage)}%`}
					/>
					<ValueCard
						heading="Total Biomass"
						value={`${formatFloats(batch.pupaeIntroPreps[0].biomassG / 1000)}kg`}
					/>
					<ValueCard
						heading="Pre-Pupae Weight"
						value={`${formatFloats(batch.pupaeIntroPreps[0].pupaeWeightMg)}mg`}
					/>
				</FormOutputs>
			}
		</Form>
	)
}

export default BatchSummary;