import AxiosRequest from "./AxiosRequest"
import IAviary from "../types/Aviary";
import AbstractMagOpsApi from "./AbstractMagOpsApi";

class AviaryApi extends AbstractMagOpsApi {

	getAll(siteId: number) {
		return AxiosRequest.get<IAviary[]>("/aviaries", {
			params: {
				"siteId": siteId
			},
			signal: this.getRequestSignal()
		})
	}

	get(id: number) {
		return AxiosRequest.get<IAviary>(`/aviary/${id}`, {
			signal: this.getRequestSignal()
		})
	}

	create(data: { siteId: number, name: string }) {
		return AxiosRequest.post<IAviary>("/aviary", data)
	}

	delete(id: number) {
		return AxiosRequest.delete<{}>(`/aviary/${id}`)
	}
}

export default new AviaryApi()