import {MagOpsColors} from "../../shared/styles/MagOpsStyle";

/**
 * List of all colors to use for graphs
 */
export const DashboardColors = [
	MagOpsColors.primary.goterraSky,
	MagOpsColors.secondary.forestGreen,
	MagOpsColors.secondary.coralRed,
	MagOpsColors.secondary.marvelousMauve,
	MagOpsColors.secondary.sunsetOrange,
	MagOpsColors.secondary.lemonYellow,
	MagOpsColors.secondary.perplexingPink,
	MagOpsColors.secondary.maggotBlood,
	MagOpsColors.secondary.electricPurple,
	MagOpsColors.primary.periwinkleBlue,
]