import React, {useContext, useEffect} from "react";
import {NavContext} from "../../shared/providers/NavProvider";
import {Box, Container, Grid, Hidden, Typography} from "@mui/material";
import {ReactComponent as ErrorMib} from "../../assets/thinking_mib_404.svg";
import {ReactComponent as InternalErrorMib} from "../../assets/thinking_mib_500.svg";
import {appBarClasses} from "../../components/layout/LayoutStyles";
import {Home, Notifications} from "@mui/icons-material";
import {WhiteOutlinedButton} from "./ErrorsStyles";
import {Link} from "react-router-dom";

type Props = {
	error?: 404 | 500;
}

const Error = ({error = 404}: Props) => {

	const [, navDispatch] = useContext(NavContext);

	useEffect(() => {
		navDispatch({type: "SET_PAGE_DARK"})

		return () => {
			navDispatch({type: "SET_PAGE_LIGHT"})
		}
	}, [navDispatch])

	return (
		<Container maxWidth="md">
			<Grid container spacing={1}>
				<Grid item xs={12}>
					<Box p={3} sx={{
						[`& .${appBarClasses.logo}`]: {
							width: "100%"
						}
					}}>
						{error === 404 ? <ErrorMib className={appBarClasses.logo}/> :
							<InternalErrorMib className={appBarClasses.logo}/>}
					</Box>
				</Grid>
				<Grid item xs={12}>
					<Box padding={3}>
						<Typography fontSize="2rem" color="white">
							Looks like the maggots got here first! Better get back to the home page while you still
							can...
						</Typography>
						<Hidden smDown>
							<Box pt={3} display={"flex"} gap={3}>
								<Link to="/">
									<WhiteOutlinedButton variant="outlined" size="large" startIcon={<Home/>}>
										Go Home
									</WhiteOutlinedButton>
								</Link>
								<WhiteOutlinedButton
									onClick={() => navDispatch({type: "OPEN_TODO"})}
									variant="outlined"
									size="large"
									startIcon={<Notifications/>}
								>
									Check your tasks
								</WhiteOutlinedButton>
							</Box>
						</Hidden>
					</Box>
				</Grid>
				<Hidden smUp>
					<Grid item xs={12} sm={4}>
						<Box px={3}>
							<Link to="/">
								<WhiteOutlinedButton sx={{width: "100%"}} variant="outlined" size="large"
																		 startIcon={<Home/>}>
									Go Home
								</WhiteOutlinedButton>
							</Link>
						</Box>
					</Grid>
					<Grid item xs={12} sm={6}>
						<Box px={3}>
							<WhiteOutlinedButton
								sx={{width: "100%"}}
								onClick={() => navDispatch({type: "OPEN_TODO_MOBILE"})}
								variant="outlined"
								size="large"
								startIcon={<Notifications/>}
							>
								Check your tasks
							</WhiteOutlinedButton>
						</Box>
					</Grid>
				</Hidden>
			</Grid>
		</Container>
	)
}

export default Error;