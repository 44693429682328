import Config from "../types/Config";

export type ConfigState = {
	config?: Config
};

export type ConfigAction =
	| { type: "SET_CONFIG", data: Config }

export const ConfigReducer = (state: ConfigState, action: ConfigAction): ConfigState => {
	switch (action.type) {
		case "SET_CONFIG":
			return {
				config: action.data
			};
		default:
			return state;
	}
}

export const initialConfigState: ConfigState = {}