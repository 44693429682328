import axios from "axios";

/**
 * Contains the full Rest API for MagOps as defined in the SAM CLI template.
 * Each entry has a call that returns the correct result and has typed parameters for what should be added
 */
const AxiosRequest = axios.create({
	baseURL: process.env.REACT_APP_API_URL + "/api",
	headers: {
		"Content-Type": "application/json"
	}
})

export default AxiosRequest;