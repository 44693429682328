import React, {useContext, useEffect, useState} from "react";
import {TodoContext} from "../../shared/providers/TodoProvider";
import {CardActionArea, CardHeader, CircularProgress, List, Toolbar} from "@mui/material";
import {AirplanemodeActive, Dns, DoubleArrow, GroupWork, PieChart, Refresh} from "@mui/icons-material";
import TodoListCard from "./TodoListCard";
import TodoCard from "./TodoCard";
import {NavContext} from "../../shared/providers/NavProvider";
import {MagOpsColors} from "../../shared/styles/MagOpsStyle";
import {StyledTodoListCard} from "./TodoStyles";
import {refreshTodo} from "../../shared/helpers";
import IProductionTask from "../../shared/types/ProductionTask";

const taskMapper = (task: IProductionTask): React.ReactNode => {
	switch (task.type) {
		case "PupaeIntro":
			return <TodoCard task={task} key={task.id} link={`/batch/stocking/${task.id}`}/>
		case "Eggs":
			return <TodoCard task={task} key={task.id} link={`/batch/eggs/${task.id}`}/>
		case "GrowRoomFeed":
			return <TodoCard task={task} key={task.id} link={`/batch/feed/${task.id}`}/>
		case "GrowRoomSample":
			return <TodoCard task={task} key={task.id} link={`/batch/sample/${task.id}`}/>
		case "Sifting":
			return <TodoCard task={task} key={task.id} link={`/batch/sifting/${task.id}`}/>
		case "Transfer":
			return <TodoCard task={task} key={task.id} link={`/batch/transfer/${task.id}`}/>
		case "HatchingTrays":
			return <TodoCard task={task} key={task.id} link={`/batch/hatching_trays/${task.id}`}/>
		case "PupaeIntroPrep":
			return <TodoCard task={task} key={task.id} link={`/batch/pupae_intro_prep/${task.id}`}/>
	}
}

export const TodoLayout = () => {
	const [todoState, todoDispatch] = useContext(TodoContext);
	const [navState] = useContext(NavContext);
	const [isLoading, setIsLoading] = useState(true);

	useEffect(() => {
		setIsLoading(todoState.loading);
	}, [todoState.loading]);

	return (
		<React.Fragment>
			<List>
				{
					isLoading &&
					<StyledTodoListCard>
						<CardHeader
							avatar={<CircularProgress color="primary" size={24}/>}
							title="Loading..."
							titleTypographyProps={{
								variant: "h3",
								color: MagOpsColors.primary.goterraSky
							}}
						/>
					</StyledTodoListCard>
				}
				{todoState.todoList.aviary && <TodoListCard
					isLoading={isLoading}
					title="Aviary"
					avatar={<AirplanemodeActive/>}
					mapper={taskMapper}
					tasks={todoState.todoList.aviary}
				/>}
				{todoState.todoList.incubator && <TodoListCard
					isLoading={isLoading}
					title="Incubator"
					avatar={<GroupWork/>}
					tasks={todoState.todoList.incubator}
					mapper={taskMapper}
				/>}
				{todoState.todoList.growRoom && <TodoListCard
					isLoading={isLoading}
					title="Grow Room"
					avatar={<Dns/>}
					tasks={todoState.todoList.growRoom}
					mapper={taskMapper}
				/>}
				{todoState.todoList.sifting && <TodoListCard
					isLoading={isLoading}
					title="Sifting"
					avatar={<PieChart/>}
					mapper={taskMapper}
					tasks={todoState.todoList.sifting}
				/>}
				{todoState.todoList.darkRoom && <TodoListCard
					isLoading={isLoading}
					title="Dark Room"
					avatar={<DoubleArrow/>}
					mapper={taskMapper}
					tasks={todoState.todoList.darkRoom}
				/>}
				{
					!isLoading &&
					<StyledTodoListCard>
						<CardActionArea onClick={async () => {
							await refreshTodo(navState, todoDispatch);
						}}>
							<CardHeader
								avatar={<Refresh color="primary"/>}
								title="Get New Tasks"
								titleTypographyProps={{
									variant: "h3",
									color: MagOpsColors.primary.goterraSky
								}}
							/>
						</CardActionArea>
					</StyledTodoListCard>
				}
			</List>
			<Toolbar/>
		</React.Fragment>
	);
}