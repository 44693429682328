import AxiosRequest from "./AxiosRequest"
import {formatDate} from "../helpers";
import IMib from "../types/Mib";
import AbstractMagOpsApi from "./AbstractMagOpsApi";

class MibApi extends AbstractMagOpsApi {
	getAll(query?: { siteId?: number, stackId?: number }) {
		return AxiosRequest.get<IMib[]>("/mibs", {
			params: {
				...(query?.siteId && {
					siteId: query.siteId
				}),
				...(query?.stackId && {
					stack_id: query.stackId
				})
			},
			signal: this.getRequestSignal()
		})
	}

	get(id: number, startDate?: Date, endDate?: Date) {
		return AxiosRequest.get<IMib>(`/mib/${id}`, {
			params: {
				timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
				...(startDate && {
					startDate: formatDate(startDate)
				}),
				...(endDate && {
					endDate: formatDate(endDate)
				})
			},
			signal: this.getRequestSignal()
		})
	}

	create(data: { stack_id: number, name: string }) {
		return AxiosRequest.post<IMib>("/mib", data)
	}

	delete(id: number) {
		return AxiosRequest.delete<{}>(`/mib/${id}`)
	}

	feed(mib_service_id: number) {
		return AxiosRequest.post<{}>("/mib/feed", {mib_service_id})
	}
}

export default new MibApi()