import React, {useContext, useEffect, useState} from "react";
import {NavContext} from "../../shared/providers/NavProvider";
import Form from "../../components/forms/Form";
import {useHistory} from "react-router-dom";
import {useSnackbar} from "notistack";
import {GridColDef, GridRowParams} from "@mui/x-data-grid";
import FormSection from "../../components/forms/FormSection";
import {Box, Hidden, Typography} from "@mui/material";
import {DataGridStyled} from "../../components/layout/DataGridStyles";
import MibApi from "../../shared/api/MibApi";
import IMib from "../../shared/types/Mib";
import axios from "axios";


const columns: GridColDef[] = [
	{
		field: "name",
		headerName: "Name",
		flex: 1,
		renderCell: (cellValues) => {
			return (
				<Typography>
					{cellValues.value && `MIB ${cellValues.value}`}
				</Typography>
			)
		}
	},
	{
		field: "site",
		headerName: "Site",
		flex: 2
	},
];

const mobileColumns: GridColDef[] = [
	{
		field: "name",
		headerName: "Name",
		flex: 1
	},
];

type DisplayMib = {
	id: number;
	name: string;
	site: string;
}

const MibList = () => {
	const [navState] = useContext(NavContext);
	const [mibs, setMibs] = useState<DisplayMib[]>([]);
	const [isLoading, setIsLoading] = useState(true);
	const {enqueueSnackbar} = useSnackbar();

	let history = useHistory();

	useEffect(() => {
		(async () => {
			setIsLoading(true);
			try {
				if (navState.sites.length === 0) {
					// Still waiting for sites to be populated
					return;
				}
				let response = await MibApi.getAll({siteId: navState.sites[navState.siteIndex].id});
				const mibsIn = response.data.map((mib: IMib): DisplayMib => {
					return {
						id: mib.id,
						name: mib.name,
						site: mib.site || ""
					}
				})
				setMibs(mibsIn);
			} catch (error) {
				if (axios.isCancel(error)) {
					return;
				}
				// Something happened in setting up the request that triggered an Error
				enqueueSnackbar(`Failed to load mibs for your site`, {variant: "error"})
				console.log('Error', error.message);
				setIsLoading(false);
			}
		})()

		return () => MibApi.abort();
	}, [navState.sites, navState.siteIndex, enqueueSnackbar, history])

	useEffect(() => {
		if (mibs && mibs.length > 0) {
			setIsLoading(false);
		}
	}, [mibs])

	const onRowClick = (params: GridRowParams) => {
		history.push(`/mibs/${params.row.id}`)
	}

	return (
		<Form heading="MIBs" loading={false}>
			<FormSection>
				<Box height={424}>
					<Hidden smDown>
						<DataGridStyled
							columns={columns}
							rows={mibs}
							loading={isLoading}
							onRowClick={onRowClick}
						/>
					</Hidden>
					<Hidden smUp>
						<DataGridStyled
							columns={mobileColumns}
							rows={mibs}
							loading={isLoading}
							onRowClick={onRowClick}
						/>
					</Hidden>
				</Box>
			</FormSection>
		</Form>
	)
}

export default MibList;