import {Hidden} from "@mui/material";
import React, {ReactNode} from "react";
import {StyledDrawer, StyledDrawerMobile} from "./NavigationStyles";

type Props = {
	onClose: (() => void);
	onOpen: (() => void);
	open: boolean;
	openMobile: boolean;
	children?: ReactNode;
	drawerWidth: number
};

const NavDrawer = ({onClose, onOpen, open, openMobile, children, drawerWidth}: Props) => {
	return (
		<React.Fragment>
			<Hidden smUp>
				<StyledDrawerMobile
					drawerWidth={drawerWidth}
					variant="temporary"
					anchor="left"
					open={openMobile}
					onClose={onClose}
					onOpen={onOpen}
					PaperProps={{
						elevation: 2,
						square: false
					}}
					ModalProps={{
						keepMounted: true, // Better open performance on mobile.
						onClick: onClose
					}}
				>
					{children}
				</StyledDrawerMobile>
			</Hidden>
			<Hidden smDown>
				<StyledDrawer
					drawerWidth={drawerWidth}
					variant={"persistent"}
					anchor="left"
					open={open}
					PaperProps={{
						elevation: 2,
						square: false
					}}
				>
					{children}
				</StyledDrawer>
			</Hidden>
		</React.Fragment>
	);
}

export default NavDrawer;