import Form from "../../../components/forms/Form";
import React, {useContext, useEffect, useState} from "react";
import {useHistory} from "react-router-dom";
import {NavContext} from "../../../shared/providers/NavProvider";
import ReportsApi from "../../../shared/api/ReportsApi";
import {format, subDays} from "date-fns";
import {useSnackbar} from "notistack";
import {DateRange} from "@mui/lab/DateRangePicker/RangeTypes";
import SingleDateAreaChart from "../../../components/dashboard/SingleDateAreaChart";
import AlertsBanner from "../../../components/dashboard/AlertsBanner";
import IAlert from "../../../shared/types/Alert";
import AlertsApi from "../../../shared/api/AlertsApi";
import IGrowRoomReport from "../../../shared/types/GrowRoomReport";
import {formatFloats} from "../../../shared/helpers";
import axios from "axios";
import {CSVLink} from "react-csv";
import {Button} from "@mui/material";

const SiftingDashboard = () => {
	const [navState] = useContext(NavContext);
	const [report, setReport] = useState<IGrowRoomReport>();
	const [isLoading, setIsLoading] = useState(true);
	const [alerts, setAlerts] = useState<Array<IAlert>>();
	const [dateRange, setDateRange] = React.useState<DateRange<Date>>(
		[subDays(new Date(), 7), new Date()]
	);
	const {enqueueSnackbar} = useSnackbar();

	let history = useHistory();

	useEffect(() => {
		(async () => {
			try {
				setIsLoading(true);
				if (navState.sites.length === 0 || !dateRange[0] || !dateRange[1]) {
					// Still waiting for sites to be populated
					return;
				}
				let response = await ReportsApi.getGrowRoom(navState.sites[navState.siteIndex].id, dateRange[0], dateRange[1]);
				setReport(response.data);
			} catch (error) {
				if (axios.isCancel(error)) {
					return;
				}
				// Something happened in setting up the request that triggered an Error
				enqueueSnackbar(`Failed to load grow room reports`, {variant: "error"})
				console.log('Error', error.message);
			}
		})()

		return () => ReportsApi.abort();
	}, [navState.sites, navState.siteIndex, enqueueSnackbar, history, dateRange])

	useEffect(() => {
		(async () => {
			try {
				setIsLoading(true);
				if (navState.sites.length === 0) {
					// Still waiting for sites to be populated
					return;
				}
				let response = await AlertsApi.get(navState.sites[navState.siteIndex].id, "grow_room");
				setAlerts(response.data);
			} catch (error) {
				if (axios.isCancel(error)) {
					return;
				}
				// Something happened in setting up the request that triggered an Error
				enqueueSnackbar(`Failed to find grow room alerts`, {variant: "error"})
				history.push("/not-found")
				console.log('Error', error.message);
			}
		})()

		return () => AlertsApi.abort();
	}, [navState.sites, navState.siteIndex, enqueueSnackbar, history])

	useEffect(() => {
		if (report && alerts) {
			setIsLoading(false);
		}
	}, [report, alerts])

	return (
		<Form
			heading={"Sifting Dashboard"}
			dateRangeProps={{
				dateRange: dateRange,
				maxDate: new Date(),
				setDateRange: setDateRange
			}}
			loading={!report}
		>
			<AlertsBanner alerts={alerts}/>
			{report?.biomassOut &&
				<SingleDateAreaChart
					charts={[
						{key: 0, name: "Biomass Out", color: "orange"},
						{key: 1, name: "Frass Out", color: "mauve"},
					]}
					data={report.biomassOut.map(value => ({
						date: format(new Date(value.time), "dd/MM/yyyy"),
						0: value.biomass_kg,
						1: value.frass_kg,
					}))}
					heading="Biomass Out"
					loading={isLoading}
					units="kg"
				>
					<CSVLink
						data={report.biomassOut.map(biomass => ({
							Date: format(new Date(biomass.time), "dd/MM/yyyy"),
							"Biomass (kg)": biomass.biomass_kg,
							"Frass (kg)": biomass.frass_kg,
							"Batch": biomass.batch,
						}))}
						filename={`MagOps_Sifting_Biomass_Report_${format(dateRange[0] || 0, "dd/MM/yyyy")}-${format(dateRange[1] || 0, "dd/MM/yyyy")}`}
					>
						<Button color="primary" variant="contained" size="large">
							Download Data
						</Button>
					</CSVLink>
				</SingleDateAreaChart>
			}
			{report?.prePupaeWeightOut &&
				<SingleDateAreaChart
					charts={[
						{key: 0, name: "Pre Pupae Sample Weight", color: "blue"},
					]}
					data={report.prePupaeWeightOut.map(value => ({
						date: format(new Date(value.time), "dd/MM/yyyy"),
						0: value.prePupaeWeightMg !== null ? +formatFloats(value.prePupaeWeightMg) : null,
					}))}
					heading="Pre Pupae Sample Weight"
					loading={isLoading}
					units={"mg"}
				>
					<CSVLink
						data={report.prePupaeWeightOut.map(biomass => ({
							Date: format(new Date(biomass.time), "dd/MM/yyyy"),
							"Pre Pupae Weight (mg)": biomass.prePupaeWeightMg,
							"Batch": biomass.batch,
						}))}
						filename={`MagOps_Sifting_Pre_Pupae_Report_${format(dateRange[0] || 0, "dd/MM/yyyy")}-${format(dateRange[1] || 0, "dd/MM/yyyy")}`}
					>
						<Button color="primary" variant="contained" size="large">
							Download Data
						</Button>
					</CSVLink>
				</SingleDateAreaChart>
			}
		</Form>
	)
}

export default SiftingDashboard;