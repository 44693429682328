/**
 * Provides an abstraction for allowing cancelling of requests
 */
import {Stack} from "datastructures-js";

class AbstractMagOpsApi {
	private controllers: Stack<AbortController>;

	constructor() {
		this.controllers = new Stack<AbortController>();
	}

	protected getRequestSignal() {
		const controller = new AbortController();
		this.controllers.push(controller);
		return controller.signal;
	}

	public abort() {
		if (this.controllers.peek()) {
			this.controllers.pop().abort();
		}
	}
}

export default AbstractMagOpsApi;