import React, {useContext, useEffect, useState} from "react";
import Form from "../components/forms/Form";
import AlertsBanner from "../components/dashboard/AlertsBanner";
import IAlert from "../shared/types/Alert";
import {NavContext} from "../shared/providers/NavProvider";
import {useSnackbar} from "notistack";
import {useHistory} from "react-router-dom";
import AlertsApi from "../shared/api/AlertsApi";
import {format, subDays} from "date-fns";
import IDayReport from "../shared/types/DayReport";
import ReportsApi from "../shared/api/ReportsApi";
import {formatFloats, formatInt} from "../shared/helpers";
import FormOutputs from "../components/forms/FormOutputs";
import {Box, LinearProgress, Typography} from "@mui/material";
import FormSection from "../components/forms/FormSection";
import Heading from "../components/forms/Heading";
import {useInView} from "react-intersection-observer";
import DateDivider from "../components/dashboard/DateDivider";
import axios from "axios";
import ValueLinkCard from "../components/forms/ValueLinkCard";

const Home = () => {
	const [navState] = useContext(NavContext);
	const [isLoading, setIsLoading] = useState(true);
	const [reports, setReports] = useState<Array<IDayReport>>([]);
	const [days, setDays] = useState(0);
	const [alerts, setAlerts] = useState<Array<IAlert>>();
	const {enqueueSnackbar} = useSnackbar();
	const [ref, inView] = useInView({threshold: 0.0});
	let history = useHistory();

	useEffect(() => {
		(async () => {
			try {
				if (navState.sites.length === 0) {
					// Still waiting for sites to be populated
					return;
				}
				let response = await ReportsApi.getDay(navState.sites[navState.siteIndex].id, subDays(new Date(), days));
				setReports(oldReports => {
					let newReports = [...oldReports];
					if (newReports.length <= days) {
						newReports.push(response.data);
					} else {
						newReports[days] = response.data;
					}
					return newReports;
				});
				// Do this to preload
				if (days < 7) {
					setDays(oldDays => oldDays + 1);
				}
			} catch (error) {
				if (axios.isCancel(error)) {
					return;
				}
				// Something happened in setting up the request that triggered an Error
				enqueueSnackbar(`Failed to load today's overview`, {variant: "error"})
				console.log('Error', error.message);
			}
		})()
	}, [navState.sites, navState.siteIndex, enqueueSnackbar, history, days])

	useEffect(() => {
		(async () => {
			try {
				setIsLoading(true);
				if (navState.sites.length === 0) {
					// Still waiting for sites to be populated
					return;
				}
				let response = await AlertsApi.get(navState.sites[navState.siteIndex].id);
				setAlerts(response.data);
			} catch (error) {
				if (axios.isCancel(error)) {
					return;
				}
				// Something happened in setting up the request that triggered an Error
				enqueueSnackbar(`Failed to load alerts`, {variant: "error"})
				console.log('Error', error.message);
			}
		})()

		return () => {
			AlertsApi.abort();
		}
	}, [navState.sites, navState.siteIndex, enqueueSnackbar, history])

	useEffect(() => {
		if (alerts) {
			setIsLoading(false);
		}
	}, [alerts])

	useEffect(() => {
		if (inView) {
			setDays(oldDays => oldDays + 1);
		}
	}, [inView])

	return (
		<Form
			heading="Welcome to MagOps"
			subheading="Operations Overview"
			loading={isLoading}
		>
			<AlertsBanner alerts={alerts} heading="Alerts"/>
			<DateDivider date={new Date()}/>
			{reports.map((report, index) => {
				const date = subDays(new Date(), index);
				return (
					<React.Fragment key={index}>
						<FormSection>
							<Heading
								heading={format(date, "EEEE")}
								subheading={format(date, "LLLL do, yyyy")}
							/>
						</FormSection>
						<FormOutputs header="Summary">
							{!report?.eggsG && !report?.feeders && !report?.breeders
								&& !report?.prePupaeWeightMg && !report?.pupaeWeightMg && !report?.hatchingRate &&
								<Box flexGrow={1}>
									<Typography variant="h2" textAlign="center">No Tasks Completed</Typography>
								</Box>
							}
							{report?.eggsG && report.eggsG.batchId && report.eggsG.amount &&
								<ValueLinkCard path={`/batches/${report.eggsG.batchId}`} heading="Eggs Collected"
															 value={`${formatFloats(report.eggsG.amount)}g`}/>
							}
							{report?.forecastFeeders && report.forecastFeeders.batchId && report.forecastFeeders.amount &&
								<ValueLinkCard path={`/batches/${report.forecastFeeders.batchId}`} heading="Projected Feeders in 5 Days"
															 value={`${formatInt(report.forecastFeeders.amount)}`}/>
							}
							{report?.feeders && report.feeders.batchId && report.feeders.amount &&
								<ValueLinkCard path={`/batches/${report.feeders.batchId}`} heading="Feeder Trays"
															 value={formatInt(report.feeders.amount)}/>
							}
							{report?.breeders && report.breeders.batchId && report.breeders.amount &&
								<ValueLinkCard path={`/batches/${report.breeders.batchId}`} heading="Breeder Trays"
															 value={formatInt(report.breeders.amount)}/>}
							{report?.hatchingRate && report.hatchingRate.batchId && report.hatchingRate.amount &&
								<ValueLinkCard path={`/batches/${report.hatchingRate.batchId}`} heading="Hatching Rate"
															 value={formatFloats(report.hatchingRate.amount * 100) + "%"}/>
							}
							{report?.prePupaeWeightMg && report.prePupaeWeightMg.batchId && report.prePupaeWeightMg.amount &&
								<ValueLinkCard path={`/batches/${report.prePupaeWeightMg.batchId}`} heading="Pre-Pupae Weight In"
															 value={`${formatFloats(report.prePupaeWeightMg.amount)}mg`}/>
							}
							{report?.pupaeWeightMg && report.pupaeWeightMg.batchId && report.pupaeWeightMg.amount &&
								<ValueLinkCard path={`/batches/${report.pupaeWeightMg.batchId}`} heading="Pupae Weight Out"
															 value={`${formatFloats(report.pupaeWeightMg.amount)}mg`}/>
							}
						</FormOutputs>
						<DateDivider date={subDays(date, 1)}/>
					</React.Fragment>
				)
			})}
			<FormSection>
				<LinearProgress ref={ref}/>
			</FormSection>
		</Form>
	)
}

export default Home;