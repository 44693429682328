import {Button, ButtonProps, styled} from "@mui/material";
import {MagOpsColors} from "../../shared/styles/MagOpsStyle";

export const WhiteOutlinedButton = styled(Button)<ButtonProps>(({theme}) => ({
	color: theme.palette.getContrastText(MagOpsColors.primary.goterraSky),
	fontSize: "1.25rem",
	borderColor: theme.palette.getContrastText(MagOpsColors.primary.goterraSky),
	borderWidth: 2,
	"&:hover": {
		borderColor: MagOpsColors.primary.periwinkleBlue,
		color: MagOpsColors.primary.periwinkleBlue,
		borderWidth: 2,
	},
}));