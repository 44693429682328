import FeedApi from "../../../../shared/api/GrowRoomFeedApi";
import {nowTime, refreshTodo} from "../../../../shared/helpers";
import React, {useContext, useState} from "react";
import {NavContext} from "../../../../shared/providers/NavProvider";
import {TodoContext} from "../../../../shared/providers/TodoProvider";
import IBatch from "../../../../shared/types/Batch";
import {useSnackbar} from "notistack";
import {useHistory, useParams} from "react-router-dom";
import useBatch from "../../../../shared/helpers/useBatch";
import Form from "../../../../components/forms/Form";
import FormOutputs from "../../../../components/forms/FormOutputs";
import ValueInputCard from "../../../../components/forms/ValueInputCard";
import {ConfigContext} from "../../../../shared/providers/ConfigProvider";

interface FeedProps {
	taskId?: string;
}

const GrowRoomFeedForm = () => {
	const [navState] = useContext(NavContext)
	const [, todoDispatch] = useContext(TodoContext)
	const [batch, setBatch] = useState<IBatch>();
	const [configState] = useContext(ConfigContext);
	const [isLoading, setIsLoading] = useState(true)
	const [value, setValue] = useState<number>(configState.config?.growRoomFeedAmountG || 0 / 1000);
	const [valueValid, setValueValid] = useState<boolean>(!!configState.config?.growRoomFeedAmountG);
	const [date, setDate] = useState<Date>(new Date());
	const {enqueueSnackbar} = useSnackbar();

	let history = useHistory();
	let {taskId} = useParams<FeedProps>()

	useBatch({
		taskIdStr: taskId,
		setBatch,
		setDate,
		setIsLoading
	})

	const create = async (batchId: number, value: number, taskId: number, date: Date) => {
		return await FeedApi.create({
			batchId: batchId,
			feedPerTrayG: value,
			taskId: taskId,
			...(date && {time: nowTime(date)})
		})
	}

	const submit = async () => {
		if (batch && taskId) {
			const taskIdNum = parseInt(taskId);
			if (isNaN(taskIdNum)) {
				return
			}
			try {
				setIsLoading(true);
				await create(batch.id, value, taskIdNum, date);
				enqueueSnackbar("Successfully fed batch", {variant: "success"});
				history.push("/");
			} catch (e) {
				enqueueSnackbar(`Failed to submit Manufacture Breeder Trays data`, {variant: "error"});
				setIsLoading(false);
				console.log('Error', e.message);
			}
		}
	}

	const handleFormPaging = async (back = false) => {
		if (!back) {
			await submit()
			await refreshTodo(navState, todoDispatch)
		}
	}

	return (
		<Form
			heading="Manufacture Breeder Trays"
			subheading={`Batch ${batch?.localId.toString().padStart(4, "0")}`}
			loading={isLoading}
			handleFormPaging={handleFormPaging}
			nextDisabled={!valueValid || value === 0}
			dateProps={{
				date: date,
				setDate: setDate,
				readonly: true,
			}}
		>
			<FormOutputs>
				<ValueInputCard
					heading="Feed per Tray"
					valid={valueValid}
					setValue={value => setValue(value * 1000)}
					setValid={setValueValid}
					units="kg"
					defaultValue={value}
					// helperText={`Defaults to SOP value of ${(configState.config?.growRoomFeedAmountG || 0) / 1000}kg`}
				/>
			</FormOutputs>
		</Form>
	)
}

export default GrowRoomFeedForm;