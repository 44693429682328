import React, {createContext, useReducer} from "react";
import {ConfigAction, ConfigReducer, ConfigState, initialConfigState} from "../reducers/ConfigReducer";

export type ConfigContextType = [ConfigState, (action: ConfigAction) => void]

export const ConfigContext = createContext<ConfigContextType>([
	initialConfigState,
	() => null
])

type ConfigProviderProps = {
	children?: React.ReactNode
}

export const ConfigProvider = ({children}: ConfigProviderProps) => {
	const [state, dispatch] = useReducer(ConfigReducer, initialConfigState);

	return (
		<ConfigContext.Provider value={[state, dispatch]}>
			{children}
		</ConfigContext.Provider>
	)
}