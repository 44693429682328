import {BrowserRouter as Router, Switch} from "react-router-dom";
import Home from "../../containers/Home";
import Login from "../../containers/auth/Login";
import EggsForm from "../../containers/batch/eggs/EggsForm";
import Layout from "../layout/Layout";
import TransferForm from "../../containers/batch/transfer/TransferForm";
import Error from "../../containers/errors/Error";
import GlobalsLoader from "../utility/GlobalsLoader";
import ConfigForm from "../../containers/settings/ConfigForm";
import SiftingForm from "../../containers/batch/sifting/SiftingForm";
import GrowRoomFeedForm from "../../containers/batch/grow/feed/GrowRoomFeedForm";
import SampleForm from "../../containers/batch/grow/sample/GrowRoomSampleForm";
import PupaeIntroForm from "../../containers/batch/pupae_intro/PupaeIntroForm";
import InfrastructureForm from "../../containers/settings/infrastructure/InfrastructureForm";
import BatchList from "../../containers/batch/BatchList";
import BatchSummary from "../../containers/batch/BatchSummary";
import AviaryDashboard from "../../containers/reports/aviary/AviaryDashboard";
import IncubatorDashboard from "../../containers/reports/incubator/IncubatorDashboard";
import SiftingDashboard from "../../containers/reports/sifting/SiftingDashboard";
import DarkRoomDashboard from "../../containers/reports/dark_room/DarkRoomDashboard";
import MibList from "../../containers/mib/MibList";
import MibSummary from "../../containers/mib/MibSummary";
import MibServiceForm from "../../containers/mib/service/MibServiceForm";
import MibHarvestForm from "../../containers/mib/harvest/MibHarvestForm";
import React from "react";
import PrivateRoute from "./PrivateRoute";
import InviteUserForm from "../../containers/settings/users/InviteUserForm";
import PublicRoute from "./PublicRoute";
import CustomerForm from "../../containers/settings/customer/CustomerForm";
import WasteForm from "../../containers/waste/WasteForm";
import HatchingTraysForm from "../../containers/batch/hatching_trays/HatchingTraysForm";
import PupaeIntroPrepForm from "../../containers/batch/pupae_intro/PupaeIntroPrepForm";


const MagOpsRouter = () => {
	return (
		<Router>
			<GlobalsLoader>
				<Layout>
					<Switch>
						<PrivateRoute admin path="/settings/config">
							<ConfigForm/>
						</PrivateRoute>
						<PrivateRoute admin path="/settings/infrastructure">
							<InfrastructureForm/>
						</PrivateRoute>
						<PrivateRoute admin path="/settings/customer">
							<CustomerForm/>
						</PrivateRoute>
						<PublicRoute path="/login">
							<Login/>
						</PublicRoute>
						<PrivateRoute admin path="/users/invite">
							<InviteUserForm/>
						</PrivateRoute>
						<PrivateRoute path="/batches/:batchId">
							<BatchSummary/>
						</PrivateRoute>
						<PrivateRoute exact path="/batches">
							<BatchList/>
						</PrivateRoute>
						<PrivateRoute exact path="/mibs">
							<MibList/>
						</PrivateRoute>
						<PrivateRoute exact path="/mibs/:mibId">
							<MibSummary/>
						</PrivateRoute>
						<PrivateRoute exact path="/mibs/:mibId/service">
							<MibServiceForm/>
						</PrivateRoute>
						<PrivateRoute exact path="/mibs/:mibServiceId/harvest">
							<MibHarvestForm/>
						</PrivateRoute>
						<PrivateRoute path="/batch/eggs/:taskId">
							<EggsForm/>
						</PrivateRoute>
						<PrivateRoute path="/batch/hatching_trays/:taskId">
							<HatchingTraysForm/>
						</PrivateRoute>
						<PrivateRoute path="/batch/transfer/:taskId">
							<TransferForm/>
						</PrivateRoute>
						<PrivateRoute path="/batch/feed/:taskId">
							<GrowRoomFeedForm/>
						</PrivateRoute>
						<PrivateRoute path="/batch/sample/:taskId">
							<SampleForm/>
						</PrivateRoute>
						<PrivateRoute path="/batch/sifting/:taskId">
							<SiftingForm/>
						</PrivateRoute>
						<PrivateRoute path="/batch/stocking/:taskId">
							<PupaeIntroForm/>
						</PrivateRoute>
						<PrivateRoute path="/batch/pupae_intro_prep/:taskId">
							<PupaeIntroPrepForm/>
						</PrivateRoute>
						<PrivateRoute path="/reports/aviary">
							<AviaryDashboard/>
						</PrivateRoute>
						<PrivateRoute path="/reports/incubator">
							<IncubatorDashboard/>
						</PrivateRoute>
						<PrivateRoute path="/reports/sifting">
							<SiftingDashboard/>
						</PrivateRoute>
						<PrivateRoute path="/reports/dark_room">
							<DarkRoomDashboard/>
						</PrivateRoute>
						<PrivateRoute path="/waste">
							<WasteForm/>
						</PrivateRoute>
						<PrivateRoute exact path="/">
							<Home/>
						</PrivateRoute>
						<PrivateRoute exact path="/error">
							<Error error={500}/>
						</PrivateRoute>
						<PrivateRoute exact path="/not-found">
							<Error/>
						</PrivateRoute>
						<PublicRoute path="*">
							<Error/>
						</PublicRoute>
					</Switch>
				</Layout>
			</GlobalsLoader>
		</Router>
	);
}

export default MagOpsRouter;