import AxiosRequest from "./AxiosRequest"
import ISite from "../types/Site";
import AbstractMagOpsApi from "./AbstractMagOpsApi";

class SiteApi extends AbstractMagOpsApi {
	getAll() {
		return AxiosRequest.get<ISite[]>("/sites", {
			signal: this.getRequestSignal()
		})
	}

	get(id: number) {
		return AxiosRequest.get<ISite>(`/site/${id}`, {
			signal: this.getRequestSignal()
		})
	}

	create(data: { location: string }) {
		return AxiosRequest.post<ISite>("/site", data)
	}

	delete(id: number) {
		return AxiosRequest.delete<{}>(`/site/${id}`)
	}
}

export default new SiteApi()