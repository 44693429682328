import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

type Props = {
	setOpen: (open: boolean) => void;
	open: boolean;
	missing: number;
	submit: () => void;
}

const AlertDialog = ({open, setOpen, missing, submit}: Props) => {
	const handleClose = () => {
		setOpen(false);
	};

	return (
		<Dialog
			open={open}
			onClose={handleClose}
		>
			<DialogTitle>
				Are you sure you want to submit?
			</DialogTitle>
			<DialogContent>
				<DialogContentText>
					{`There are still ${missing} aviaries without an entry. They will be set to 0.`}
				</DialogContentText>
			</DialogContent>
			<DialogActions>
				<Button onClick={() => setOpen(false)}>Go Back</Button>
				<Button onClick={() => {
					setOpen(false);
					submit();
				}} autoFocus>Submit</Button>
			</DialogActions>
		</Dialog>
	);
}

export default AlertDialog;
