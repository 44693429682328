import React, {createContext, useReducer} from "react";
import {AuthAction, AuthReducer, AuthState, initialAuthState} from "../reducers/AuthReducer";

export type AuthContextType = [AuthState, (action: AuthAction) => void]

export const AuthContext = createContext<AuthContextType>([
	initialAuthState,
	() => null
])

type AuthProviderProps = {
	children?: React.ReactNode
}

export const AuthProvider = ({children}: AuthProviderProps) => {
	const [state, dispatch] = useReducer(AuthReducer, initialAuthState);

	return (
		<AuthContext.Provider value={[state, dispatch]}>
			{children}
		</AuthContext.Provider>
	)
}