import React, {useContext, useEffect} from "react";
import {Route, RouteProps} from "react-router-dom";
import {NavContext} from "../../shared/providers/NavProvider";

const PublicRoute = ({children, ...rest}: RouteProps) => {
	const [navState, navDispatch] = useContext(NavContext);

	useEffect(() => {
		if (!navState.disableNav) {
			navDispatch({type: "SET_NAV_DISABLED", data: true});
		}
	}, [navState.disableNav, navDispatch])

	return (
		<Route
			{...rest}
			render={() => (children)}
		/>
	);
}

export default PublicRoute;