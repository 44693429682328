import {Typography} from "@mui/material";
import {FormValueCard} from "./FormStyles";
import React from "react";

type Props = {
	heading: string;
	value: string | number;
	variant?: "elevation" | "outlined";
	color?: "primary" | "error" | "success" | "warning";
}

const ValueCard = ({heading, value, variant = "outlined", color}: Props) => {
	return (
		<FormValueCard variant={variant} sx={{flexGrow: 1}} color={color}>
			<Typography component="p" variant="h6" align="center">
				{heading}
			</Typography>
			<Typography component="p" variant="h2" align="center" sx={{paddingTop: 1.25}}>
				{value}
			</Typography>
		</FormValueCard>
	);
}

export default ValueCard