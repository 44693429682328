import React, {createContext, useReducer} from "react";
import {initialTodoState, TodoAction, todoReducer, TodoState} from "../reducers/TodoReducer";

export type TodoContextType = [TodoState, (action: TodoAction) => void]

export const TodoContext = createContext<TodoContextType>([
	initialTodoState,
	() => null
])

type TodoProviderProps = {
	children?: React.ReactNode
}

export const TodoProvider = ({children}: TodoProviderProps) => {
	const [state, dispatch] = useReducer(todoReducer, initialTodoState);

	return (
		<TodoContext.Provider value={[state, dispatch]}>
			{children}
		</TodoContext.Provider>
	)
}