import {alpha, Card, CardProps, Drawer, DrawerProps, styled} from "@mui/material";
import {MagOpsColors} from "../../shared/styles/MagOpsStyle";
import {TaskStatus} from "../../shared/types/ProductionTask";

interface TodoCardProps extends CardProps {
	status: TaskStatus;
}

export const StyledTodoCard = styled(Card)<TodoCardProps>(({status, theme}) => ({
	margin: theme.spacing(2),
	padding: theme.spacing(0),
	overflow: "visible",
	...(status === "Todo" && {
		backgroundColor: MagOpsColors.primary.duskBlue
	}),
	...(status === "Overdue" && {
		backgroundColor: alpha(MagOpsColors.secondary.coralRed, 0.25)
	}),
	...(status === "Optional" && {
		backgroundColor: alpha(MagOpsColors.secondary.sunsetOrange, 0.2)
	}),
	...(status === "Done" && {
		backgroundColor: alpha(MagOpsColors.secondary.forestGreen, 0.2)
	}),
	...(status === "Blocked" && {
		backgroundColor: alpha(MagOpsColors.primary.maggotGrey, 0.25)
	}),
}))

export const StyledTodoListCard = styled(Card)<CardProps>(({theme}) => ({
	marginBottom: theme.spacing(2)
}))

interface StyledDrawerProps extends DrawerProps {
	drawerWidth: number
}

export const StyledTodoDrawer = styled(Drawer)<StyledDrawerProps>(({drawerWidth, theme}) => ({
	width: drawerWidth,
	"& .MuiDrawer-paper": {
		flexGrow: 1,
		width: drawerWidth,
		margin: theme.spacing(1),
		marginTop: theme.spacing(8),
		background: "transparent",
		borderLeft: 0
	}
}))

export const StyledTodoDrawerMobile = styled(Drawer)<DrawerProps>(({theme}) => ({
	width: "100%",
	"& .MuiDrawer-paper": {
		width: "100%",
		background: MagOpsColors.primary.duskBlue,
		padding: theme.spacing(1),
	}
}))