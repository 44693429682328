import {Autocomplete, Button, Grid, Typography} from "@mui/material";
import React, {useContext, useState} from "react";
import FormSection from "./FormSection";
import Heading from "./Heading";
import ValueInputCard from "./ValueInputCard";
import ISite from "../../shared/types/Site";
import IBillableCustomer from "../../shared/types/BillableCustomer";
import {FormValueCard, FormValueTextField} from "./FormStyles";
import {BinType} from "../../shared/types/CustomerSite";
import {NavContext} from "../../shared/providers/NavProvider";

type Props = {
	show?: boolean;
	header: string;
	submit: (names: string[], site: ISite, customer: IBillableCustomer, binType: BinType) => void;
	customers: IBillableCustomer[];
}

const CustomerSite = ({submit, show, header, customers}: Props) => {
	const [navState] = useContext(NavContext);
	const [name, setName] = useState<string>("");
	const [setNameValid, setSetNameValid] = useState<boolean>(false);
	const [customer, setCustomer] = useState<IBillableCustomer | null>(null);
	const [site, setSite] = useState<ISite | null>(null);
	const [binType, setBinType] = useState<string | null>(null);

	return (
		<FormSection show={show}>
			<Grid container spacing={3} justifyContent="center" alignContent="center">
				<Grid item xs={12}>
					<Heading subheading={header}/>
				</Grid>
				<Grid item xs={12} sm={6} md={4} lg={3} sx={{display: "flex"}}>
					<FormValueCard variant="outlined" style={{flexGrow: 1}}>
						<Typography component="p" variant="h6" align="center">
							Select Goterra Facility
						</Typography>
						<Autocomplete
							value={site}
							options={navState.sites}
							onChange={(event, newValue) => {
								setSite(newValue);
							}}
							getOptionLabel={option => option.location}
							renderInput={(params) => (
								<FormValueTextField
									{...params}
									variant="standard"
								/>
							)}
						/>
					</FormValueCard>
				</Grid>
				<Grid item xs={12} sm={6} md={4} lg={3} sx={{display: "flex"}}>
					<FormValueCard variant="outlined" style={{flexGrow: 1}}>
						<Typography component="p" variant="h6" align="center">
							Select Customer
						</Typography>
						<Autocomplete
							value={customer}
							options={customers}
							onChange={(event, newValue) => {
								setCustomer(newValue);
							}}
							getOptionLabel={option => option.name}
							renderInput={(params) => (
								<FormValueTextField
									{...params}
									variant="standard"
								/>
							)}
						/>
					</FormValueCard>
				</Grid>
				<Grid item xs={12} sm={6} md={4} lg={3} sx={{display: "flex"}}>
					<FormValueCard variant="outlined" style={{flexGrow: 1}}>
						<Typography component="p" variant="h6" align="center">
							Select Default Bin Type
						</Typography>
						<Autocomplete
							value={binType}
							options={["Bin240L", "Bin120L", "Bucket", "None"]}
							onChange={(event, newValue) => {
								setBinType(newValue);
							}}
							getOptionLabel={option => option}
							renderInput={(params) => (
								<FormValueTextField
									{...params}
									variant="standard"
								/>
							)}
						/>
					</FormValueCard>
				</Grid>
				<Grid item xs={12} sx={{display: "flex"}}>
					<ValueInputCard
						heading={"Site Names"}
						helperText={"Enter multiple sites by placing a \",\" between them."}
						valid={setNameValid}
						setValueStr={setName}
						setValid={setSetNameValid}
					/>
				</Grid>
				<Grid item xs={12}>
					<Button
						size="large"
						color="primary"
						variant="contained"
						disabled={!setNameValid || site === null || customer === null || binType === null}
						onClick={() => {
							if (site !== null && customer !== null && binType !== null) {
								// Split the name on commas into multiple names
								const names = name.split(",");
								submit(names, site, customer, binType as BinType) // This should be safe enough
							}
						}}
					>
						{header}
					</Button>
				</Grid>
			</Grid>
		</FormSection>

	)
}

export default CustomerSite;