import AxiosRequest from "./AxiosRequest"
import AbstractMagOpsApi from "./AbstractMagOpsApi";
import IWasteCollection from "../types/WasteCollection";

class WasteApi extends AbstractMagOpsApi {

	create(data: IWasteCollection) {
		return AxiosRequest.post<IWasteCollection>("/waste", data)
	}
}

export default new WasteApi()