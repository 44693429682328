interface SettingsHelper {
	name: string;
	description: string;
	unit?: string;
}

interface ConfigHelper {
	[key: string]: SettingsHelper;
}

const ConfigHelperText: ConfigHelper = {
	eggsDueDate: {
		name: "Egg Collection Due Date",
		description: "The number of days after batch creation that the eggs should be collected by.",
	},
	growRoomFeedDueDates: {
		name: "Grow Room Feed Due Dates",
		description: "The number of days after batch creation that the batch should be fed in the grow room.",
	},
	growRoomSampleDueDates: {
		name: "Grow Room Sample Due Dates",
		description: "The number of days after batch creation that the batch should be sampled in the grow room.",
	},
	actualSampleWeight: {
		name: "Actual Sample Weight",
		description: "The weight of the sample to be taken for transfer in grams (g).",
		unit: "g"
	},
	breederTrayDensity: {
		name: "Breeder Tray Density",
		description: "The amount of maggots required per breeder tray."
	},
	eggTrayDensity: {
		name: "Incubator Tray Density",
		description: "The amount of eggs to put in each tray in grams.",
		unit: "g"
	},
	feederTrayDensity: {
		name: "Feeder Tray Density",
		description: "The amount of maggots required per feeder tray."
	},
	hatchingRateThreshold: {
		name: "Hatching Rate Threshold",
		description: "The threshold for hatching rate, in integer percentage only.",
		unit: "%"
	},
	maggotsPerEgg: {
		name: "Maggots Per Egg",
		description: "The theoretical number of maggots per egg at 100% hatching rate."
	},
	mibServiceDuration: {
		name: "MIB Service Duration",
		description: "The number of days the trays used for a MIB service should remain in the MIB before harvest."
	},
	pupaeIntroDueDate: {
		name: "Pupae Intro Due Date",
		description: "The number of days after batch creation that the pupae should be introduced into the aviary by."
	},
	requiredBreederTrays: {
		name: "Required Breeder Trays",
		description: "The percentage of the pre pupae mass that the pupae should be above, integer percentage only."
	},
	siftingDueDate: {
		name: "Sifting Due Date",
		description: "The number of days after batch creation that the batch should be sifted by."
	},
	transferSampleSize: {
		name: "Transfer Sample Size",
		description: "The size of the sample the neonate weight is being compared against in grams.",
		unit: "g"
	},
	transferDueDate: {
		name: "Transfer Due Date",
		description: "The number of days after batch creation that the batch should be transferred by."
	},
	pupaeWeightLossThreshold: {
		name: "Pupae Weight Loss Threshold",
		description: "The percentage of the pre pupae mass that the pupae should be above, integer percentage only."
	},
	mibFeedPerTray: {
		name: "MIB Feed Per Tray",
		description: "The amount of feed added to each tray when a MIB is fed in kilograms.",
		unit: "Kg"
	},
	hatchingTraysDueDate: {
		name: "Hatching Trays Due Date",
		description: "The number of days after batch creation that the batch should have hatching trays made in incubator."
	},
	pupaeIntroPrepDueDate: {
		name: "Pupae Intro Prep Due Date",
		description: "The number of days after batch creation that the batch should be sifted in preparation for pupae intro."
	},
	growRoomFeedAmountG: {
		name: "Feed per Breeder Tray",
		description: "The amount of feed to add when manufacturing trays for breeders (i.e. grow room introduction).",
		unit: "g"
	},
	hatchingTraysFeedAmountG: {
		name: "Feed per Hatching Tray",
		description: "The amount of feed to add when creating trays for incubator.",
		unit: "g"
	},
	forecastRequiredEggsForBreeders: {
		name: "Required Eggs for Breeders",
		description: "The estimated amount of eggs required for breeder trays to be made in 5 days. Used for forecasting.",
		unit: "mg"
	},
	forecastHatchingRate: {
		name: "Forecasted Hatching Rate",
		description: "The hatching rate used in calculations for forecasting available feeder trays.",
		unit: "%"
	}

}

export default ConfigHelperText;