/**
 * userBatch will retrieve a batch from the given task in the routing information
 */
import {useEffect} from "react";
import axios from "axios";
import {useSnackbar} from "notistack";
import IBillableCustomer from "../types/BillableCustomer";
import CustomerApi from "../api/CustomerApi";
import SiteApi from "../api/SiteApi";

/**
 * Required parameters for setting the customers
 */
type UseCustomersParams = {
	setCustomers: (customers: IBillableCustomer[]) => void;
	setIsLoading: (loading: boolean) => void;
	setDirty?: (dirty: boolean) => void;
	dirty?: boolean;
}

const useCustomers = ({setCustomers, setDirty, setIsLoading, dirty}: UseCustomersParams) => {
	const {enqueueSnackbar} = useSnackbar();

	useEffect(() => {
		(async () => {
			try {
				setIsLoading(true);
				let response = await CustomerApi.list();
				setCustomers(response.data);

				if (setDirty) {
					setDirty(false);
				}
			} catch (error) {
				if (axios.isCancel(error)) {
					return;
				}
				// Something happened in setting up the request that triggered an Error
				enqueueSnackbar(`Failed to find customers`, {variant: "error"})
				console.log('Error', error.message);
			}
		})()

		return () => SiteApi.abort();
	}, [enqueueSnackbar, setDirty, dirty, setCustomers, setIsLoading])
}

export default useCustomers;