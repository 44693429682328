import {Button, Grid} from "@mui/material";
import React, {useState} from "react";
import FormSection from "./FormSection";
import Heading from "./Heading";
import ValueInputCard from "./ValueInputCard";

type Props = {
	show?: boolean;
	header: string;
	submit: (name: string) => void;
}

const InfrastructureName = ({submit, show, header}: Props) => {
	const [name, setName] = useState<string>("");
	const [setNameValid, setSetNameValid] = useState<boolean>(false);

	return (
		<FormSection show={show}>
			<Grid container spacing={3} justifyContent="center" alignContent="center">
				<Grid item xs={12}>
					<Heading subheading={header}/>
				</Grid>
				<Grid item xs={12} sm={6} md={4} lg={3} sx={{display: "flex"}}>
					<ValueInputCard
						heading={"Name"}
						valid={setNameValid}
						setValueStr={setName}
						setValid={setSetNameValid}
					/>
				</Grid>
				<Grid item xs={12}>
					<Button
						size="large"
						color="primary"
						variant="contained"
						disabled={!setNameValid}
						onClick={() => submit(name)}
					>
						{`${header}`}
					</Button>
				</Grid>
			</Grid>
		</FormSection>

	)
}

export default InfrastructureName;