import {Typography} from "@mui/material";
import {FormValueCard} from "./FormStyles";
import React from "react";
import {Link} from "react-router-dom";

type Props = {
	heading: string;
	value: string | number;
	variant?: "elevation" | "outlined";
	color?: "primary" | "error" | "success" | "warning";
	path: string
}

const ValueLinkCard = ({heading, value, variant = "outlined", color, path}: Props) => {
	return (
		<FormValueCard variant={variant} sx={{flexGrow: 1}} color={color}>
			<Link to={{
				pathname: path
			}}>
				<Typography component="p" variant="h6" align="center">
					{heading}
				</Typography>
				<Typography component="p" variant="h2" align="center" sx={{paddingTop: 1.25}}>
					{value}
				</Typography>
			</Link>
		</FormValueCard>
	);
}

export default ValueLinkCard